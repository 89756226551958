import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getConsentAPI, getTermsAPI} from '@api/documents-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'

interface DocumentsState {
	terms: string
	consent: string
	loadingTerms: boolean
	loadingConsent: boolean
	termsSuccess: boolean
	consentSuccess: boolean
	termsError: string | null
	consentError: string | null
}

interface DocumentsSuccess {
	terms?: string
	consent?: string
}

const initialState: DocumentsState = {
	terms: null as any,
	consent: null as any,
	loadingTerms: false,
	loadingConsent: false,
	termsSuccess: false,
	consentSuccess: false,
	termsError: null,
	consentError: null,
}

const documentsSlice = createSlice({
	name: 'documents',
	initialState,
	reducers: {
		getTermsStart(state): void {
			state.loadingTerms = true
			state.termsError = null
		},
		getTermsSuccess(state, action: PayloadAction<DocumentsSuccess>): void {
			const {terms} = action.payload

			state.terms = terms!
			state.loadingTerms = false
			state.termsSuccess = true
			state.termsError = null
		},
		getTermsFailure(state, action: PayloadAction<string>): void {
			state.loadingTerms = false
			state.termsError = action.payload
		},
		getConsentStart(state): void {
			state.loadingConsent = true
			state.consentError = null
		},
		getConsentSuccess(state, action: PayloadAction<DocumentsSuccess>): void {
			const {consent} = action.payload

			state.consent = consent!
			state.loadingConsent = false
			state.consentSuccess = true
			state.consentError = null
		},
		getConsentFailure(state, action: PayloadAction<string>): void {
			state.loadingConsent = false
			state.consentError = action.payload
		},
		resetTermsError(state): void {
			state.termsError = null
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): DocumentsState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getTermsStart,
	getTermsSuccess,
	getTermsFailure,
	getConsentStart,
	getConsentSuccess,
	getConsentFailure,
	resetTermsError,
} = documentsSlice.actions
export default documentsSlice.reducer

export const getTerms =
	(activeLanguage?: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getTermsStart())

			const terms = await getTermsAPI()

			dispatch(getTermsSuccess({terms}))
		} catch (err) {
			dispatch(getTermsFailure(getTranslationFromError(err as Error)))
		}
	}

export const getConsent =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getConsentStart())

			const consent = await getConsentAPI()

			dispatch(getConsentSuccess({consent}))
		} catch (err) {
			dispatch(getConsentFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetTermsErrorState =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetTermsError())
	}
