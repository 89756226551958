enum DocumentTypesEnum {
	DELIVERY_DOCUMENT = 'DELIVERY_DOCUMENT',
	DEALER_QUOTATION = 'DEALER_QUOTATION',
	LEASE_CONTRACT = 'LEASE_CONTRACT',
	SIGNED_LEASE_CONTRACT = 'SIGNED_LEASE_CONTRACT',
	UNSIGNED_DELIVERY_DOCUMENT = 'UNSIGNED_DELIVERY_DOCUMENT',
	PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export default DocumentTypesEnum
