import {combineReducers} from '@reduxjs/toolkit'

import authReducer from '@redux/slices/auth-slice'
import errorReducer from '@redux/slices/error-slice'
import navigationReducer from '@redux/slices/navigation-slice'
import bicycleDetailCtaReducer from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import bicycleDetailReducer from '@templates/BicycleDetail/bicycle-detail-slice'
import bicyclesReducer from '@templates/BicycleOverview/bicycles-slice'
import consentReducer from '@templates/ConsentTemplate/consent-slice'
import cyclistProfileReducer from '@templates/CyclistProfile/cyclist-profile-slice'
import damageCreationReducer from '@templates/DamageCreation/create-damage-slice'
import forgotPasswordReducer from '@templates/ForgotPassword/forgot-password-slice'
import cyclistsReducer from '@templates/Home/cyclists-slice'
import loginReducer from '@templates/Login/login-slice'
import logoutReducer from '@templates/Logout/logout-slice'
import createQuotationsReducer from '@templates/QuotationCreation/create-quotation-slice'
import quotationDetailReducer from '@templates/QuotationDetail/quotation-detail.slice'
import quotationsReducer from '@templates/QuotationOverview/quotations.slice'
import resetPasswordReducer from '@templates/ResetPassword/reset-password-slice'
import setPasswordReducer from '@templates/SetPassword/set-password-slice'
import ssoReducer from '@templates/Sso/sso-slice'

import customersReducer from './slices/customers-slice'
import cyclistAccountReducer from './slices/cyclist-account-slice'
import dealersReducer from './slices/dealers-slice'
import documentsReducer from './slices/documents-slice'
import productDataReducer from './slices/product-data-slice'

const rootReducer = combineReducers({
	login: loginReducer,
	auth: authReducer,
	logout: logoutReducer,
	sso: ssoReducer,
	consent: consentReducer,
	forgotPassword: forgotPasswordReducer,
	resetPassword: resetPasswordReducer,
	setPassword: setPasswordReducer,
	cyclists: cyclistsReducer,
	productData: productDataReducer,
	error: errorReducer,
	navigation: navigationReducer,
	documents: documentsReducer,
	dealers: dealersReducer,
	bicycleDetail: bicycleDetailReducer,
	orderFlow: bicycleDetailCtaReducer,
	bicycles: bicyclesReducer,
	cyclistProfile: cyclistProfileReducer,
	createDamage: damageCreationReducer,
	cyclistAccount: cyclistAccountReducer,
	createQuotation: createQuotationsReducer,
	customers: customersReducer,
	quotationDetail: quotationDetailReducer,
	quotations: quotationsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
