import * as PropTypes from 'prop-types'
import * as React from 'react'

import {CommonPageContext} from '@utils/types'
import {
	AuthWrapper,
	I18nWrapper,
	MuiThemeWrapper,
	UnderMaintenanceWrapper,
} from '@wrappers/index'
import ProductDataWrapper from '@wrappers/product-data-wrapper'

interface TopLayoutProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
	pageContext: CommonPageContext
}

export const TopLayout: React.FC<TopLayoutProps> = ({
	children,
	pageContext,
}) => {
	return (
		<React.Fragment>
			<MuiThemeWrapper>
				<I18nWrapper languageFromPath={pageContext.pathLanguageIso}>
					<UnderMaintenanceWrapper>
						<AuthWrapper isPrivateRoute={pageContext.isPrivate!}>
							<ProductDataWrapper>{children}</ProductDataWrapper>
						</AuthWrapper>
					</UnderMaintenanceWrapper>
				</I18nWrapper>
			</MuiThemeWrapper>
		</React.Fragment>
	)
}

TopLayout.propTypes = {
	children: PropTypes.node,
}

export default TopLayout
