import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getCyclistAPI} from '@api/cyclists-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Cyclist} from '@utils/types'

interface CyclistProfileState {
	cyclist: Cyclist
	loading: boolean
	success: boolean
	error: string | null
}

interface CyclistProfileSuccess {
	cyclist: Cyclist
}

const initialState: CyclistProfileState = {
	cyclist: undefined as any,
	loading: false,
	success: false,
	error: null,
}

const cyclistsSlice = createSlice({
	name: 'cyclistProfile',
	initialState,
	reducers: {
		getCyclistProfileStart(state): void {
			state.loading = true
			state.error = null
		},
		getCyclistProfileSuccess(
			state,
			action: PayloadAction<CyclistProfileSuccess>
		): void {
			const {cyclist} = action.payload

			state.cyclist = cyclist
			state.loading = false
			state.success = true
			state.error = null
		},
		getCyclistProfileFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		resetCyclistProfile(state): void {
			state.cyclist = initialState.cyclist
			state.loading = initialState.loading
			state.success = initialState.success
			state.error = initialState.error
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): CyclistProfileState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getCyclistProfileStart,
	getCyclistProfileSuccess,
	getCyclistProfileFailure,
	resetCyclistProfile,
} = cyclistsSlice.actions
export default cyclistsSlice.reducer

export const getCyclistProfile =
	(cyclistId: number, bicycleId?: number): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getCyclistProfileStart())
			const cyclist = await getCyclistAPI(cyclistId, bicycleId!)

			dispatch(getCyclistProfileSuccess({cyclist}))
		} catch (err) {
			console.log('err', err)
			dispatch(getCyclistProfileFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetCyclistProfileState =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetCyclistProfile())
	}
