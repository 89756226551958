import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import sha1 from 'js-sha1'

import {getUploadUrlAPI, createDamageAPI} from '@api/damages-api'
import {uploadFileAPI} from '@api/file-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {FileType} from '@utils/types/file-types'

export interface DamageCreationForm {
	cyclistName: string
	salesOrderNumber: string
	bicycleInfo: string
	damageImages: File[]
	description: string
	quotationDocument: File
}

export interface Damage {
	images: Image[]
	description: string
	quotationDocumentIdentifier: string
	quotationChecksum: string
}

interface Image {
	imageDocumentIdentifier: string
	imageChecksum: string
}

interface DamageCreationState {
	success: boolean
	loading: boolean
	error: string
}

const initialState: DamageCreationState = {
	success: false,
	loading: false,
	error: null as any,
}

const damageCreationSlice = createSlice({
	name: 'damageCreation',
	initialState,
	reducers: {
		createDamageStart(state): void {
			state.loading = true
			state.error = null as any
		},
		createDamageSuccess(state): void {
			state.success = true
			state.loading = false
			state.error = null as any
		},
		createDamageFailure(state, action: PayloadAction<string>): void {
			state.success = false
			state.loading = false
			state.error = action.payload
		},
		createDamageReset(state): void {
			state.success = false
			state.loading = false
			state.error = null as any
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): DamageCreationState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	createDamageSuccess,
	createDamageStart,
	createDamageFailure,
	createDamageReset,
} = damageCreationSlice.actions
export default damageCreationSlice.reducer

const getImages = (
	newDamage: DamageCreationForm,
	bicycleId: string
): Promise<Image[]> => {
	return Promise.all(
		newDamage.damageImages.map(async (image: File) => {
			const imageUrl = await getUploadUrlAPI<{
				imageDocumentIdentifier: string
				imageDocumentUploadUrl: string
			}>(bicycleId, 'damage-case-images')
			await uploadFileAPI(
				imageUrl.imageDocumentUploadUrl,
				image,
				[FileType.JPEG, FileType.JPG, FileType.PNG].join(',')
			)
			return {
				imageDocumentIdentifier: imageUrl.imageDocumentIdentifier,
				imageChecksum: sha1
					.create()
					.update(await new Response(image).arrayBuffer())
					.hex(),
			}
		})
	)
}

export const createDamage =
	(newDamage: DamageCreationForm, bicycleId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(createDamageStart())

			// Upload damage images
			const images: Image[] = await getImages(newDamage, bicycleId)

			// Upload quotation document
			const quotationDocumentUrl = await getUploadUrlAPI<{
				quotationDocumentIdentifier: string
				quotationDocumentUploadUrl: string
			}>(bicycleId, 'damage-case-quotations', newDamage.quotationDocument.name)
			const quotationChecksum = sha1
				.create()
				.update(await new Response(newDamage.quotationDocument).arrayBuffer())
				.hex()
			await uploadFileAPI(
				quotationDocumentUrl.quotationDocumentUploadUrl,
				newDamage.quotationDocument,
				FileType.PDF
			)

			// Create damage
			const success = await createDamageAPI(bicycleId, {
				images,
				description: newDamage.description,
				quotationDocumentIdentifier:
					quotationDocumentUrl.quotationDocumentIdentifier,
				quotationChecksum,
			})

			// Execute correct action based on response 'createDamageAPI'
			if (success) {
				dispatch(createDamageSuccess())
			} else {
				dispatch(createDamageFailure('UnknownError'))
			}
		} catch (error) {
			dispatch(createDamageFailure(getTranslationFromError(error as Error)))
		}
	}

export const resetForm =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(createDamageReset())
		} catch (error) {
			dispatch(createDamageFailure(getTranslationFromError(error as Error)))
		}
	}
