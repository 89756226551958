import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getDealersAPI, getManagedDealersAPI} from '@api/dealers-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Dealer} from '@utils/types'

interface DealersState {
	dealers: Dealer[]
	loading: boolean
	success: boolean
	error: string | null
}

interface DealersSuccess {
	dealers: Dealer[]
}

const initialState: DealersState = {
	dealers: [],
	loading: false,
	success: false,
	error: null,
}

const dealersSlice = createSlice({
	name: 'dealers',
	initialState,
	reducers: {
		getDealersStart(state): void {
			state.loading = true
			state.error = null
		},
		getDealersSuccess(state, action: PayloadAction<DealersSuccess>): void {
			const {dealers} = action.payload

			state.dealers = dealers
			state.loading = false
			state.success = true
			state.error = null
		},
		getDealersFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		reset(): DealersState {
			return {...initialState}
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): DealersState => {
			return {
				...initialState,
			}
		},
	},
})

export const {getDealersStart, getDealersSuccess, getDealersFailure, reset} =
	dealersSlice.actions
export default dealersSlice.reducer

export const getDealers =
	(companyName?: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getDealersStart())
			const dealers = await getDealersAPI(companyName)

			dispatch(getDealersSuccess({dealers}))
		} catch (err) {
			dispatch(getDealersFailure(getTranslationFromError(err as Error)))
		}
	}

export const getManagedDealers =
	(companyName?: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getDealersStart())
			const dealers = await getManagedDealersAPI(companyName)

			dispatch(getDealersSuccess({dealers}))
		} catch (err) {
			dispatch(getDealersFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetDealers =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(reset())
	}
