export default {
  LoginTitle1: `Bienvenue`,
  LoginTitle2: `Connectez-vous pour continuer`,
  LoginButton: `Se connecter`,
  LoginEmailLabel: `Adresse e-mail`,
  LoginPasswordLabel: `Mot de passe`,
  LoginForgotPasswordLabel: `Mot de passe oublié?`,
  LoginPasswordDefaultValue: `Entrez votre mot de passe`,
  LoginEmailDefaultValue: `Adresse e-mail`,
  LoginPurpleTitle1: `The lease`,
  LoginPurpleTitle2: `we can do!`,
  LoginPurpleTextBlock: `Gérez facilement tous les vélos en leasing de Cyclis dans l'outil pour revendeurs.`,
  LogoutButton: `Déconnexion`,
  UnknownError: `Une erreur inconnue est survenue.`,
  ForgotPasswordTitle: `Mot de passe oublié?`,
  ForgotPasswordSubTitle: `Saisissez l'adresse électronique associée à votre compte. Nous vous enverrons les instructions pour réinitialiser votre mot de passe.`,
  ForgotPasswordBackButton: `Retour`,
  ForgotPasswordSendEmailButton: `Envoyez un e-mail de réinitialisation`,
  ForgotPasswordSuccessTitle: `Consultez votre boîte de réception!`,
  ForgotPasswordSuccessSubtitlePart2: `pour réinitialiser votre mot de passe.`,
  CustomSuccessGoBack: `Retour`,
  SetPasswordTitle: `Modifiez votre mot de passe actuel`,
  SetPasswordSubTitle: `Veillez à choisir un mot de passe sécurisé qui répond aux critères ci-dessous.`,
  SetPasswordSaveButton: `Enregistrer le mot de passe`,
  SetPasswordTwoSpecialCharacters: `2 caractères spéciaux`,
  SetPasswordTwoUppercaseCharacters: `2 majuscules`,
  SetPasswordTwoDigits: `2 chiffres`,
  SetPasswordTenCharacters: `10 caractères au total`,
  SetPasswordCurrentPasswordLabel: `Mot de passe actuel`,
  SetPasswordConfirmPasswordLabel: `Confirmer le mot de passe`,
  SetPasswordError: `Le mot de passe actuel que vous avez saisi ne correspond pas à votre mot de passe.`,
  ResetPasswordTitle: `Entrez un nouveau mot de passe pour votre compte.`,
  ResetPasswordSubTitle: `Veillez à choisir un mot de passe sécurisé qui répond aux critères ci-dessous.`,
  ResetPasswordSaveButton: `Enregistrer le mot de passe`,
  ResetPasswordError: `Le code de réinitialisation n'est pas valide ou est arrivé à échéance.`,
  EmailSuccessTitle: `Consultez votre boîte de réception!`,
  EmailSuccessGoBack: `Retour`,
  BicycleOverviewHeaderTitle: `Mes vélos`,
  BicycleOverviewHeaderSubTitle: `La liste ci-dessous répertorie tous les vélos actifs en leasing par votre magasin.`,
  BicycleOverviewExportButton: `Exporter`,
  BicycleOverviewTotalNumberOfRecords: `Nombre total de commandes`,
  BicycleOverviewTotalLeaseAmountFee: `Montant total du leasing`,
  BicycleOverviewTableHeaderSoNum: `Référence SO`,
  BicycleOverviewTableHeaderName: `Nom`,
  BicycleOverviewTableHeaderBicycle: `Vélo`,
  BicycleOverviewTableHeaderExpectedDeliveryDate: `Date de livraison prévue`,
  BicycleOverviewTableHeaderPickupDate: `Date d'enlèvement`,
  BicycleOverviewTableHeaderStartOfContract: `Date de début de contrat`,
  BicycleOverviewTableHeaderEndOfContract: `Date de fin de contrat`,
  BicycleOverviewTableHeaderCurrentMaintenanceBudget: `Budget d'entretien actuel`,
  BicycleOverviewTableHeaderBicycleStatus: `Statut`,
  BicycleOverviewSearchInputPlaceholder: `Rechercher...`,
  NavigationQuotations: `Devis`,
  NavigationMyBicycles: `Mes vélos`,
  NavigationDropdownChangePassword: `Modifiez le mot de passe`,
  NavigationDropdownLogout: `Déconnexion`,
  NavigationHome: `Page d'accueil`,
  NavigationOpenTasks: `Tâches en cours`,
  NavigationContact: `Contact`,
  QuotationCreationPageStepInvolvedParties: `Parties concernées`,
  QuotationCreationPageStepBicycleInfo: `Infos relatives au vélo`,
  QuotationCreationPageStepAccessories: `Accessoires`,
  QuotationCreationPageStepAdditionalInfo: `Informations complémentaires`,
  QuotationCreationPageStepUpload: `Télécharger`,
  QuotationCreationPageNextButton: `Suivant`,
  QuotationCreationPageBackButton: `Retour`,
  QuotationCustomerId: `Employeur`,
  QuotationCustomerPlaceholder: `Saisissez le nom du client`,
  QuotationFormIdPlaceholder: `Entrez un identifiant unique`,
  QuotationDealerId: `Revendeur`,
  QuotationDealerPlaceholder: `Saisissez le nom du revendeur`,
  QuotationReference: `Référence`,
  QuotationReferencePlaceholder: `Référence figurant sur chaque facture`,
  QuotationCyclistEmail: `E-mail du cycliste`,
  QuotationCyclistEmailPlaceholder: `Entrez l'adresse électronique`,
  QuotationFirstName: `Prénom`,
  QuotationFirstNamePlaceholder: `Par exemple : Richard`,
  QuotationLastName: `Nom`,
  QuotationLastNamePlaceholder: `Par exemple : Dupont`,
  QuotationEmailAddress: `Adresse e-mail`,
  QuotationEmailAddressPlaceholder: `Entrez l'adresse électronique`,
  QuotationPhone: `Numéro de téléphone`,
  QuotationPhonePlaceholder: `Saisissez un numéro de téléphone`,
  QuotationLanguage: `Langue`,
  QuotationBrand: `Marque`,
  QuotationModel: `Modèle`,
  QuotationTypeHere: `Saisir ici...`,
  QuotationType: `Type`,
  QuotationFrameType: `Type de cadre`,
  QuotationFrameSize: `Dimensions du cadre`,
  QuotationColor: `Couleur`,
  QuotationRetailPrice: `Prix catalogue`,
  QuotationAccessoryDescription: `Description`,
  QuotationAccessoryDescriptionPlaceholder: `Description de cet accessoire`,
  QuotationAccessoryAmount: `Quantité`,
  QuotationAccessoryPricePerPiece: `Prix unitaire`,
  QuotationAddNewAccessory: `Ajoutez un nouvel accessoire`,
  QuotationAdministration: `Administration`,
  QuotationAdministrationCost: `Frais administratifs`,
  QuotationAdministrationDescription: `Description des frais administratifs`,
  QuotationAdministrationPlaceholder: `Description de ce coût administratif`,
  QuotationAddNewAdministrationCost: `Ajoutez un nouveau type de frais administratifs`,
  QuotationExtrasToInclude: `Options à ajouter`,
  QuotationInsurance: `Assurance`,
  QuotationAssistance: `Assistance dépannage`,
  QuotationMaintenance: `Entretien`,
  QuotationAdditionalMainBudget: `Budget d'entretien supplémentaire`,
  QuotationAdditionalMainBudgetMin: `min. (compris par défaut)`,
  QuotationAdditionalMainBudgetMax: `max.`,
  QuotationUploadTitle: `Charger le document`,
  QuotationUploadDescription: `Chargez le devis (1) que vous avez reçu de l'un de nos revendeurs de vélos.`,
  QuotationCreateButton: `Établissez un devis`,
  QuotationsOverViewTitle: `Devis`,
  QuotationsOverViewSubTitle: `La liste ci-dessous répertorie tous les devis de votre entreprise.`,
  QuotationsOverViewCreateButton: `+ établir un devis`,
  QuotationsOverViewTableHeaderSoNum: `Référence SO`,
  QuotationsOverViewTableHeaderCustomer: `Client`,
  QuotationsOverViewTableHeaderCyclist: `Cycliste`,
  QuotationsOverViewTableHeaderBicycle: `Vélo`,
  QuotationCreationSuccessTitle: `Nous sommes en train d'établir votre devis`,
  QuotationCreationSuccessSubTitle: `Nous vous le ferons parvenir dès qu'il sera prêt.`,
  QuotationCreationSuccessButtonText: `Retour à la liste`,
  QuotationCreationCouldNotFindCyclist: `Impossible de trouver un cycliste enregistré avec cette adresse e-mail {{email}}.`,
  QuotationCreationSelectLanguagePlaceholder: `-- Sélectionnez une langue --`,
  QuotationCreationSelectPlaceholder: `-- Faites un choix --`,
  QuotationDetailBackToOverview: `Retour à la liste`,
  QuotationDetailQuotationIdTitle: `Identifiant devis`,
  QuotationDetailQuotationSoNumberTitle: `Numéro SO`,
  QuotationDetailQuotationId: `Identifiant devis`,
  QuotationDetailSONumber: `Numéro SO:`,
  QuotationDetailInvolvedPartiesTitle: `Parties concernées`,
  QuotationDetailCustomer: `Client:`,
  QuotationDetailCyclist: `Cycliste:`,
  QuotationDetailCyclistEmail: `E-mail:`,
  QuotationDetailDealer: `Revendeur:`,
  QuotationDetailReference: `Référence:`,
  QuotationDetailBicycleInformation: `Infos vélo`,
  QuotationDetailBrand: `Marque:`,
  QuotationDetailModel: `Modèle:`,
  QuotationDetailType: `Type:`,
  QuotationDetailFrameType: `Type de cadre:`,
  QuotationDetailFrameSize: `Dimensions du cadre:`,
  QuotationDetailColor: `Couleur:`,
  QuotationDetailRetailPrice: `Prix catalogue`,
  QuotationDetailTotalAccessoriesPrice: `Prix total des accessoires`,
  QuotationAccessoriesListTitle: `Liste des accessoires`,
  QuotationDetailAccessoryPerPiece: `à l'unité`,
  QuotationDetailExtrasTitle: `Options`,
  QuotationDetailExtrasAdditionalServices: `Services supplémentaires dans votre plan de leasing:`,
  QuotationDetailInsurance: `Assurance`,
  QuotationDetailAssistance: `Assistance dépannage`,
  QuotationDetailMaintenance: `Entretien`,
  QuotationDetailExtraBudget: `Budget d'entretien supplémentaire:`,
  QuotationDetailBicycleLeaseTitle: `Leasing vélo`,
  QuotationDetailLeaseCost: `Frais de leasing:`,
  QuotationDetailAdministrationTitle: `Administration`,
  QuotationDetailAdministrationCost: `Frais administratifs:`,
  UploadHelperTextDragAndDrop: `Faites glisser`,
  UploadHelperTextQuotation: `votre devis ici ou`,
  UploadHelperTextClick: `cliquez sur`,
  UploadedText: `Téléchargé`,
  UploadDeleteButton: `Supprimer`,
  QuotationDetailAttachmentsTitle: `Annexes`,
  QuotationDetailDownloadButton: `Télécharger`,
  QuotationOverviewDownload: `Télécharger`,
  ConsentTitle: `Politique de Confidentialité`,
  ConsentDate: `Mis à jour le 1er janvier 2020`,
  ConsentBodyPart1: `Lorsque vous utilisez nos services, vous nous confiez vos données. Cyclis s'est dotée d'une politique de confidentialité qui fait le point sur les types de données collectées, les raisons de cette collecte et leur utilisation par nos soins.`,
  ConsentBodyPart2: `Vous pouvez nous contacter pour les questions relatives à la vie privée à l'adresse`,
  ConsentBodyTitle: `Quelles sont les informations que nous recueillons à votre sujet?`,
  ConsentSubmitButton: `Continuer`,
  ConsentAcceptPart1: `J'ai lu la`,
  ConsentAcceptPart2: `Politique de Confidentialité`,
  QuotationOverviewDocumentLoading: `Téléchargement en cours`,
  QuotationOverviewHeaderDate: `Date`,
  LoginInvalidRole: `Pour accéder au portail, vous devez avoir plus de droits qu'un cycliste.`,
  HomePageTitlePartOne: `Bienvenue chez Cyclis`,
  HomePageTitlePartTwo: `Tableau de bord`,
  HomePageSubTitle: `Informez-vous sur les tâches en cours ou renseignez votre client.`,
  HomePageStatsOrdersToBeConfirmed: `Commandes à approuver`,
  HomePageStatsStatsViewOrders: `Affichez les commandes`,
  HomePageReadyToDeliverBicycles: `Vélos prêts pour livraison`,
  HomePageStatsDeliverBicycle: `Livrez le vélo`,
  HomePageSearchTitle: `Renseignez votre client`,
  HomepageSearchSubTitle: `Vous pouvez accéder à son dossier en effectuant une recherche par nom et/ou numéro SO`,
  HomePageSearchInputPlaceholder: `Nom ou numéro SO`,
  HomePageSearchButton: `Rechercher`,
  HomePageCreateNewQuotationPartOne: `Créez un`,
  HomePageCreateNewQuotationPartTwo: `Nouveau devis`,
  SearchResultPageBackToOverview: `Retour à la liste`,
  SearchResultPageTitle: `Résultats de recherche pour`,
  SearchResultHeaderSoNum: `Numéro SO`,
  SearchResultHeaderCyclist: `Cycliste`,
  SearchResultHeaderCompany: `Entreprise`,
  SearchResultHeaderBicycle: `Vélo`,
  CyclistProfileBackToOverview: `Retour à la liste`,
  CyclistProfileCurrentMaintenanceBudget: `Budget d'entretien actuel`,
  CyclistProfileSeeMore: `Voir plus`,
  CyclistProfileNewInsuranceCase: `Nouveau sinistre`,
  CyclistProfileNewQuotation: `Nouveau devis`,
  CyclistProfileAddMaintenance: `Ajouter un entretien`,
  BicycleDetailBackToOverview: `Retour à la liste`,
  BicycleDetailHeaderSoNumber: `Numéro SO`,
  BicycleDetailMaintenanceTitle: `Entretien`,
  BicycleDetailMaintenanceAvailable: `Budget d'entretien disponible`,
  BicycleDetailMaintenanceTotal: `Budget d'entretien total`,
  BicycleDetailMaintenanceSpend: `Budget de maintenance dépensé`,
  BicycleDetailMaintenanceHistory: `Historique`,
  BicycleDetailMaintenanceUploadHelperText: `la facture d'entretien ou`,
  BicycleDetailInsurancesTitle: `Assurances`,
  BicycleDetailInsurancesButtonText: `Signaler un nouveau sinistre`,
  BicycleDetailLeaseDetailsTitle: `Détails du leasing`,
  BicycleDetailLeaseDetailsSoNum: `Numéro SO`,
  BicycleDetailLeaseDetailsStatus: `Statut`,
  BicycleDetailLeaseDetailsStartOfContract: `Début du contrat`,
  BicycleDetailLeaseDetailsEndOfContract: `Fin du contrat`,
  BicycleDetailLeaseDetailsExpectedDeliveryDate: `Délai de livraison prévu`,
  BicycleDetailLeaseDetailsPickupDate: `Date d'enlèvement`,
  BicycleDetailLeaseDetailsLeaseCost: `Coût de location`,
  BicycleDetailBicycleInformationTitle: `Infos vélo`,
  BicycleDetailBicycleInformationBrand: `Marque`,
  BicycleDetailBicycleInformationModel: `Modèle`,
  BicycleDetailBicycleInformationFrameType: `Type de cadre`,
  BicycleDetailBicycleInformationFrameNumber: `Numéro de cadre`,
  BicycleDetailBicycleInformationType: `Type`,
  BicycleDetailBicycleInformationColor: `Couleur`,
  BicycleDetailBicycleInformationNumberPlate: `Numéro de plaque`,
  BicycleDetailBicycleInformationKeyNumber: `Numéro de clé`,
  BicycleDetailInvolvedPartiesTitle: `Parties concernées`,
  BicycleDetailInvolvedPartiesCustomer: `Client`,
  BicycleDetailInvolvedPartiesCyclist: `Cycliste`,
  BicycleDetailInvolvedPartiesCyclistEmail: `E-mail`,
  BicycleDetailInvolvedPartiesDealer: `Marchand de vélos`,
  BicycleDetailAccessoriesListTitle: `Liste d'accessoires`,
  BicycleDetailAccessoriesListPerPiece: `À l'unité`,
  BicycleDetailExtrasTitle: `Options`,
  BicycleDetailExtrasExtraBudget: `Budget d'entretien supplémentaire`,
  BicycleDetailExtrasAdditionalServices: `Services annexes inclus dans votre plan de leasing`,
  BicycleDetailExtrasAdditionalServicesInsurance: `Assurance`,
  BicycleDetailExtrasAdditionalServicesAssistance: `Assistance dépannage`,
  BicycleDetailExtrasAdditionalServicesMaintenance: `Entretien`,
  BicycleDetailAttachmentsTitle: `Annexes`,
  BicycleDetailCtaQuotationApproved: `Votre devis a été approuvé!`,
  BicycleDetailCtaQuotationApprovedSubTitle: `Confirmez la commande et, si possible, indiquez une date de livraison prévue.`,
  BicycleDetailEstimatedDeliveryDateLabel: `Date de livraison prévue`,
  BicycleDetailConfirmOrderButton: `Confirmez la commande`,
  BicycleDetailCtaKeepYourCustomerUpToDate: `Tenez votre client informé`,
  BicycleDetailKeepYourCustomerUpToDate: `En cas de retard, vous pouvez ajuster le délai de livraison prévu ou faire savoir au client que le vélo est prêt pour enlèvement.`,
  BicycleDetailCtaReadyForPickupLabel: `Prêt pour enlèvement`,
  BicycleDetailCtaUpdateStatusButton: `Mise à jour du statut`,
  BicycleDetailCtaFrameNumberLabel: `Numéro de cadre:`,
  BicycleDetailCtaKeyNumberLabel: `Numéro de clé:`,
  BicycleDetailCtaLicensePlateLabel: `Plaque d'immatriculation:`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleTitle: `Vélo bientôt prêt pour enlèvement.`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart1: `Remplissez tous les champs ci-dessous pour livrer le vélo.`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart2: `Vous pouvez télécharger le`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart3: `document de livraison`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart4: `ici`,
  BicycleDetailCtaFrameNumberLabel2: `Numéro de cadre:`,
  BicycleDetailCtaKeyNumberLabel2: `Numéro de clé:`,
  BicycleDetailCtaLicensePlateLabel2: `Numéro de plaque:`,
  BicycleDetailCtaDeliverBicycleButton: `Livrez le vélo`,
  BicycleDetailCtaDeliverBicycleTitle: `Vous pouvez livrer le vélo maintenant!`,
  BicycleDetailCtaDeliverBicycleSubTitlePart1: `N'oubliez pas de transférer le document de livraison signé et la facture`,
  BicycleDetailCtaDeliverBicycleSubTitlePart2: `Vous pouvez télécharger le`,
  BicycleDetailCtaDeliverBicycleSubTitlePart3: `document de livraison`,
  BicycleDetailCtaDeliverBicycleSubTitlePart4: `ici`,
  BicycleDetailCtaAddDocument: `Ajoutez un document`,
  BicycleDetailCtaAddInvoice: `Ajoutez la facture`,
  BicycleDetailCtaSignedDeliveryDocument: `Document de livraison signé`,
  BicycleDetailCtaInvoiceDocument: `Facture`,
  BicycleDetailCtaFinishButton: `Terminer`,
  BicycleDetailCtaDeliverySuccessTitle: `La commande est clôturée!`,
  BicycleDetailCtaDeliverySuccessSubTitle1: `Merci d'avoir saisi toutes les informations. Toutes les étapes sont à présent terminées.`,
  BicycleDetailCtaDeliverySuccessSubTitle2: `Nous allons clôturer la commande pour vous.`,
  ErrorDownloadingUnsignedDeliveryDocument: `Le document de livraison n'a pas pu être téléchargé.`,
  BicycleDetailStatusIsSyncingPlaceholderTitle: `Mise à jour du vélo en cours`,
  BicycleDetailStatusIsSyncingPlaceholderSubtitle1: `Nous sommes en train d'examiner votre mise à jour en interne chez Cyclis, et nous allons modifier cela dans votre dossier vélo dès que possible.`,
  BicycleDetailStatusIsSyncingPlaceholderSubtitle2: `Veuillez revenir plus tard pour consulter vos mises à jour.`,
  BicycleDetailStatusIsSyncingPlaceholderBackToOverviewButton: `Retour au sommaire`,
  BicycleDetailAddMaintenanceModalTitle: `Prêt à télécharger les frais d'entretien`,
  BicycleDetailAddMaintenanceModalText: `Les frais d'entretien sont prêts pour l'envoi. Si vous n'avez plus rien à ajouter, envoyez le document en cliquant sur le bouton 'Télécharger' au bas de page.`,
  BicycleDetailAddMaintenanceModalCancelButton: `Résilier`,
  BicycleDetailAddMaintenanceModalUploadButton: `Télécharger`,
  BicycleDetailUserActionLogTitle: `Mon Dernier Activités`,
  UserActionLogItemTitleMaintenanceInvoiceUploaded: `Facture de maintenance téléchargée`,
  UserActionLogItemTitleDamageCaseCreated: `Cas d'assurance créé`,
  SearchAtleast3Characters: `Saisissez au moins 3 caractères`,
  BicycleStatusAll: `Tous`,
  BicycleStatusCyclisToOrder: `À commander (Cyclis)`,
  BicycleStatusCyclisOrdered: `À commander (Dealer)`,
  BicycleStatusDealerOrdered: `Commandé`,
  BicycleStatusDeliveryDateGiven: `Commandé (Livraison connu)`,
  BicycleStatusReadyForPickup: `Prêt pour enlèvement`,
  BicycleStatusPickupInfoAvailable: `Prêt pour enlèvement`,
  BicycleStatusDeliveryCompleted: `Livré`,
  BicycleStatusEndLease: `Fin de leasing`,
  BicycleStatusCanceled: `Résilié`,
  BicycleOverviewCustomPlaceholderHeader: `Pas de vélos disponibles`,
  BicycleOverviewCustomPlaceholderSubTitle: `Il est possible qu'il n'y ait aucun vélo correspondant à ce statut. Il est préférable de filtrer sur 'Tous'.`,
  QuotationOverviewCustomPlaceholderHeader: `Aucun devis disponible`,
  QuotationOverviewCustomPlaceholderSubTitle: `Il se peut qu'aucun vélo ne corresponde à ce critère de recherche.`,
  AutocompleteNoOptionsWithFilterText: `Pas d'options`,
  AutocompleteNoOptionsWithoutFilterText3Chars: `Tapez 3 caractères ou plus pour lancer la recherche`,
  AutocompleteNoOptionsWithoutFilterTextNoChars: `Tapez pour lancer la recherche`,
  DamageCreationPageStepBicycleInfo: `Infos relatives au vélo`,
  DamageCreationPageStepDescription: `Description`,
  DamageCreationPageStepCost: `Prix`,
  DamageCreationBicycleInfoFormCyclistName: `Nom du cycliste`,
  DamageCreationBicycleInfoFormSalesOrderNumber: `Numéro SO`,
  DamageCreationBicycleInfoFormBicycleInfo: `Vélo`,
  DamageCreationDescriptionFormPictures: `Télécharger une ou plusieurs photos`,
  DamageCreationDescriptionFormPreview: `Par exemple:`,
  DamageCreationDescriptionFormDescription: `Description`,
  DamageCreationDescriptionFormDescriptionPlaceholder: `Décrivez le sinistre`,
  DamageCreationCostFormTitle: `Télécharger le document`,
  DamageCreationCostFormSubTitle: `Veuillez télécharger votre document de devis (1) qui couvrira la réparation de cette affaire d'assurance.`,
  DamageCreationPlaceholderTitle: `Nous avons bien reçu votre demande.`,
  DamageCreationPlaceholderSubTitle: `Asseyez-vous et détendez-vous, nous vous enverrons un e-mail dès que votre devis aura été approuvé.`,
  DamageCreationPlaceholderButton: `Retour au vélo`,
  DamageCreationBackButton: `Retour`,
  DamageCreationSubmitButton: `Terminer`,
  DamageCreationNextButton: `Suivant`,
  DealerOrderFlowSyncInProgress: `Mise à jour en cours`,
  DealerOrderFlowPickupInfoTogetherError: `Vous devez remplir tous les champs.`,
  NoDeliveryDateGiven: `Aucune date de livraison sélectionnée`,
  NoActualDeliveryDateGiven: `Aucune date de livraison sélectionnée`,
  DealerOrderedFlowBicycleMustHaveADeliveryDate: `Le vélo doit d'abord être en statut 'Délai de livraison disponible'.`,
  OpenTaskOverviewHeaderTitle: `Commandes`,
  OpenTaskOverviewHeaderSubTitle: `La liste ci-dessous répertorie tous les vélos dont les tâches sont en cours.`,
  OpenTaskOverviewSearchInputPlaceholder: `Rechercher...`,
  OpenTaskOverviewTableHeaderSoNum: `Numéro SO`,
  OpenTaskOverviewTableHeaderName: `Nom`,
  OpenTaskOverviewTableHeaderBicycle: `Vélo`,
  OpenTaskOverviewTableHeaderCreateDate: `Date de création`,
  OpenTaskOverviewTableHeaderExpectedDeliveryDate: `Délai de livraison prévu`,
  OpenTaskOverviewCustomPlaceholderHeader: `Aucune tâche en cours disponible.`,
  OpenTaskOverviewCustomPlaceholderSubTitle: `Il n'y a pas de tâches en cours. Revenez plus tard.`,
  HomeCyclistSearchSeeAllResults: `Consultez tous les résultats`,
  BicycleDetailMaintenanceUploadSuccessTitle: `Merci d'avoir complété les informations demandées.`,
  BicycleDetailMaintenanceUploadSuccessSubTitle: `Nous allons traiter votre demande et ajuster le budget d'entretien.`,
  BicycleDetailPickupDateLabel: `Le vélo peut être enlevé chez:`,
  BicycleDetailVelopassIdLabel: `Velopass ID:`,
  NoPickupDateGiven: `Aucune date d'enlèvement sélectionnée`,
  InsurancaCaseHelperText: `vos photos ou`,
  StandardError1: `L'adresse électronique fournie n'est pas une adresse électronique valide`,
  StandardError2: `Le mot de passe saisi correspond aux règles relatives aux mots de passe`,
  StandardError3: `Votre session a expiré`,
  StandardError4: `Vous n'êtes pas autorisé à effectuer cette opération`,
  StandardError5: `Le code sécurisé saisi n'est pas valable`,
  StandardError6: `Le prénom saisi est trop long`,
  StandardError7: `Le nom saisi est trop long.`,
  StandardError8: `L'identifiant de connexion n'est pas valable`,
  StandardError9: `Le code sécurisé unique saisi n'est pas valable`,
  StandardError10: `La marque saisie n'est pas valable`,
  StandardError11: `Le type de vélo saisi n'est pas valable`,
  StandardError12: `Le prix de vente saisi n'est pas valable`,
  StandardError13: `Le prix saisi pour les accessoires n'est pas valable`,
  StandardError14: `L'indicateur assurance fourni n'est pas valable`,
  StandardError15: `L'indicateur entretien fourni n'est pas valable`,
  StandardError16: `L'indicateur assistance fourni n'est pas valable`,
  StandardError17: `Le budget Entretien supplémentaire saisi n'est pas valable`,
  StandardError18: `Le champ doit contenir une chaîne de caractères`,
  StandardError19: `Le champ ne peut pas être vide`,
  StandardError20: `Le code sécurisé saisi est trop long et comporte plus de 512 caractères`,
  StandardError21: `Le code sécurisé saisi est trop long et comporte plus de 512 caractères`,
  StandardError22: `La combinaison identifiant et mot de passe n'est pas valable`,
  StandardError23: `Code sécurisé non valide pour SSO`,
  StandardError24: `La langue choisie n'est pas valable`,
  StandardError25: `Un ou plusieurs types de données produit saisis ne sont pas valables`,
  StandardError26: `Le champ doit contenir un nombre`,
  StandardError27: `Cycliste non trouvé`,
  StandardError28: `Le champ doit contenir une expression booléenne`,
  StandardError29: `La couleur saisie n'est pas valable`,
  StandardError30: `Le type de cadre saisi n'est pas valable`,
  StandardError31: `La taille de cadre saisie n'est pas valable`,
  StandardError32: `Le client saisi n'est pas valable`,
  StandardError33: `Aucun document trouvé pour ce matricule`,
  StandardError34: `La somme de contrôle du document ne correspond pas. Le document a pu être endommagé après son téléchargement`,
  StandardError35: `Pour créer un devis, il faut joindre un fichier`,
  StandardError36: `Le revendeur saisi n'est pas valable`,
  StandardError37: `Vous devez fournir des informations sur le cycliste`,
  StandardError38: `Un cycliste avec l'adresse électronique saisie existe déjà`,
  StandardError39: `La référence de devis saisie n'est pas valable`,
  StandardError40: `La référence de document saisie n'est pas valable`,
  StandardError41: `Il faut saisir au moins 3 caractères pour filtrer`,
  StandardError42: `Le type de portail saisi n'est pas valable`,
  StandardError43: `Autorisations non valables`,
  StandardError44: `Le vélo saisi n'existe pas`,
  StandardError45: `L'offre n'a pas le bon statut`,
  StandardError46: `Le statut saisi n'existe pas`,
  StandardError47: `La référence de vélo saisie n'est pas valable`,
  StandardError48: `Le vélo n'a pas le bon statut`,
  StandardError49: `Le vélo saisi n'est pas un speed pedelec`,
  StandardError50: `Le numéro de téléphone saisi est trop long et comporte plus de 20 caractères`,
  StandardError51: `La langue saisie est trop longue et comporte plus de 10 caractères`,
  StandardError52: `Le modèle saisi est trop long et comporte plus de 250 caractères`,
  StandardError53: `La référence saisie est trop longue et comporte plus de 50 caractères`,
  StandardError54: `La valeur du champ ne peut pas être nulle`,
  StandardError55: `La description des accessoires fournis est trop longue`,
  StandardError56: `Type de document non valable`,
  StandardError57: `Au moins une photo est nécessaire pour créer un dossier d'assurance`,
  StandardError58: `Un ou plusieurs critères de filtre ne sont pas valables`,
  StandardError72: `Lien manquant vers le partenaire sur le vélo pour l'utilisateur authentifié`,
  StandardError73: `Le cycliste pour l'adresse e-mail fournie n'est pas géré par l'utilisateur actuel`,
  StandardError77: `Le champ doit être un nombre positif`,
  StandardError78: `Le champ ne peut pas être un nombre négatif`,
  StandardError82: `Lien manquant vers le partenaire sur le devis pour l'utilisateur authentifié`,
  FileInputInvalidFileType: `Type de dossier non valable`,
  FormValidationErrorMessageRequired: `Ce champ est obligatoire`,
  FormValidationErrorMessageEmail: `Cette adresse électronique n'est pas valide`,
  FormValidationErrorMessagePhone: `Ce numéro de téléphone n'est pas valide`,
  FormValidationErrorMessageDateTypeError: `Cette valeur n'est pas une date valide`,
  FormValidationErrorMessageFileTypeUnsupported: `Ce type de fichier n'est pas pris en charge`,
  FormValidationErrorMessageStringMin: `Cette valeur est trop courte`,
  FormValidationErrorMessageStringMax: `Cette valeur est trop longue`,
  FormValidationErrorMessageNumberTypeError: `Cette valeur doit être un nombre`,
  FormValidationErrorMessageNumberNoNegative: `Aucune valeur négative n'est autorisée`,
  FormValidationErrorMessageNumberMin: `Cette valeur est trop petite`,
  FormValidationErrorMessageNumberMax: `Cette valeur est trop longue`,
  FormValidationErrorMessageArrayMin: `Le nombre d'éléments fournis est trop petit`,
  FormValidationErrorMessageBooleanCheck: `Vous devez cocher la case`,
  FormValidationErrorMessagePatternMatch: `Cette valeur ne correspond pas au modèle requis`,
  FormValidationErrorMessageCurrentPasswordEqual: `Votre nouveau mot de passe ne peut pas être le même que votre mot de passe actuel`,
  FormValidationErrorMessageConfirmPasswordNotEqual: `Votre nouveau mot de passe ne correspond pas à cette valeur`,
  ContactTitle: `Nous sommes heureux de vous aider.`,
  ContactSubtitle: `Sur quoi porte ta question?`,
  ContactTabTitleSales: `Liés aux ventes`,
  ContactTabTitleAfterCare: `Soins après-vente`,
  ContactTabTitleTechnical: `Problème technique`,
  ContactTabTitleBilling: `Facturation`,
  ContactTabTitleOther: `Autre`,
  ContactTabPanelAvailability: `Disponibilité`,
  ContactTabPanelFAQ: `FAQ`,
  ContactTabPanelNameSales: `Équipe Cyclis`,
  ContactTabPanelEmailSalesBE: `dealer@cyclis.be`,
  ContactTabPanelEmailSalesNL: `info@cyclis.nl`,
  ContactTabPanelPhoneSalesBE: `+32 (0)11 49 56 08`,
  ContactTabPanelPhoneSalesNL: `+31 (0)85 130 6930`,
  ContactTabPanelAvailabilitySales: `Vous pouvez joindre nos équipes en semaine de 08h00 à 17h00. Nous sommes fermés les week-ends et les jours fériés.`,
  ContactTabPanelFAQSales: `Vous n'avez pas envie d'attendre? Vous pouvez toujours chercher votre réponse sur notre`,
  ContactTilesFindUs: `Vous pouvez également nous trouver...`,
  ContactTileOfficeTitle: `en Hasselt`,
  ContactTileOfficeBody: `Venez voir où nous travaillons.`,
  ContactTileFacebookTitle: `sur Facebook`,
  ContactTileFacebookBody: `Likez-nous et suivez nos histoires!`,
  ContactTileInstagramTitle: `sur Instagram`,
  ContactTileInstagramBody: `Suivez-nous et apprenez à connaître l'équipe!`,
  PageNotFoundTitle: `Oups, quelque chose a mal tourné.`,
  PageNotFoundSubtitle: `Veuillez vérifier et réessayer ou retourner à notre page d'accueil.`,
  PageNotFoundGoBackButton: `Retourner`,
  BadRequestTitle: `Oups, quelque chose a mal tourné.`,
  BadRequestGoBackButton: `Retourner à la page d'accueil`,
  MaintenancePageTitle: `Maintenance`,
  MaintenancePageDescription: `Nous effectuons actuellement la maintenance de nos portails, ce qui signifie qu'ils sont temporairement indisponibles. Vous pourrez y accéder à nouveau demain. Nous nous excusons pour ce dérangement.`,
  PlannedMaintenanceBannerTitle: `Avis  important`,
  PlannedMaintenanceBannerText: `En raison de notre team building vendredi le 28 juin, nos appels téléphoniques seront transférés à notre centre de contact ce jour. Nous répondrons à vos e-mails dans les plus brefs délais. Merci de votre compréhension.`,
  PlannedMaintenanceBannerSubscript: `The Cyclis Team`,
}
