import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {AppThunk} from '@redux/store'
import {
	IDENTITY_TOKEN_STORAGE_KEY,
	REFRESH_TOKEN_STORAGE_KEY,
	USER_UID,
} from '@utils/constants/auth-constants'

interface LogoutState {
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: LogoutState = {
	loading: false,
	success: false,
	error: null,
}

const logoutSlice = createSlice({
	name: 'logout',
	initialState,
	reducers: {
		logoutStart(state): void {
			state.loading = true
			state.error = null
		},
		logoutSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		logoutFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
	},
})

export const {logoutStart, logoutSuccess, logoutFailure} = logoutSlice.actions
export default logoutSlice.reducer

export const logout =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(logoutStart())
			localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
			localStorage.removeItem(IDENTITY_TOKEN_STORAGE_KEY)
			localStorage.removeItem(USER_UID)
			dispatch(logoutSuccess())
		} catch (err) {
			dispatch(logoutFailure(err as string))
		}
	}
