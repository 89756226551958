import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {ProductData} from '@utils/types'

import {
	getAllProductDataAPI,
	ProductDataApiResult,
} from '../../rest-api/product-data-api'

interface ProductDataState {
	brands: ProductData[]
	types: ProductData[]
	colors: ProductData[]
	frameSizes: ProductData[]
	frameTypes: ProductData[]
	loading: boolean
	success: boolean
	error: string | null
}

interface ProductDataSuccess {
	productData: ProductDataApiResult
}

const initialState: ProductDataState = {
	brands: [],
	types: [],
	colors: [],
	frameSizes: [],
	frameTypes: [],
	loading: false,
	success: false,
	error: null,
}

const productDataSlice = createSlice({
	name: 'productData',
	initialState,
	reducers: {
		getProductDataStart(state): void {
			state.loading = true
			state.error = null
		},
		getProductDataSuccess(
			state,
			action: PayloadAction<ProductDataSuccess>
		): void {
			const {productData} = action.payload

			state.brands = productData.brands
			state.colors = productData.colors
			state.types = productData.types
			state.frameSizes = productData.frameSizes
			state.frameTypes = productData.frameTypes
			state.loading = false
			state.success = true
			state.error = null
		},
		getProductDataFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): ProductDataState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getProductDataStart,
	getProductDataSuccess,
	getProductDataFailure,
} = productDataSlice.actions
export default productDataSlice.reducer

export const getAllProductData =
	(language: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getProductDataStart())
			const productData = await getAllProductDataAPI(language)

			dispatch(getProductDataSuccess({productData}))
		} catch (err) {
			dispatch(getProductDataFailure(getTranslationFromError(err as Error)))
		}
	}
