export enum UserActionType {
	MAINTENANCE_INVOICE_UPLOADED = 'MAINTENANCE_INVOICE_UPLOADED',
	DAMAGE_INSURANCE_CASE_CREATED = 'DAMAGE_INSURANCE_CASE_CREATED',
}

export const userActionTitleCopyKeys = new Map<UserActionType, string>([
	[
		UserActionType.MAINTENANCE_INVOICE_UPLOADED,
		'UserActionLogItemTitleMaintenanceInvoiceUploaded',
	],
	[
		UserActionType.DAMAGE_INSURANCE_CASE_CREATED,
		'UserActionLogItemTitleDamageCaseCreated',
	],
])

type UserAction = {
	action: UserActionType
	date: string
	timestamp: number
	description: string
}

export default UserAction
