import {consumeJsonWithAuth} from '@api/api-client'
import {ProductData} from '@utils/types'

const PRODUCT_DATA_ENDPOINT = 'product-data'

export interface ProductDataApiResult {
	colors: ProductData[]
	brands: ProductData[]
	types: ProductData[]
	frameTypes: ProductData[]
	frameSizes: ProductData[]
}

/**
 * Get all the available product data.
 *
 * @return {Promise<ProductData[]>} bicycles - All the available product data
 */
export const getAllProductDataAPI = async (
	language = 'en_US'
): Promise<ProductDataApiResult> => {
	const types = `brands,types,colors,frame-sizes,frame-types`

	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${PRODUCT_DATA_ENDPOINT}?language=${language}&types=${types}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get all the brand product data.
 *
 * @return {Promise<ProductData[]>} brands - All the available brands
 */
export const getBrandsProductDataAPI = async (
	language = 'en_US'
): Promise<ProductDataApiResult> => {
	const types = `brands`

	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${PRODUCT_DATA_ENDPOINT}?language=${language}&types=${types}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get all the bicycle type product data.
 *
 * @return {Promise<ProductData[]>} types - All the available bicycle types
 */
export const getTypesProductDataAPI = async (
	language = 'en_US'
): Promise<ProductDataApiResult> => {
	const types = `types`

	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${PRODUCT_DATA_ENDPOINT}?language=${language}&types=${types}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get all the color type product data.
 *
 * @return {Promise<ProductData[]>} colors - All the available colors
 */
export const getColorsProductDataAPI = async (
	language = 'en_US'
): Promise<ProductDataApiResult> => {
	const types = `colors`

	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${PRODUCT_DATA_ENDPOINT}?language=${language}&types=${types}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get all the frame size product data.
 *
 * @return {Promise<ProductData[]>} frame sizes - All the available frame sizes
 */
export const getFrameSizesProductDataAPI = async (
	language = 'en_US'
): Promise<ProductDataApiResult> => {
	const types = `frame-sizes`

	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${PRODUCT_DATA_ENDPOINT}?language=${language}&types=${types}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get all the frame type product data.
 *
 * @return {Promise<ProductData[]>} frame types - All the available frame types
 */
export const getFrameTypesProductDataAPI = async (
	language = 'en_US'
): Promise<ProductDataApiResult> => {
	const types = `frame-types`

	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${PRODUCT_DATA_ENDPOINT}?language=${language}&types=${types}`,
		{
			method: 'GET',
		}
	)
}
