import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {validateAuth} from '@redux/slices/auth-slice'
import {navigate} from '@redux/slices/navigation-slice'
import {CONSENT_GIVEN} from '@utils/constants/auth-constants'
import {LanguageContext} from '@utils/context'

/**
 * AuthWrapper
 */
interface AuthWrapperProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
	isPrivateRoute: boolean
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({
	children,
	isPrivateRoute,
}) => {
	const isBrowser = typeof window !== 'undefined'
	const dispatch = useDispatch()
	const {activeLanguage} = useContext(LanguageContext)

	const {isAuthenticated, validationFinished} = useSelector(
		(state: RootState) => state.auth
	)

	useEffect(() => {
		if (validationFinished && !isAuthenticated) {
			dispatch(navigate('/login', {replace: true}))
		} else if (
			isAuthenticated &&
			localStorage.getItem(CONSENT_GIVEN) === 'false'
		) {
			dispatch(
				navigate(`/${activeLanguage}/app/consent`, {
					replace: true,
				})
			)
		}
	}, [isAuthenticated, validationFinished])

	useEffect(() => {
		if (isBrowser && isPrivateRoute) {
			dispatch(validateAuth())
		}
	}, [isPrivateRoute, dispatch, isBrowser])

	const componentToRender = <React.Fragment>{children}</React.Fragment>
	const waitingComponent: JSX.Element | null = null

	return isBrowser && isPrivateRoute
		? validationFinished
			? componentToRender
			: waitingComponent
		: componentToRender
}

AuthWrapper.propTypes = {
	children: PropTypes.node,
	isPrivateRoute: PropTypes.any,
}

export default AuthWrapper
