import {FeatureFlag} from '@utils/types'

/**
 * Parses a comma separted list of enabled feature flags to an object of booleans
 *
 * @param featureFlagsCommaSeparated string of comma separated enabled feature flags
 */
export const parseFeatureFlags = (
	featureFlagsCommaSeparated: string | undefined
): Record<FeatureFlag, boolean> => {
	if (!featureFlagsCommaSeparated) {
		return {} as Record<FeatureFlag, boolean>
	}

	return featureFlagsCommaSeparated.split(',').reduce((acc, feature) => {
		acc[feature as FeatureFlag] = true

		return acc
	}, {} as Record<FeatureFlag, boolean>)
}
