import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {createQuotationAPI} from '@api/quotations-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Quotation} from '@utils/types'

interface QuotationCreationState {
	quotation: Quotation
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: QuotationCreationState = {
	quotation: undefined as any,
	loading: false,
	success: false,
	error: null,
}

const quotationCreationSlice = createSlice({
	name: 'quotationCreation',
	initialState,
	reducers: {
		createQuotationStart(state): void {
			state.loading = true
			state.error = null
		},
		createQuotationSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		createQuotationFailure(state, action: PayloadAction<string>): void {
			state.success = false
			state.loading = false
			state.error = action.payload
		},
		createQuotationReset(state): void {
			state.loading = initialState.loading
			state.success = initialState.success
			state.error = initialState.error
			state.quotation = initialState.quotation
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): QuotationCreationState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	createQuotationStart,
	createQuotationSuccess,
	createQuotationFailure,
	createQuotationReset,
} = quotationCreationSlice.actions
export default quotationCreationSlice.reducer

export const createQuotation =
	(newQuotation: Quotation): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(createQuotationStart())
			const success = await createQuotationAPI(newQuotation)

			if (success) {
				dispatch(createQuotationSuccess())
			} else {
				dispatch(createQuotationFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(createQuotationFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetQuotation =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(createQuotationReset())
	}

export const uploadDocument =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(createQuotationStart())
	}
