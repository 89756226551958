export enum BicycleStatus {
	ALL = 'ALL',
	CYCLIS_TO_ORDER = 'CYCLIS_TO_ORDER',
	CYCLIS_ORDERED = 'CYCLIS_ORDERED',
	DEALER_ORDERED = 'DEALER_ORDERED',
	DELIVERY_DATE_GIVEN = 'DELIVERY_DATE_GIVEN',
	READY_FOR_PICKUP = 'READY_FOR_PICKUP',
	PICKUP_INFO_AVAILABLE = 'PICKUP_INFO_AVAILABLE',
	DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
	CUSTOMER_LEASE_CANCELED = 'CUSTOMER_LEASE_CANCELED',
	END_LEASE = 'END_LEASE',
}

export enum QuotationStatus {
	ALL = 'ALL',
	TO_CONFIRM = 'TO_CONFIRM',
	CYCLIS_CONFIRMATION = 'CYCLIS_CONFIRMATION',
	CYCLIS_CANCELED = 'CYCLIS_CANCELED',
	CYCLIST_CONFIRMATION = 'CYCLIST_CONFIRMATION',
	CUSTOMER_CONFIRMATION = 'CUSTOMER_CONFIRMATION',
	CUSTOMER_REJECTION = 'CUSTOMER_REJECTION',
}
