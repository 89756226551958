import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getQuotationDocumentAPI} from '@api/documents-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'

interface QuotationsState {
	documentDownloadUrl: string
	documentLoading: boolean
	documentSuccess: boolean
	error: string | null
}

interface DocumentSuccess {
	downloadUrl: string
}

const initialState: QuotationsState = {
	documentDownloadUrl: undefined as any,
	documentLoading: false,
	documentSuccess: false,
	error: null,
}

const quotationDetailSlice = createSlice({
	name: 'quotationDetail',
	initialState,
	reducers: {
		getDocumentStart(state): void {
			state.documentLoading = true
			state.error = null
		},
		getDocumentSuccess(state, action: PayloadAction<DocumentSuccess>): void {
			const {downloadUrl} = action.payload

			state.documentDownloadUrl = downloadUrl
			state.documentLoading = false
			state.documentSuccess = true
			state.error = null
		},
		getDocumentFailure(state, action: PayloadAction<string>): void {
			state.documentLoading = false
			state.documentSuccess = false
			state.error = action.payload
		},
		resetDocument(state): void {
			state.documentLoading = initialState.documentLoading
			state.documentSuccess = initialState.documentSuccess
			state.documentDownloadUrl = initialState.documentDownloadUrl
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): QuotationsState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getDocumentStart,
	getDocumentSuccess,
	getDocumentFailure,
	resetDocument,
} = quotationDetailSlice.actions
export default quotationDetailSlice.reducer

export const getQuotationDocumentDownloadUrl =
	(quotationId: string, documentId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getDocumentStart())
			const downloadUrl: string = await getQuotationDocumentAPI(
				quotationId,
				documentId
			)

			dispatch(getDocumentSuccess({downloadUrl}))
		} catch (err) {
			dispatch(getDocumentFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetQuotationDocument =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetDocument())
	}
