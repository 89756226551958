import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import sha1 from 'js-sha1'

import {
	getDeliveryDocumentAPI,
	getDeliveryDocumentUploadUrlsAPI,
} from '@api/documents-api'
import {
	orderPickupInfoAvailableAPI,
	orderToDealerOrderedAPI,
	orderToDeliveryCompletedAPI,
	orderToDeliveryDateGivenAPI,
	orderToReadyForPickupAPI,
} from '@api/order-flow-api'
import {uploadFileAPI} from '@api/quotations-api'
import {AppThunk} from '@redux/store'
import {getBicycleDetail} from '@templates/BicycleDetail/bicycle-detail-slice'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {isEmptyString} from '@utils/string-utils'
import {BicycleStatus} from '@utils/types/status'

interface BicycleCTAState {
	loading: boolean
	success: boolean
	error: string | null
	deliveryDocumentDownloadUrl: string | null
	upload: {
		deliveryDocumentIdentifier: string | null
		deliveryDocumentUploadUrl: string | null
		invoiceDocumentIdentifier: string | null
		invoiceDocumentUploadUrl: string | null
	}
}

interface DocumentSuccess {
	downloadUrl: string
}

interface RetrieveUploadDataSuccess {
	deliveryDocumentIdentifier: string | null
	deliveryDocumentUploadUrl: string | null
	invoiceDocumentIdentifier: string | null
	invoiceDocumentUploadUrl: string | null
}

const initialState: BicycleCTAState = {
	loading: false,
	success: false,
	error: null,
	deliveryDocumentDownloadUrl: null,
	upload: {
		deliveryDocumentIdentifier: null,
		deliveryDocumentUploadUrl: null,
		invoiceDocumentIdentifier: null,
		invoiceDocumentUploadUrl: null,
	},
}

const bicycleCtaSlice = createSlice({
	name: 'bicycleCta',
	initialState,
	reducers: {
		orderFlowChangeStart(state): void {
			state.loading = true
			state.error = null
		},
		orderFlowChangeSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		orderFlowChangeFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.success = false
			state.error = action.payload
		},
		getDownloadUrlSuccess(state, action: PayloadAction<DocumentSuccess>): void {
			state.deliveryDocumentDownloadUrl = action.payload.downloadUrl
			state.error = null
		},
		getDownloadUrlFailure(state, action: PayloadAction<string>): void {
			state.deliveryDocumentDownloadUrl = null
			state.error = action.payload
		},
		getUploadUrlsSuccess(
			state,
			action: PayloadAction<RetrieveUploadDataSuccess>
		): void {
			state.upload.deliveryDocumentIdentifier =
				action.payload.deliveryDocumentIdentifier
			state.upload.deliveryDocumentUploadUrl =
				action.payload.deliveryDocumentUploadUrl
			state.upload.invoiceDocumentIdentifier =
				action.payload.invoiceDocumentIdentifier
			state.upload.invoiceDocumentUploadUrl =
				action.payload.invoiceDocumentUploadUrl
			state.error = null
		},
		getUploadUrlsFailure(state, action: PayloadAction<string>): void {
			state.error = action.payload
		},
		uploadDocumentsStart(state): void {
			state.loading = true
		},
		uploadDocumentsSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		uploadDocumentsFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		resetOrderFlowChange(state): void {
			state.loading = initialState.loading
			state.success = initialState.success
			state.error = initialState.error
			state.deliveryDocumentDownloadUrl =
				initialState.deliveryDocumentDownloadUrl
			state.upload = initialState.upload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): BicycleCTAState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	orderFlowChangeStart,
	orderFlowChangeSuccess,
	orderFlowChangeFailure,
	getDownloadUrlSuccess,
	getDownloadUrlFailure,
	getUploadUrlsSuccess,
	getUploadUrlsFailure,
	uploadDocumentsStart,
	uploadDocumentsSuccess,
	uploadDocumentsFailure,
	resetOrderFlowChange,
} = bicycleCtaSlice.actions
export default bicycleCtaSlice.reducer

export const orderReadyForPickup =
	(
		bicycleId: number,
		actualDeliveryDate: Date,
		hasPrefilledPickupInfo: boolean,
		isSpeedBike: boolean,
		frameNumber?: string,
		keyNumber?: string,
		numberPlate?: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(orderFlowChangeStart())

			const isAllBasePickupInfoFilledIn =
				!isEmptyString(frameNumber) && !isEmptyString(keyNumber)
			const isAllBicyclePickupInfoFilledIn = isSpeedBike
				? isAllBasePickupInfoFilledIn && !isEmptyString(numberPlate)
				: isAllBasePickupInfoFilledIn
			const partialPickupInfoFilledIn =
				(isEmptyString(frameNumber) && !isEmptyString(keyNumber)) ||
				(!isEmptyString(frameNumber) && isEmptyString(keyNumber)) ||
				(!isEmptyString(numberPlate) && isEmptyString(frameNumber)) ||
				(!isEmptyString(numberPlate) && isEmptyString(keyNumber))

			if (!hasPrefilledPickupInfo && partialPickupInfoFilledIn) {
				dispatch(
					orderFlowChangeFailure('DealerOrderFlowPickupInfoTogetherError')
				)
				return
			}

			const status = isAllBicyclePickupInfoFilledIn
				? BicycleStatus.PICKUP_INFO_AVAILABLE
				: BicycleStatus.READY_FOR_PICKUP

			const success = await orderToReadyForPickupAPI(
				bicycleId,
				status,
				actualDeliveryDate,
				frameNumber,
				keyNumber,
				numberPlate
			)

			if (success) {
				dispatch(orderFlowChangeSuccess())
				dispatch(getBicycleDetail(bicycleId))
				dispatch(resetOrderFlowChange())
			} else {
				dispatch(orderFlowChangeFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(orderFlowChangeFailure(getTranslationFromError(err as Error)))
		}
	}

export const orderPickupInfoAvailable =
	(
		bicycleId: number,
		actualDeliveryDate: string,
		frameNumber?: string,
		keyNumber?: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(orderFlowChangeStart())

			const success = await orderPickupInfoAvailableAPI(
				bicycleId,
				actualDeliveryDate,
				frameNumber,
				keyNumber
			)

			if (success) {
				dispatch(orderFlowChangeSuccess())
				dispatch(getBicycleDetail(bicycleId))
				dispatch(resetOrderFlowChange())
			} else {
				dispatch(orderFlowChangeFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(orderFlowChangeFailure(getTranslationFromError(err as Error)))
		}
	}

export const orderConfirm =
	(bicycleId: number): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(orderFlowChangeStart())

			const successOrdered = await orderToDealerOrderedAPI(bicycleId)

			if (successOrdered) {
				dispatch(orderFlowChangeSuccess())
				dispatch(getBicycleDetail(bicycleId))
				dispatch(resetOrderFlowChange())
			} else {
				dispatch(orderFlowChangeFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(orderFlowChangeFailure(getTranslationFromError(err as Error)))
		}
	}

export const orderDeliveryDateGiven =
	(bicycleId: number, deliveryDate?: Date): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(orderFlowChangeStart())

			const successDeliveryDate = await orderToDeliveryDateGivenAPI(
				bicycleId,
				deliveryDate!
			)

			if (successDeliveryDate) {
				dispatch(orderFlowChangeSuccess())
				dispatch(getBicycleDetail(bicycleId))
				dispatch(resetOrderFlowChange())
			} else {
				dispatch(orderFlowChangeFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(orderFlowChangeFailure(getTranslationFromError(err as Error)))
		}
	}

export const startDeliveryDocumentDownload =
	(bicycleId: number, documentId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			const downloadUrl = await getDeliveryDocumentAPI(bicycleId, documentId)

			dispatch(getDownloadUrlSuccess({downloadUrl}))
		} catch (error: any) {
			const statusCode = JSON.parse(error.message).statusCode

			if (statusCode === 404) {
				dispatch(
					getDownloadUrlFailure('ErrorDownloadingUnsignedDeliveryDocument')
				)
			} else {
				dispatch(getDownloadUrlFailure(getTranslationFromError(error)))
			}
		}
	}

export const retrieveDeliveryUploadUrls =
	(bicycleId: number): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			const uploadData = await getDeliveryDocumentUploadUrlsAPI(bicycleId)

			if (uploadData) {
				dispatch(getUploadUrlsSuccess({...uploadData}))
			} else {
				dispatch(getUploadUrlsFailure('UnknownError'))
			}
		} catch {
			dispatch(getUploadUrlsFailure('UnknownError'))
		}
	}

export const uploadDeliveryDocuments =
	(
		bicycleId: number,
		upload: RetrieveUploadDataSuccess,
		deliveryDocument: File,
		invoiceDocument: File,
		pickupDate: Date,
		velopassId: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(uploadDocumentsStart())

			await uploadFileAPI(upload.deliveryDocumentUploadUrl!, deliveryDocument)
			await uploadFileAPI(upload.invoiceDocumentUploadUrl!, invoiceDocument)

			const deliveryDocumentChecksum = sha1
				.create()
				.update(await new Response(deliveryDocument).arrayBuffer())
				.hex()

			const invoiceDocumentChecksum = sha1
				.create()
				.update(await new Response(invoiceDocument).arrayBuffer())
				.hex()

			const success = await orderToDeliveryCompletedAPI(
				bicycleId,
				upload.deliveryDocumentIdentifier!,
				deliveryDocumentChecksum,
				upload.invoiceDocumentIdentifier!,
				invoiceDocumentChecksum,
				pickupDate,
				velopassId
			)

			if (success) {
				dispatch(uploadDocumentsSuccess())
			} else {
				dispatch(uploadDocumentsFailure('UnknownError'))
			}
		} catch {
			dispatch(uploadDocumentsFailure('UnknownError'))
		}
	}

export const orderReset =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetOrderFlowChange())
	}
