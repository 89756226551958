import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import sha1 from 'js-sha1'

import {getBicycleDetailAPI} from '@api/bicycles-api'
import {getDeliveryDocumentAPI} from '@api/documents-api'
import {uploadFileAPI} from '@api/file-api'
import {createMaintenanceEventAPI, getUploadUrlAPI} from '@api/maintenances-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Bicycle} from '@utils/types'
import {FileType} from '@utils/types/file-types'

interface BicycleDetailState {
	bicycle: Bicycle
	loading: boolean
	success: boolean
	documentDownloadUrl: string
	documentLoading: boolean
	documentSuccess: boolean
	maintenanceUploadSuccess: boolean
	maintenanceUploadLoading: boolean
	error: string | null
}

interface BicycleDetailSuccess {
	bicycle: Bicycle
}

interface DocumentSuccess {
	downloadUrl: string
}

const initialState: BicycleDetailState = {
	bicycle: null as any,
	loading: false,
	success: false,
	documentDownloadUrl: undefined as any,
	documentLoading: false,
	documentSuccess: false,
	maintenanceUploadSuccess: false,
	maintenanceUploadLoading: false,
	error: null,
}

const bicycleDetailSlice = createSlice({
	name: 'bicycleDetail',
	initialState,
	reducers: {
		getBicycleDetailStart(state): void {
			state.loading = true
			state.error = null
		},
		getBicycleDetailSuccess(
			state,
			action: PayloadAction<BicycleDetailSuccess>
		): void {
			const {bicycle} = action.payload

			state.bicycle = bicycle
			state.loading = false
			state.success = true
			state.error = null
		},
		getBicycleDetailFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		resetBicycleDetail(state): void {
			state.bicycle = initialState.bicycle
			state.loading = initialState.loading
			state.success = initialState.success
			state.maintenanceUploadSuccess = initialState.maintenanceUploadSuccess
			state.maintenanceUploadLoading = initialState.maintenanceUploadLoading
			state.error = initialState.error
		},
		getBicycleDocumentStart(state): void {
			state.documentLoading = true
			state.error = null
		},
		getBicycleDocumentSuccess(
			state,
			action: PayloadAction<DocumentSuccess>
		): void {
			const {downloadUrl} = action.payload

			state.documentDownloadUrl = downloadUrl
			state.documentLoading = false
			state.documentSuccess = true
			state.error = null
		},
		getBicycleDocumentFailure(state, action: PayloadAction<string>): void {
			state.documentLoading = false
			state.documentSuccess = false
			state.error = action.payload
		},
		resetBicycleDocument(state): void {
			state.documentLoading = initialState.documentLoading
			state.documentSuccess = initialState.documentSuccess
			state.documentDownloadUrl = initialState.documentDownloadUrl
		},
		uploadMaintenanceDocumentStart(state): void {
			state.maintenanceUploadSuccess = false
			state.maintenanceUploadLoading = true
			state.error = null
		},
		uploadMaintenanceDocumentSuccess(state): void {
			state.maintenanceUploadLoading = false
			state.maintenanceUploadSuccess = true
		},
		uploadMaintenanceDocumentFailure(
			state,
			action: PayloadAction<string>
		): void {
			state.maintenanceUploadLoading = false
			state.error = action.payload
		},
		uploadMaintenanceDocumentReset(state): void {
			state.maintenanceUploadLoading = initialState.maintenanceUploadLoading
			state.maintenanceUploadSuccess = initialState.maintenanceUploadSuccess
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): BicycleDetailState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getBicycleDetailStart,
	getBicycleDetailSuccess,
	getBicycleDetailFailure,
	resetBicycleDetail,
	getBicycleDocumentStart,
	getBicycleDocumentSuccess,
	getBicycleDocumentFailure,
	resetBicycleDocument,
	uploadMaintenanceDocumentStart,
	uploadMaintenanceDocumentSuccess,
	uploadMaintenanceDocumentFailure,
	uploadMaintenanceDocumentReset,
} = bicycleDetailSlice.actions
export default bicycleDetailSlice.reducer

export const getBicycleDetail =
	(bicycleId: number): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getBicycleDetailStart())
			const bicycle = await getBicycleDetailAPI(bicycleId)

			dispatch(
				getBicycleDetailSuccess({
					bicycle,
				})
			)
		} catch (err) {
			console.log('err', err)
			dispatch(getBicycleDetailFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetBicycleDetailState =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetBicycleDetail())
	}

export const getBicycleDeliveryDocumentDownloadUrl =
	(bicycleId: number, documentId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getBicycleDocumentStart())
			const downloadUrl: string = await getDeliveryDocumentAPI(
				bicycleId,
				documentId
			)

			dispatch(getBicycleDocumentSuccess({downloadUrl}))
		} catch (err) {
			dispatch(getBicycleDocumentFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetBicycleDeliveryDocument =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetBicycleDocument())
	}

export const uploadMaintenanceDocument =
	(file: File, bicycleId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(uploadMaintenanceDocumentStart())

			// Get upload URL
			const invoiceDocumentUrl = await getUploadUrlAPI<{
				invoiceDocumentIdentifier: string
				invoiceDocumentUploadUrl: string
			}>(bicycleId, 'maintenance-documents', file.name)

			// Upload file
			const successUpload = await uploadFileAPI(
				invoiceDocumentUrl.invoiceDocumentUploadUrl,
				file,
				[FileType.PDF, FileType.JPG, FileType.PNG, FileType.JPEG].join(',')
			)

			const fileBinary = await new Response(file).arrayBuffer()
			const checksum = sha1.create().update(fileBinary).hex()

			const successMaintenance = await createMaintenanceEventAPI(
				bicycleId,
				invoiceDocumentUrl.invoiceDocumentIdentifier,
				checksum
			)

			if (successUpload && successMaintenance) {
				dispatch(uploadMaintenanceDocumentSuccess())
			} else {
				dispatch(uploadMaintenanceDocumentFailure('UnknownError'))
			}
		} catch (error) {
			dispatch(
				uploadMaintenanceDocumentFailure(
					getTranslationFromError(error as Error)
				)
			)
		}
	}

export const resetBicycleMaintenanceDocument =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(uploadMaintenanceDocumentReset())
	}
