import {i18n as i18nType} from 'i18next'
import decodeJwt from 'jwt-decode'

import {DEFAULT_LANGUAGE} from '@i18n/i18n-config'
import locales from '@i18n/locales'
import {windowNavigatorExists} from '@utils/window-utils'

import {IDENTITY_TOKEN_STORAGE_KEY} from './constants/auth-constants'
import {IdentityToken} from './types'

export const getLang = (): string => {
	const identityToken =
		typeof window === 'undefined'
			? ''
			: localStorage.getItem(IDENTITY_TOKEN_STORAGE_KEY)

	if (identityToken) {
		const decodedToken: IdentityToken = decodeJwt(identityToken)

		if (decodedToken?.language) {
			return decodedToken.language
		}
	}

	const languages = Object.keys(locales)
	const browserLanguage =
		windowNavigatorExists() && window.navigator.language.replace('-', '_')

	return (
		(languages.some((l) => l.includes(browserLanguage as string)) &&
			languages[
				languages.findIndex((l) => l.includes(browserLanguage as string))
			]) ||
		DEFAULT_LANGUAGE
	)
}

export const changeLanguage = async (
	i18n: i18nType,
	langFromPath: string
): Promise<string> => {
	let lang = langFromPath

	// No language in URL path; take Browser language
	if (!langFromPath) {
		lang = getLang()
	}

	if (!lang) {
		lang = DEFAULT_LANGUAGE
	}

	await i18n.changeLanguage(lang)

	return lang
}
