import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {forgotPasswordAPI} from '@api/auth-api'
import {AppThunk} from '@redux/store'
import {getTranslationFromError} from '@utils/errors'

interface ForgotPasswordState {
	username: string
	loading: boolean
	success: boolean
	error: string | null
}

interface ForgotPasswordSuccess {
	username: string
}

const initialState: ForgotPasswordState = {
	username: null as any,
	loading: false,
	success: false,
	error: null,
}

const forgotPasswordSlice = createSlice({
	name: 'forgotPassword',
	initialState,
	reducers: {
		forgotPasswordStart(state): void {
			state.loading = true
			state.error = null
		},
		forgotPasswordSuccess(
			state,
			action: PayloadAction<ForgotPasswordSuccess>
		): void {
			const {username} = action.payload

			state.username = username
			state.loading = false
			state.success = true
			state.error = null
		},
		forgotPasswordFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
	},
})

export const {
	forgotPasswordStart,
	forgotPasswordSuccess,
	forgotPasswordFailure,
} = forgotPasswordSlice.actions
export default forgotPasswordSlice.reducer

export const forgotPassword =
	(username: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(forgotPasswordStart())
			await forgotPasswordAPI(username)
			dispatch(forgotPasswordSuccess({username}))
		} catch (err) {
			dispatch(forgotPasswordFailure(getTranslationFromError(err as Error)))
		}
	}
