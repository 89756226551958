import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import decodeJwt from 'jwt-decode'

import {loginWithSsoAPI} from '@api/auth-api'
import {
	getIdentityTokenSuccess,
	validateAuthSuccess,
} from '@redux/slices/auth-slice'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {
	IDENTITY_TOKEN_STORAGE_KEY,
	REFRESH_TOKEN_STORAGE_KEY,
} from '@utils/constants/auth-constants'
import {IdentityToken} from '@utils/types'

interface SsoState {
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: SsoState = {
	loading: false,
	success: false,
	error: null,
}

const ssoSlice = createSlice({
	name: 'sso',
	initialState,
	reducers: {
		ssoStart(state): void {
			state.loading = true
			state.error = null
		},
		ssoSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		ssoFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): SsoState => {
			return {
				...initialState,
			}
		},
	},
})

export const {ssoStart, ssoSuccess, ssoFailure} = ssoSlice.actions
export default ssoSlice.reducer

export const sso =
	(token: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(ssoStart())
			const {
				token: bearerToken,
				identityToken,
				refreshToken,
			} = await loginWithSsoAPI(token)

			if (bearerToken === undefined) {
				dispatch(ssoFailure('Undefined JWT'))
			} else {
				// Store new refreshToken in local storage
				localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken)
				localStorage.setItem(IDENTITY_TOKEN_STORAGE_KEY, identityToken)

				const {firstName, lastName, language} = decodeJwt(
					identityToken
				) as IdentityToken

				// Store new bearerToken in memory
				dispatch(validateAuthSuccess({bearerToken}))
				dispatch(getIdentityTokenSuccess({firstName, lastName, language}))
				dispatch(ssoSuccess())
			}
		} catch (err) {
			dispatch(ssoFailure(err as string))
		}
	}
