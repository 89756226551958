import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {searchCyclistsAPI} from '@api/cyclists-api'
import {getDealerStatisticsAPI} from '@api/dealers-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Cyclist} from '@utils/types'

interface CyclistsState {
	cyclists: Cyclist[]
	loading: boolean
	success: boolean
	statisticsSuccess: boolean
	statisticsLoading: boolean
	statistics: {
		orderToBeConfirmed: number
		readyToDeliver: number
	}
	error: string | null
}

interface RetrieveDealerStatisticsSuccess {
	orderToBeConfirmed: number
	readyToDeliver: number
}

interface CyclistsSuccess {
	cyclists: Cyclist[]
}

const initialState: CyclistsState = {
	cyclists: [],
	loading: false,
	success: false,
	statisticsSuccess: false,
	statisticsLoading: false,
	statistics: {
		orderToBeConfirmed: null as any,
		readyToDeliver: null as any,
	},
	error: null,
}

const cyclistsSlice = createSlice({
	name: 'cyclists',
	initialState,
	reducers: {
		getCyclistsStart(state): void {
			state.loading = true
			state.error = null
		},
		getCyclistsSuccess(state, action: PayloadAction<CyclistsSuccess>): void {
			const {cyclists} = action.payload

			state.cyclists = cyclists
			state.loading = false
			state.success = true
			state.error = null
		},
		getCyclistsFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		resetCyclists(state): void {
			state.loading = initialState.loading
			state.success = initialState.success
			state.error = initialState.error
			state.cyclists = initialState.cyclists
		},
		getDealerStatisticsStart(state): void {
			state.statisticsLoading = true
			state.error = null
		},
		getDealerStatisticsSuccess(
			state,
			action: PayloadAction<RetrieveDealerStatisticsSuccess>
		): void {
			state.statisticsSuccess = true
			state.statisticsLoading = false
			state.error = null
			state.statistics.orderToBeConfirmed = action.payload.orderToBeConfirmed
			state.statistics.readyToDeliver = action.payload.readyToDeliver
		},
		getDealerStatisticsFailure(state, action: PayloadAction<string>): void {
			state.error = action.payload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): CyclistsState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getCyclistsStart,
	getCyclistsSuccess,
	getCyclistsFailure,
	resetCyclists,
	getDealerStatisticsStart,
	getDealerStatisticsSuccess,
	getDealerStatisticsFailure,
} = cyclistsSlice.actions
export default cyclistsSlice.reducer

export const getCyclists =
	(filter: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getCyclistsStart())
			const cyclists = (await searchCyclistsAPI(filter)).map((c) => ({
				...c,
				cyclistFirstName: c.cyclistFirstName || '',
				cyclistLastName: c.cyclistLastName || '',
			}))

			dispatch(getCyclistsSuccess({cyclists}))
		} catch (err) {
			console.log('err', err)
			dispatch(getCyclistsFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetCyclistState =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetCyclists())
	}

export const getDealerStatistics =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getDealerStatisticsStart())

			const statistics = await getDealerStatisticsAPI()

			dispatch(getDealerStatisticsSuccess(statistics))
		} catch (err) {
			dispatch(
				getDealerStatisticsFailure(getTranslationFromError(err as Error))
			)
		}
	}
