import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getQuotationDocumentAPI} from '@api/documents-api'
import {getQuotationByIdAPI, getQuotationsAPI} from '@api/quotations-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Quotation} from '@utils/types'

type QuotationSetter = (quotation: Quotation) => void

interface QuotationsState {
	quotations: Quotation[]
	quotation: Quotation
	paginationCursor: string
	paginationHasMoreData: boolean
	loading: boolean
	success: boolean
	documentDownloadUrl: string
	documentQuotationId: string
	documentLoading: boolean
	documentSuccess: boolean
	error: string | null
}

interface QuotationsSuccess {
	quotations: Quotation[]
	paginationCursor: string
	paginationHasMoreData: boolean
}

interface DocumentStart {
	quotationId: string
}

interface DocumentSuccess {
	downloadUrl: string
}

const initialState: QuotationsState = {
	quotations: [],
	quotation: null as any,
	paginationCursor: undefined as any,
	paginationHasMoreData: false,
	loading: false,
	success: false,
	documentDownloadUrl: undefined as any,
	documentQuotationId: undefined as any,
	documentLoading: false,
	documentSuccess: false,
	error: null,
}

const quotationsSlice = createSlice({
	name: 'quotations',
	initialState,
	reducers: {
		getQuotationsStart(state): void {
			state.loading = true
			state.error = null
		},
		getQuotationsSuccess(
			state,
			action: PayloadAction<QuotationsSuccess>
		): void {
			const {quotations, paginationCursor, paginationHasMoreData} =
				action.payload

			state.quotations = quotations
			state.paginationCursor = paginationCursor
			state.paginationHasMoreData = paginationHasMoreData
			state.loading = false
			state.success = true
			state.error = null
		},
		getMoreQuotationsSuccess(
			state,
			action: PayloadAction<QuotationsSuccess>
		): void {
			const {quotations, paginationCursor, paginationHasMoreData} =
				action.payload

			state.quotations = [...state.quotations, ...quotations]
			state.paginationCursor = paginationCursor
			state.paginationHasMoreData = paginationHasMoreData
			state.loading = false
			state.success = true
			state.error = null
		},
		getQuotationsFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.success = false
			state.error = action.payload
		},
		getQuotationStart(state): void {
			state.loading = true
			state.success = false
			state.error = null
		},
		getQuotationSuccess(state, action: PayloadAction<Quotation>): void {
			state.loading = false
			state.error = null
			state.success = true
			state.quotation = action.payload
		},
		getQuotationFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
			state.success = false
		},
		resetQuotationPagination(state): void {
			state.paginationCursor = initialState.paginationCursor
			state.paginationHasMoreData = initialState.paginationHasMoreData
		},
		getDocumentStart(state, action: PayloadAction<DocumentStart>): void {
			const {quotationId} = action.payload

			state.documentQuotationId = quotationId
			state.documentLoading = true
			state.error = null
		},
		getDocumentSuccess(state, action: PayloadAction<DocumentSuccess>): void {
			const {downloadUrl} = action.payload

			state.documentDownloadUrl = downloadUrl
			state.documentLoading = false
			state.documentSuccess = true
			state.error = null
		},
		getDocumentFailure(state, action: PayloadAction<string>): void {
			state.documentLoading = false
			state.documentSuccess = false
			state.error = action.payload
		},
		resetDocument(state): void {
			state.documentLoading = initialState.documentLoading
			state.documentSuccess = initialState.documentSuccess
			state.documentDownloadUrl = initialState.documentDownloadUrl
			state.documentQuotationId = initialState.documentQuotationId
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): QuotationsState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getQuotationsStart,
	getQuotationsSuccess,
	getQuotationsFailure,
	getMoreQuotationsSuccess,
	getQuotationStart,
	getQuotationSuccess,
	getQuotationFailure,
	resetQuotationPagination,
	getDocumentStart,
	getDocumentSuccess,
	getDocumentFailure,
	resetDocument,
} = quotationsSlice.actions
export default quotationsSlice.reducer

export const getQuotations =
	(
		cursor?: string,
		search?: string,
		sortProperty?: string,
		sortOrder?: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(resetQuotationPagination())

			dispatch(getQuotationsStart())
			const {data, meta} = await getQuotationsAPI(
				cursor,
				search,
				sortProperty,
				sortOrder
			)

			dispatch(
				getQuotationsSuccess({
					quotations: data,
					paginationCursor: meta.cursor,
					paginationHasMoreData: meta.hasMoreData,
				})
			)
		} catch (err) {
			dispatch(getQuotationsFailure(getTranslationFromError(err as Error)))
		}
	}

export const getQuotationById =
	(quotationId: number, setQuotation: QuotationSetter): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getQuotationStart())
			const quotation = await getQuotationByIdAPI(quotationId)

			dispatch(getQuotationSuccess(quotation))
			setQuotation(quotation)
		} catch (err) {
			dispatch(getQuotationFailure(getTranslationFromError(err as Error)))
		}
	}

export const getQuotationsNextPage =
	(
		cursor?: string,
		search?: string,
		sortProperty?: string,
		sortOrder?: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getQuotationsStart())
			const {data, meta} = await getQuotationsAPI(
				cursor,
				search,
				sortProperty,
				sortOrder
			)

			dispatch(
				getMoreQuotationsSuccess({
					quotations: data,
					paginationCursor: meta.cursor,
					paginationHasMoreData: meta.hasMoreData,
				})
			)
		} catch (err) {
			dispatch(getQuotationsFailure(getTranslationFromError(err as Error)))
		}
	}

export const getQuotationDocumentDownloadUrl =
	(quotationId: string, documentId: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getDocumentStart({quotationId}))
			const downloadUrl: string = await getQuotationDocumentAPI(
				quotationId,
				documentId
			)

			dispatch(getDocumentSuccess({downloadUrl}))
		} catch (err) {
			dispatch(getDocumentFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetQuotationDocument =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetDocument())
	}
