import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getBicyclesAPI, getBicyclesCsvAPI} from '@api/bicycles-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Bicycle} from '@utils/types'

interface BicyclesState {
	bicycles: Bicycle[]
	statistics: {
		totalBicycles?: number
		totalFee?: number
	}
	paginationCursor: string
	paginationHasMoreData: boolean
	loading: boolean
	success: boolean
	csvContent: string
	loadingCsv: boolean
	csvSuccess: boolean
	error: string | null
}

interface BicyclesSuccess {
	bicycles: Bicycle[]
	paginationCursor: string
	paginationHasMoreData: boolean
}

interface BicyclesCsvSuccess {
	content: string
}

interface StatisticsSuccess {
	statistics: {
		totalBicycles?: number
		totalFee?: number
	}
}

const initialState: BicyclesState = {
	bicycles: [],
	paginationCursor: undefined as any,
	paginationHasMoreData: false,
	loading: false,
	success: false,
	error: null,
	statistics: {
		totalBicycles: 0,
		totalFee: 0,
	},
	csvContent: undefined as any,
	loadingCsv: false,
	csvSuccess: false,
}

const bicyclesSlice = createSlice({
	name: 'bicycles',
	initialState,
	reducers: {
		getBicyclesStart(state): void {
			state.loading = true
			state.error = null
		},
		getBicyclesSuccess(state, action: PayloadAction<BicyclesSuccess>): void {
			const {bicycles, paginationCursor, paginationHasMoreData} = action.payload

			state.bicycles = bicycles
			state.paginationCursor = paginationCursor
			state.paginationHasMoreData = paginationHasMoreData
			state.loading = false
			state.success = true
			state.error = null
		},
		getMoreBicyclesSuccess(
			state,
			action: PayloadAction<BicyclesSuccess>
		): void {
			const {bicycles, paginationCursor, paginationHasMoreData} = action.payload

			state.bicycles = [...state.bicycles, ...bicycles]
			state.paginationCursor = paginationCursor
			state.paginationHasMoreData = paginationHasMoreData
			state.loading = false
			state.success = true
			state.error = null
		},
		getStatisticsSuccess(
			state,
			action: PayloadAction<StatisticsSuccess>
		): void {
			const {statistics} = action.payload

			state.statistics.totalBicycles = statistics.totalBicycles
			state.statistics.totalFee = statistics.totalFee
		},
		getBicyclesFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		resetBicyclesPagination(state): void {
			state.paginationCursor = initialState.paginationCursor
			state.paginationHasMoreData = initialState.paginationHasMoreData
		},
		getBicyclesCsvStart(state): void {
			state.loadingCsv = true
			state.csvContent = undefined as any
			state.error = null
		},
		getBicyclesCsvSuccess(
			state,
			action: PayloadAction<BicyclesCsvSuccess>
		): void {
			const {content} = action.payload

			state.csvContent = content
			state.loadingCsv = false
			state.csvSuccess = true
			state.error = null
		},
		getBicycleCsvFailure(state, action: PayloadAction<string>): void {
			state.loadingCsv = false
			state.error = action.payload
		},
		resetBicylesCsvState(state): void {
			state.csvContent = undefined as any
			state.csvSuccess = false
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): BicyclesState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getBicyclesStart,
	getBicyclesSuccess,
	getMoreBicyclesSuccess,
	getStatisticsSuccess,
	resetBicyclesPagination,
	getBicyclesFailure,
	getBicyclesCsvStart,
	getBicyclesCsvSuccess,
	getBicycleCsvFailure,
	resetBicylesCsvState,
} = bicyclesSlice.actions
export default bicyclesSlice.reducer

export const getBicycles =
	(
		cursor?: string,
		search?: string,
		sortProperty?: string,
		sortOrder?: string,
		statusFilter?: string,
		stats?: boolean
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(resetBicyclesPagination())

			dispatch(getBicyclesStart())
			const {bicycles, statistics} = await getBicyclesAPI(
				cursor,
				search,
				sortProperty,
				sortOrder,
				statusFilter,
				stats
			)

			const {data, meta} = bicycles

			dispatch(
				getBicyclesSuccess({
					bicycles: data,
					paginationCursor: meta.cursor,
					paginationHasMoreData: meta.hasMoreData,
				})
			)

			if (stats && statistics.totalBicycles && statistics.totalFee) {
				dispatch(getStatisticsSuccess({statistics}))
			}
		} catch (err) {
			dispatch(getBicyclesFailure(getTranslationFromError(err as Error)))
		}
	}

export const getBicyclesNextPage =
	(
		cursor?: string,
		search?: string,
		sortProperty?: string,
		sortOrder?: string,
		statusFilter?: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getBicyclesStart())
			const {bicycles} = await getBicyclesAPI(
				cursor,
				search,
				sortProperty,
				sortOrder,
				statusFilter
			)

			const {data, meta} = bicycles

			dispatch(
				getMoreBicyclesSuccess({
					bicycles: data,
					paginationCursor: meta.cursor,
					paginationHasMoreData: meta.hasMoreData,
				})
			)
		} catch (err) {
			dispatch(getBicyclesFailure(getTranslationFromError(err as Error)))
		}
	}

export const getBicyclesCsv =
	(
		search?: string,
		sortProperty?: string,
		sortOrder?: string,
		statusFilter?: string
	): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getBicyclesCsvStart())
			const content = await getBicyclesCsvAPI(
				search,
				sortProperty,
				sortOrder,
				statusFilter
			)
			const byteOrder = `\ufeff`

			dispatch(
				getBicyclesCsvSuccess({
					content: `${byteOrder}${content}`,
				})
			)
		} catch (err) {
			dispatch(getBicycleCsvFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetBicyclesCsv =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetBicylesCsvState())
	}
