import {consumeJsonWithAuth, consumeTextWithAuth} from '@api/api-client'
import {Bicycle} from '@utils/types'
import PaginatedResult from '@utils/types/paginated-result'

const BICYCLES_ENDPOINT = 'bicycles'

/**
 * Get all bicycles for the user paginated.
 *
 * @return {Promise<PaginatedResult<Bicycle>>} bicycles - All the bicycles of the user (past and present) paginated
 */
export const getBicyclesAPI = async (
	cursor = '',
	search = '',
	sortProperty = '',
	sortOrder = '',
	statusFilter = '',
	statistics = false
): Promise<{
	bicycles: PaginatedResult<Bicycle>
	statistics: {totalBicycles?: number; totalFee?: number}
}> => {
	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${BICYCLES_ENDPOINT}?cursor=${cursor}&search=${search}&sortProperty=${sortProperty}&sortOrder=${sortOrder}&statusFilter=${statusFilter}&statistics=${statistics}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get all bicycles for the user in csv format
 *
 * @return {Promise<string>} bicycles in CSV format - All the bicycles of the user (past and present) in CSV format
 */
export const getBicyclesCsvAPI = async (
	search = '',
	sortProperty = '',
	sortOrder = '',
	statusFilter = ''
): Promise<string> => {
	return consumeTextWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${BICYCLES_ENDPOINT}/csv?search=${search}&sortProperty=${sortProperty}&sortOrder=${sortOrder}&statusFilter=${statusFilter}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Get a specific bicycle by id
 *
 * @return {Promise<Bicycle>} bicycle - Bicycle detail data
 */
export const getBicycleDetailAPI = async (id: number): Promise<Bicycle> => {
	const response = await consumeJsonWithAuth<Bicycle>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${BICYCLES_ENDPOINT}/${id}`,
		{
			method: 'GET',
		}
	)

	return response
}
