import {consume, consumeJsonWithAuth, consumeWithAuth} from '@api/api-client'
import {PaginatedResult, Quotation, UploadUrlResponse} from '@utils/types'

const QUOTATIONS_ENDPOINT = 'quotations'

/**
 * Get all quotations for the user paginated.
 *
 * @return {Promise<PaginatedResult<Quotation>>} quotations - A paginated list of all the quotations for this user
 */
export const getQuotationsAPI = async (
	cursor = '',
	search = '',
	sortProperty = '',
	sortOrder = ''
): Promise<PaginatedResult<Quotation>> => {
	const response = await consumeJsonWithAuth<PaginatedResult<Quotation>>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${QUOTATIONS_ENDPOINT}?cursor=${cursor}&search=${search}&sortProperty=${sortProperty}&sortOrder=${sortOrder}`,
		{
			method: 'GET',
		}
	)

	return response
}

/**
 * Get quotation for provided ID
 *
 * @param quotationId
 * @returns {Promise<Quotation>}
 */
export const getQuotationByIdAPI = async (
	quotationId: number
): Promise<Quotation> => {
	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${QUOTATIONS_ENDPOINT}/${quotationId}`,
		{
			method: 'GET',
		}
	)
}

/**
 * Create a new quotation for the user.
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const createQuotationAPI = async (
	quotation: Quotation
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${QUOTATIONS_ENDPOINT}`,
			{
				method: 'POST',
				body: JSON.stringify(quotation),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Get the quotation upload URL.
 *
 * @return {Promise<UploadUrlResponse>} response - Response body with the upload url and identifier
 */
export const getUploadURLAPI = async (): Promise<UploadUrlResponse> => {
	const response = await consumeJsonWithAuth<UploadUrlResponse>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${QUOTATIONS_ENDPOINT}/attachments`,
		{
			method: 'GET',
		}
	)

	return response
}

/**
 * Upload File
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const uploadFileAPI = async (
	uploadUrl: string,
	file: File
): Promise<boolean> => {
	try {
		const response = await consume(uploadUrl, {
			method: 'PUT',
			headers: {
				'x-ms-blob-type': 'BlockBlob',
				'Accept-Charset': 'UTF-8',
				'x-ms-version': '2017-11-09',
				'x-ms-blob-content-type': 'application/pdf',
			},
			body: file,
		})
		return response.status === 201
	} catch (error) {
		throw error
	}
}
;``
