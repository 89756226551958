import PropTypes from 'prop-types'
import React from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {validateAuth} from '@redux/slices/auth-slice'
import {resetError} from '@redux/slices/error-slice'
import {navigate} from '@redux/slices/navigation-slice'
import {onLoginExpired} from '@templates/Login/login-slice'
import {REFRESH_TOKEN_STORAGE_KEY} from '@utils/constants/auth-constants'

/**
 * ErrorWrapper
 * Handle errors passed through redux
 */
interface ErrorWrapperProps {
	children: unknown
}

export const ErrorWrapper: React.FC<ErrorWrapperProps> = ({children}) => {
	const dispatch = useDispatch()

	const {error} = useSelector((state: RootState) => state.error)
	const validationSuccess = useSelector(
		(state: RootState) => state.auth.success
	)
	const isValidatingAuth = useSelector((state: RootState) => state.auth.loading)
	const authError = useSelector((state: RootState) => state.auth.error)

	useEffect(() => {
		if (error) {
			console.log('ErrorWrapper useEffect error', error)
			const parsedErrorResponse = JSON.parse(error)
			if (parsedErrorResponse.statusCode === 401) {
				const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)
				if (refreshToken && !isValidatingAuth) {
					dispatch(validateAuth())
				}

				if (!refreshToken || !validationSuccess) {
					dispatch(navigate('/logout'))
				}
			}
		}
	}, [error, isValidatingAuth, validationSuccess])

	useEffect(() => {
		if (error) {
			const parsedErrorResponse = JSON.parse(error)
			if (parsedErrorResponse.statusCode === 401 && validationSuccess) {
				dispatch(resetError())
			}
		}
	}, [error, validationSuccess])

	useEffect(() => {
		if (!isValidatingAuth && authError && authError.includes('Expired JWT')) {
			dispatch(onLoginExpired())
			dispatch(navigate('/logout'))
		}
	}, [authError, isValidatingAuth])

	return <React.Fragment>{children}</React.Fragment>
}

ErrorWrapper.propTypes = {
	children: PropTypes.node,
}

export default ErrorWrapper
