import {createTheme} from '@material-ui/core'

/**
 * Theme definition to provide a global theme which overrides material-ui's default global theme
 * https://material-ui.com/customization/components/#global-theme-override
 */
export const LARGE_GUTTER_SIZE = 15
export const SMALL_GUTTER_SIZE = 2.5

const theme = createTheme({
	palette: {
		primary: {
			main: '#524fa2',
		},
		secondary: {
			main: '#19857b',
		},
		error: {
			main: '#f15a5b',
		},
		background: {
			default: '#fff',
		},
	},
	typography: {
		h1: {
			fontFamily: ['Montserrat', 'sans-serif'].join(','),
		},
		h2: {
			fontFamily: ['Montserrat', 'sans-serif'].join(','),
		},
		h3: {
			fontFamily: ['Montserrat', 'sans-serif'].join(','),
		},
		h4: {
			fontFamily: ['Montserrat', 'sans-serif'].join(','),
		},
		fontFamily: ['Work Sans', 'sans-serif'].join(','),
	},
})

export default theme
