import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getCyclistIdAPI} from '@api/cyclists-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'

interface CyclistAccountState {
	cyclistId: number | null
	loading: boolean
	success: boolean
	error: string | null
}

interface CyclistAccountSuccess {
	cyclistId: number | null
}

const initialState: CyclistAccountState = {
	cyclistId: undefined as any,
	loading: false,
	success: false,
	error: null,
}

const cyclistAccountSlice = createSlice({
	name: 'cyclistAccount',
	initialState,
	reducers: {
		getCyclistAccountStart(state): void {
			state.cyclistId = undefined as any
			state.loading = true
			state.error = null
		},
		getCyclistAccountSuccess(
			state,
			action: PayloadAction<CyclistAccountSuccess>
		): void {
			const {cyclistId} = action.payload

			state.cyclistId = cyclistId
			state.loading = false
			state.success = true
			state.error = null
		},
		getCyclistAccountFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		resetCyclistAccountStart(state): void {
			state.cyclistId = undefined as any
			state.loading = false
			state.success = false
			state.error = null
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): CyclistAccountState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getCyclistAccountStart,
	getCyclistAccountSuccess,
	getCyclistAccountFailure,
	resetCyclistAccountStart,
} = cyclistAccountSlice.actions
export default cyclistAccountSlice.reducer

export const getCyclistAccount =
	(email: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getCyclistAccountStart())
			const cyclistId = await getCyclistIdAPI(email)

			dispatch(getCyclistAccountSuccess({cyclistId}))
		} catch (err) {
			dispatch(getCyclistAccountFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetCyclistAccount =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(resetCyclistAccountStart())
	}
