import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {getAllProductData} from '@redux/slices/product-data-slice'
import {LanguageContext} from '@utils/context'

/**
 * ProductDataWrapper
 * Fetch all product data initially
 */
interface ProductDataWrapperProps {
	children: any
}

export const ProductDataWrapper: React.FC<ProductDataWrapperProps> = ({
	children
}) => {
	const dispatch = useDispatch()

	const {activeLanguage} = useContext(LanguageContext)

	const {isAuthenticated, validationFinished} = useSelector(
		(state: RootState) => state.auth
	)

	useEffect(() => {
		if (validationFinished && isAuthenticated) {
			dispatch(getAllProductData(activeLanguage))
		}
	}, [isAuthenticated, validationFinished])

	return <React.Fragment>{children}</React.Fragment>
}

ProductDataWrapper.propTypes = {
	children: PropTypes.node
}

export default ProductDataWrapper
