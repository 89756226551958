export default {
  LoginTitle1: `Welcome back`,
  LoginTitle2: `Sign in to continue`,
  LoginButton: `Sign in`,
  LoginEmailLabel: `E-mail address`,
  LoginPasswordLabel: `Password`,
  LoginForgotPasswordLabel: `Forgot password?`,
  LoginPasswordDefaultValue: `Enter password`,
  LoginEmailDefaultValue: `Enter e-mail address`,
  LoginPurpleTitle1: `The lease`,
  LoginPurpleTitle2: `we can do!`,
  LoginPurpleTextBlock: `Manage easily all Cyclis lease bikes in the dealer tool.`,
  LoginHRLink: `I'm HR`,
  LoginMyCyclisLink: `I'm a biker`,
  LogoutButton: `Logout`,
  UnknownError: `An unexpected error happened.`,
  ForgotPasswordTitle: `Forgot your password?`,
  ForgotPasswordSubTitle: `Enter your e-mail address associated to your account and we’ll send you instructions to reset your password.`,
  ForgotPasswordBackButton: `Back`,
  ForgotPasswordSendEmailButton: `Send reset mail`,
  ForgotPasswordSuccessTitle: `Check your e-mail!`,
  ForgotPasswordSuccessSubtitlePart1: `Tap the button in the mail that we’ve sent to`,
  ForgotPasswordSuccessSubtitlePart2: `to reset your password.`,
  CustomSuccessGoBack: `Go back`,
  SetPasswordTitle: `Change your current password.`,
  SetPasswordSubTitle: `Make sure that it’s a secure password which ‘checks’ includes the criteria below.`,
  SetPasswordSaveButton: `Save Password`,
  SetPasswordTwoSpecialCharacters: `2 special characters`,
  SetPasswordTwoUppercaseCharacters: `2 upper case characters`,
  SetPasswordTwoDigits: `2 digits`,
  SetPasswordTenCharacters: `10 characters in total`,
  SetPasswordCurrentPasswordLabel: `Current password`,
  SetPasswordConfirmPasswordLabel: `Confirm password`,
  SetPasswordError: `Your given current password does not match your password`,
  ResetPasswordTitle: `Enter a new password for your account.`,
  ResetPasswordSubTitle: `Make sure that it’s a secure password which ‘checks’ includes the criteria below.`,
  ResetPasswordSaveButton: `Save password`,
  ResetPasswordError: `The reset token is either invalid or expired.`,
  EmailSuccessTitle: `Check your e-mail!`,
  EmailSuccessGoBack: `Go back`,
  BicycleOverviewHeaderTitle: `My bicycles`,
  BicycleOverviewHeaderSubTitle: `The list below consists out of all the bicycles in lease trough your store.`,
  BicycleOverviewExportButton: `Export`,
  BicycleOverviewTotalNumberOfRecords: `Total number of records`,
  BicycleOverviewTotalLeaseAmountFee: `Total amount lease fee`,
  BicycleOverviewTableHeaderSoNum: `SO-number`,
  BicycleOverviewTableHeaderName: `Name`,
  BicycleOverviewTableHeaderBicycle: `Bicycle`,
  BicycleOverviewTableHeaderExpectedDeliveryDate: `Expected delivery date`,
  BicycleOverviewTableHeaderPickupDate: `Pick-up date`,
  BicycleOverviewTableHeaderStartOfContract: `Start contract`,
  BicycleOverviewTableHeaderEndOfContract: `End contract`,
  BicycleOverviewTableHeaderCurrentMaintenanceBudget: `Current maintenance budget`,
  BicycleOverviewTableHeaderBicycleStatus: `Status`,
  BicycleOverviewSearchInputPlaceholder: `Search...`,
  NavigationQuotations: `Quotations`,
  NavigationMyBicycles: `My bicycles`,
  NavigationDropdownChangePassword: `Change password`,
  NavigationDropdownLogout: `Logout`,
  NavigationHome: `Home`,
  NavigationOpenTasks: `Open tasks`,
  NavigationContact: `Contact`,
  QuotationCreationPageStepInvolvedParties: `Involved parties`,
  QuotationCreationPageStepBicycleInfo: `Bicycle info`,
  QuotationCreationPageStepAccessories: `Accessories`,
  QuotationCreationPageStepAdditionalInfo: `Additional info`,
  QuotationCreationPageStepUpload: `Upload`,
  QuotationCreationPageNextButton: `Next`,
  QuotationCreationPageBackButton: `Back`,
  QuotationCustomerId: `Employer`,
  QuotationCustomerPlaceholder: `Enter the customer name`,
  QuotationFormIdPlaceholder: `Enter a unique ID`,
  QuotationDealerId: `Dealer`,
  QuotationDealerPlaceholder: `Enter the dealer name`,
  QuotationReference: `Reference`,
  QuotationReferencePlaceholder: `Reference that will be put in each invoice`,
  QuotationCyclistEmail: `Cyclist e-mail`,
  QuotationCyclistEmailPlaceholder: `Enter e-mail`,
  QuotationFirstName: `First name`,
  QuotationFirstNamePlaceholder: `Eg. Richard`,
  QuotationLastName: `Last name`,
  QuotationLastNamePlaceholder: `Eg. Fietsmans`,
  QuotationEmailAddress: `E-mail address`,
  QuotationEmailAddressPlaceholder: `Enter e-mail address`,
  QuotationPhone: `Phone number`,
  QuotationPhonePlaceholder: `Enter a phone number`,
  QuotationLanguage: `Language`,
  QuotationBrand: `Brand`,
  QuotationModel: `Model`,
  QuotationTypeHere: `Type here...`,
  QuotationType: `Type`,
  QuotationFrameType: `Frame type`,
  QuotationFrameSize: `Frame size`,
  QuotationColor: `Color`,
  QuotationRetailPrice: `Retail price`,
  QuotationAccessoryDescription: `Description`,
  QuotationAccessoryDescriptionPlaceholder: `Description of this accessory`,
  QuotationAccessoryAmount: `Amount`,
  QuotationAccessoryPricePerPiece: `Price per piece`,
  QuotationAddNewAccessory: `Add new accessory`,
  QuotationAdministration: `Administration`,
  QuotationAdministrationCost: `Administration cost`,
  QuotationAdministrationDescription: `Administration description`,
  QuotationAdministrationPlaceholder: `Description of this administration cost`,
  QuotationAddNewAdministrationCost: `Add new administration cost`,
  QuotationExtrasToInclude: `Extra’s to include`,
  QuotationInsurance: `Insurance`,
  QuotationAssistance: `Assistance`,
  QuotationMaintenance: `Maintenance`,
  QuotationAdditionalMainBudget: `Additional maintenance budget`,
  QuotationAdditionalMainBudgetMin: `min. (standard included)`,
  QuotationAdditionalMainBudgetMax: `max.`,
  QuotationUploadTitle: `Upload document`,
  QuotationUploadDescription: `Please upload your quotation document (1) that you’ve received from one of our Bicycle dealers.`,
  QuotationCreateButton: `Create Quotation`,
  QuotationsOverViewTitle: `Quotations`,
  QuotationsOverViewSubTitle: `The list below consist out of all the quotations in your companies fleet.`,
  QuotationsOverViewCreateButton: `+ Create quotation`,
  QuotationsOverViewTableHeaderSoNum: `SO-number`,
  QuotationsOverViewTableHeaderCustomer: `Customer`,
  QuotationsOverViewTableHeaderCyclist: `Cyclist`,
  QuotationsOverViewTableHeaderBicycle: `Bicycle`,
  QuotationCreationSuccessTitle: `We're creating your quotation`,
  QuotationCreationSuccessSubTitle: `We'll send you an e-mail as soon as your quotation is ready`,
  QuotationCreationSuccessButtonText: `Back to overview`,
  QuotationCreationCouldNotFindCyclist: `Could not for a registered cyclist with the e-mail {{email}}.`,
  QuotationCreationSelectLanguagePlaceholder: `-- Select a language --`,
  QuotationCreationSelectPlaceholder: `-- Make a selection --`,
  QuotationDetailBackToOverview: `Back to overview`,
  QuotationDetailQuotationIdTitle: `Quotation ID`,
  QuotationDetailQuotationSoNumberTitle: `SO-number`,
  QuotationDetailQuotationId: `Quotation ID:`,
  QuotationDetailSONumber: `SO-number:`,
  QuotationDetailInvolvedPartiesTitle: `Involved parties`,
  QuotationDetailCustomer: `Customer:`,
  QuotationDetailCyclist: `Cyclist:`,
  QuotationDetailCyclistEmail: `Cyclist email:`,
  QuotationDetailDealer: `Dealer:`,
  QuotationDetailReference: `Reference:`,
  QuotationDetailBicycleInformation: `Bicycle information`,
  QuotationDetailBrand: `Brand:`,
  QuotationDetailModel: `Model:`,
  QuotationDetailType: `Type:`,
  QuotationDetailFrameType: `Frame type:`,
  QuotationDetailFrameSize: `Frame size:`,
  QuotationDetailColor: `Color:`,
  QuotationDetailRetailPrice: `Retail price:`,
  QuotationDetailTotalAccessoriesPrice: `Total accessories price:`,
  QuotationAccessoriesListTitle: `Accessories list`,
  QuotationDetailAccessoryPerPiece: `per piece`,
  QuotationDetailExtrasTitle: `Extra’s`,
  QuotationDetailExtrasAdditionalServices: `Additional services that are included in your leaseplan:`,
  QuotationDetailInsurance: `Insurance`,
  QuotationDetailAssistance: `Assistance`,
  QuotationDetailMaintenance: `Maintenance`,
  QuotationDetailExtraBudget: `Budget for extra maintenance:`,
  QuotationDetailBicycleLeaseTitle: `Bicycle lease`,
  QuotationDetailLeaseCost: `Lease cost:`,
  QuotationDetailAdministrationTitle: `Administration`,
  QuotationDetailAdministrationCost: `Administration cost:`,
  UploadHelperTextDragAndDrop: `Drag and drop`,
  UploadHelperTextQuotation: `your quotation here or`,
  UploadHelperTextClick: `click`,
  UploadHelperAllowedText: `Supports {{fileTypes}} {{maxSize}}`,
  UploadHelperAllowedTextFileTypes: `{{fileTypes}}, or {{lastFileType}}`,
  UploadHelperAllowedTextMaxSize: `up to {{maxSize}}`,
  UploadedText: `Uploaded`,
  UploadDeleteButton: `Delete`,
  QuotationDetailAttachmentsTitle: `Attachments`,
  QuotationDetailDownloadButton: `Download`,
  QuotationOverviewDownload: `Download`,
  ConsentTitle: `Privacy Policy.`,
  ConsentDate: `Updated on 1st January 2020`,
  ConsentBodyPart1: `When you use our services, you entrust us with your data. Cyclis Bike Lease has a privacy policy that helps you understand what data we collect, why we do it and what we do with that data.`,
  ConsentBodyPart2: `You can contact us for privacy-related matters via`,
  ConsentBodyTitle: `What information do we collect about you?`,
  ConsentBodyPart3: `Dolore qui amet non et eiusmod pariatur exercitation enim sit nulla eu nostrud eiusmod velit. Nulla ut amet officia quis aliqua sit anim excepteur ut. Excepteur deserunt fugiat esse non aliquip voluptate esse cillum nisi nostrud exercitation proident. Amet qui officia cillum qui. Incididunt ullamco labore do nostrud. Laborum quis ut voluptate irure consequat. Incididunt pariatur non nostrud adipisicing minim proident duis commodo laborum dolor aute veniam sunt. Fugiat aute in laborum proident aliqua irure ipsum et officia id proident fugiat ut culpa. Adipisicing dolor adipisicing quis velit ullamco eiusmod qui officia elit irure. Culpa cupidatat nostrud nostrud enim ut exercitation nisi id eiusmod dolor sunt. Esse officia commodo irure dolor est ea in id laboris et exercitation. Incididunt reprehenderit in ad nisi fugiat proident ut ad aliquip. Aliqua culpa Lorem dolor ad. Duis ipsum et reprehenderit laboris dolor aliquip adipisicing irure laborum. Veniam mollit id anim velit dolor. Et deserunt laborum fugiat qui est dolore labore non sit esse nulla enim.`,
  ConsentBodyPart4: `Dolore qui amet non et eiusmod pariatur exercitation enim sit nulla eu nostrud eiusmod velit. Nulla ut amet officia quis aliqua sit anim excepteur ut. Excepteur deserunt fugiat esse non aliquip voluptate esse cillum nisi nostrud exercitation proident. Amet qui officia cillum qui. Incididunt ullamco labore do nostrud. Laborum quis ut voluptate irure consequat. Incididunt pariatur non nostrud adipisicing minim proident duis commodo laborum dolor aute veniam sunt. Fugiat aute in laborum proident aliqua irure ipsum et officia id proident fugiat ut culpa. Adipisicing dolor adipisicing quis velit ullamco eiusmod qui officia elit irure. Culpa cupidatat nostrud nostrud enim ut exercitation nisi id eiusmod dolor sunt. Esse officia commodo irure dolor est ea in id laboris et exercitation. Incididunt reprehenderit in ad nisi fugiat proident ut ad aliquip. Aliqua culpa Lorem dolor ad. Duis ipsum et reprehenderit laboris dolor aliquip adipisicing irure laborum. Veniam mollit id anim velit dolor. Et deserunt laborum fugiat qui est dolore labore non sit esse nulla enim.`,
  ConsentSubmitButton: `Continue`,
  ConsentAcceptPart1: `I’ve read and accept the`,
  ConsentAcceptPart2: `Privacy Policy`,
  ConsentDownloadContainerTitle: `Privacy Policy`,
  ConsentDownloadContainerSubtitle: `Read the privacy policy`,
  ConsentDownloadButton: `Download`,
  ConsentAcceptButton: `Accept & Continue`,
  QuotationOverviewDocumentLoading: `Loading...`,
  QuotationOverviewHeaderDate: `Date`,
  LoginInvalidRole: `To access the portal you need more than just the cyclist role.`,
  HomePageTitlePartOne: `Welcome at Cyclis`,
  HomePageTitlePartTwo: `Dashboard`,
  HomePageSubTitle: `Find out which open tasks you still have to complete or start with helping your customer.`,
  HomePageStatsOrdersToBeConfirmed: `Orders to be confirmed`,
  HomePageStatsStatsViewOrders: `View orders`,
  HomePageReadyToDeliverBicycles: `Ready to deliver bicycles`,
  HomePageStatsDeliverBicycle: `Deliver bicycle`,
  HomePageSearchTitle: `Help your customer`,
  HomepageSearchSubTitle: `you can find their dossier by searching for their name and/or SO-number`,
  HomePageSearchInputPlaceholder: `Cyclist name or SO-number`,
  HomePageSearchButton: `Search`,
  HomePageCreateNewQuotationPartOne: `Create a`,
  HomePageCreateNewQuotationPartTwo: `New Quotation`,
  SearchResultPageBackToOverview: `Back to overview`,
  SearchResultPageTitle: `Search results for`,
  SearchResultHeaderSoNum: `SO-number`,
  SearchResultHeaderCyclist: `Cyclist`,
  SearchResultHeaderCompany: `Company`,
  SearchResultHeaderBicycle: `Bicycle`,
  CyclistProfileBackToOverview: `Back to overview`,
  CyclistProfileCurrentMaintenanceBudget: `Current maintenance budget`,
  CyclistProfileSeeMore: `See more`,
  CyclistProfileNewInsuranceCase: `New Insurance case`,
  CyclistProfileNewQuotation: `New Quotation`,
  CyclistProfileAddMaintenance: `Add Maintenance`,
  BicycleDetailBackToOverview: `Back to overview`,
  BicycleDetailHeaderSoNumber: `SO-number`,
  BicycleDetailMaintenanceTitle: `Maintenance`,
  BicycleDetailMaintenanceAvailable: `Available maintenance budget`,
  BicycleDetailMaintenanceTotal: `Total maintenance budget`,
  BicycleDetailMaintenanceSpend: `Maintenance budget spent`,
  BicycleDetailMaintenanceHistory: `History`,
  BicycleDetailMaintenanceUploadHelperText: `the invoice for the maintenance or`,
  BicycleDetailInsurancesTitle: `Insurances`,
  BicycleDetailInsurancesButtonText: `Report New Insurance Case`,
  BicycleDetailLeaseDetailsTitle: `Lease details`,
  BicycleDetailLeaseDetailsSoNum: `Sales order number`,
  BicycleDetailLeaseDetailsStatus: `Status`,
  BicycleDetailLeaseDetailsStartOfContract: `Start of contract`,
  BicycleDetailLeaseDetailsEndOfContract: `End of contract`,
  BicycleDetailLeaseDetailsExpectedDeliveryDate: `Expected delivery date`,
  BicycleDetailLeaseDetailsPickupDate: `Pick-up date`,
  BicycleDetailLeaseDetailsLeaseCost: `Lease cost`,
  BicycleDetailBicycleInformationTitle: `Bicycle information`,
  BicycleDetailBicycleInformationBrand: `Brand`,
  BicycleDetailBicycleInformationModel: `Model`,
  BicycleDetailBicycleInformationFrameType: `Frame type`,
  BicycleDetailBicycleInformationFrameNumber: `Frame number`,
  BicycleDetailBicycleInformationType: `Type`,
  BicycleDetailBicycleInformationColor: `Color`,
  BicycleDetailBicycleInformationNumberPlate: `License plate`,
  BicycleDetailBicycleInformationKeyNumber: `Key number`,
  BicycleDetailInvolvedPartiesTitle: `Involved parties`,
  BicycleDetailInvolvedPartiesCustomer: `Customer`,
  BicycleDetailInvolvedPartiesCyclist: `Cyclist`,
  BicycleDetailInvolvedPartiesCyclistEmail: `Cyclist email`,
  BicycleDetailInvolvedPartiesDealer: `Dealer`,
  BicycleDetailAccessoriesListTitle: `Accessories list`,
  BicycleDetailAccessoriesListPerPiece: `per piece`,
  BicycleDetailExtrasTitle: `Extra's`,
  BicycleDetailExtrasExtraBudget: `Budget for extra maintenance`,
  BicycleDetailExtrasAdditionalServices: `Additional services that are included in your leaseplan`,
  BicycleDetailExtrasAdditionalServicesInsurance: `Insurance`,
  BicycleDetailExtrasAdditionalServicesAssistance: `Assistance`,
  BicycleDetailExtrasAdditionalServicesMaintenance: `Maintenance`,
  BicycleDetailAttachmentsTitle: `Attachments`,
  BicycleDetailCtaQuotationApproved: `Your quotation was approved!`,
  BicycleDetailCtaQuotationApprovedSubTitle: `Confirm your order by entering an estimated delivery date and click the ‘Confirm order’ button`,
  BicycleDetailEstimatedDeliveryDateLabel: `Estimated delivery date`,
  BicycleDetailConfirmOrderButton: `Confirm order`,
  BicycleDetailCtaKeepYourCustomerUpToDate: `Keep your customer up-to-date`,
  BicycleDetailKeepYourCustomerUpToDate: `You can change the estimated delivery date in case of a delay or you can let them know that their bicycle isready for pick-up.`,
  BicycleDetailCtaReadyForPickupLabel: `Ready for pickup`,
  BicycleDetailCtaUpdateStatusButton: `Update status`,
  BicycleDetailCtaFrameNumberLabel: `Frame number:`,
  BicycleDetailCtaKeyNumberLabel: `Key number:`,
  BicycleDetailCtaLicensePlateLabel: `License plate:`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleTitle: `Almost ready to deliver the bicycle.`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart1: `Fill in all the fields below before delivering the bicycle to its owner.`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart2: `You can download the`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart3: `Delivery Document`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart4: `here.`,
  BicycleDetailCtaFrameNumberLabel2: `Frame number:`,
  BicycleDetailCtaKeyNumberLabel2: `Key number:`,
  BicycleDetailCtaLicensePlateLabel2: `License plate:`,
  BicycleDetailCtaDeliverBicycleButton: `Deliver Bicycle`,
  BicycleDetailCtaDeliverBicycleTitle: `You can now deliver the bicycle!`,
  BicycleDetailCtaDeliverBicycleSubTitlePart1: `Just don’t forget to upload the signed document and your first invoice in the fields below`,
  BicycleDetailCtaDeliverBicycleSubTitlePart2: `You can download the`,
  BicycleDetailCtaDeliverBicycleSubTitlePart3: `Delivery Document`,
  BicycleDetailCtaDeliverBicycleSubTitlePart4: `here.`,
  BicycleDetailCtaAddDocument: `Add document`,
  BicycleDetailCtaAddInvoice: `Add Invoice`,
  BicycleDetailCtaSignedDeliveryDocument: `Signed delivery document`,
  BicycleDetailCtaInvoiceDocument: `Invoice Document`,
  BicycleDetailCtaFinishButton: `Finish`,
  BicycleDetailCtaDeliverySuccessTitle: `You’ve completed your order!`,
  BicycleDetailCtaDeliverySuccessSubTitle1: `Thanks for filling in all the information, you have now completed all the steps.`,
  BicycleDetailCtaDeliverySuccessSubTitle2: `We’ll now close this orderprocess for you.`,
  ErrorDownloadingUnsignedDeliveryDocument: `The delivery document could not be downloaded.`,
  BicycleDetailStatusIsSyncingPlaceholderTitle: `Bicycle update in progress`,
  BicycleDetailStatusIsSyncingPlaceholderSubtitle1: `We're currently examining your update internally at Cyclis, and will amend this to your bicycle dossier as soon as possible.`,
  BicycleDetailStatusIsSyncingPlaceholderSubtitle2: `Please come back at a later time to view your updates.`,
  BicycleDetailStatusIsSyncingPlaceholderBackToOverviewButton: `Back to overview`,
  BicycleDetailDoNotDeliverBeforeDateDisclaimer: `<0>Disclaimer:</0> you can not deliver this bicycle before <1>{{date}}</1>`,
  BicycleDetailAddMaintenanceModalTitle: `Ready to upload your maintenance cost`,
  BicycleDetailAddMaintenanceModalText: `You’re maintenance cost is ready to be send, if you’re sure that you’ve uploaded the right document you can click on the ‘upload’ button below.`,
  BicycleDetailAddMaintenanceModalCancelButton: `Cancel`,
  BicycleDetailAddMaintenanceModalUploadButton: `Upload`,
  BicycleDetailUserActionLogTitle: `My Last Activities`,
  UserActionLogItemTitleMaintenanceInvoiceUploaded: `Dealer Maintenance invoice uploaded`,
  UserActionLogItemTitleDamageCaseCreated: `Dealer Damage Insurance case created`,
  SearchAtleast3Characters: `Type at least 3 characters`,
  BicycleStatusAll: `All`,
  BicycleStatusCyclisToOrder: `To order (Cyclis)`,
  BicycleStatusCyclisOrdered: `To Order (Dealer)`,
  BicycleStatusDealerOrdered: `Ordered`,
  BicycleStatusDeliveryDateGiven: `Ordered (Delivery date known)`,
  BicycleStatusReadyForPickup: `Ready for pick-up`,
  BicycleStatusPickupInfoAvailable: `Ready for pick-up`,
  BicycleStatusDeliveryCompleted: `Delivered`,
  BicycleStatusEndLease: `End lease`,
  BicycleStatusCanceled: `Canceled`,
  BicycleOverviewCustomPlaceholderHeader: `No bicycles available`,
  BicycleOverviewCustomPlaceholderSubTitle: `It may be the case that there are no bicycles in this specific status. You can change the status by filtering to ‘All’.`,
  QuotationOverviewCustomPlaceholderHeader: `No quotations available`,
  QuotationOverviewCustomPlaceholderSubTitle: `It may be the case that there are no quotations available for this specific search criteria.`,
  AutocompleteNoOptionsWithFilterText: `No options`,
  AutocompleteNoOptionsWithoutFilterText3Chars: `Type 3 or more characters to start searching`,
  AutocompleteNoOptionsWithoutFilterTextNoChars: `Type to start searching`,
  DamageCreationPageStepBicycleInfo: `Bicycle Info`,
  DamageCreationPageStepDescription: `Description`,
  DamageCreationPageStepCost: `Cost`,
  DamageCreationBicycleInfoFormCyclistName: `Cyclist name`,
  DamageCreationBicycleInfoFormSalesOrderNumber: `SO-number`,
  DamageCreationBicycleInfoFormBicycleInfo: `Bicycle`,
  DamageCreationDescriptionFormPictures: `Upload Picture(s)`,
  DamageCreationDescriptionFormPreview: `Preview:`,
  DamageCreationDescriptionFormDescription: `Description`,
  DamageCreationDescriptionFormDescriptionPlaceholder: `Describe the insurance case`,
  DamageCreationCostFormTitle: `Upload document`,
  DamageCreationCostFormSubTitle: `Please upload your quotation document (1) that will cover the repair for this insurance case.`,
  DamageCreationPlaceholderTitle: `We’ve received your insurance case.`,
  DamageCreationPlaceholderSubTitle: `Sit back and relax, we’ll send you an e-mail as soon as your quotation has been approved.`,
  DamageCreationPlaceholderButton: `Back to Bicycle`,
  DamageCreationBackButton: `Back`,
  DamageCreationSubmitButton: `Finish`,
  DamageCreationNextButton: `Next`,
  DamageCreationBicycleDetailError: `An unexpected error happened retrieving bicycle information, please try again.`,
  DealerOrderFlowSyncInProgress: `Status update in progress.`,
  DealerOrderFlowPickupInfoTogetherError: `Pick-up information fields must be filled in together.`,
  NoDeliveryDateGiven: `No delivery date selected`,
  NoActualDeliveryDateGiven: `No actual delivery date selected`,
  DealerOrderedFlowBicycleMustHaveADeliveryDate: `Bicycle must be in status 'Expected Delivery Date Available' first`,
  OpenTaskOverviewHeaderTitle: `Orders`,
  OpenTaskOverviewHeaderSubTitle: `The list below consist out of all the bicycles of your customers with open tasks.`,
  OpenTaskOverviewSearchInputPlaceholder: `Search...`,
  OpenTaskOverviewTableHeaderSoNum: `SO-number`,
  OpenTaskOverviewTableHeaderName: `Name`,
  OpenTaskOverviewTableHeaderBicycle: `Bicycle`,
  OpenTaskOverviewTableHeaderCreateDate: `Order create date`,
  OpenTaskOverviewTableHeaderExpectedDeliveryDate: `Expected delivery`,
  OpenTaskOverviewCustomPlaceholderHeader: `No open tasks available.`,
  OpenTaskOverviewCustomPlaceholderSubTitle: `There are no open tasks for you currently. Come back later.`,
  HomeCyclistSearchSeeAllResults: `See all results`,
  BicycleDetailMaintenanceUploadSuccessTitle: `Thanks for uploading your maintenance cost.`,
  BicycleDetailMaintenanceUploadSuccessSubTitle: `We’ll take care of it and will update this cost on the selected bicycle.`,
  BicycleDetailPickupDateLabel: `Bicycle was picked up on:`,
  BicycleDetailVelopassIdLabel: `Velopass ID:`,
  NoPickupDateGiven: `No pickup date selected`,
  InsurancaCaseHelperText: `your pictures or`,
  StandardError1: `Provided e-mail is not a valid e-mail`,
  StandardError2: `Provided password does match the password rules`,
  StandardError3: `Your session has expired`,
  StandardError4: `You are not authorized to perform this action`,
  StandardError5: `Provided session token is not valid`,
  StandardError6: `Provided first name is too long`,
  StandardError7: `Provided last name is too long`,
  StandardError8: `Provided sign up code is not valid`,
  StandardError9: `Provided one time token is not valid`,
  StandardError10: `Provided brand ID is not valid`,
  StandardError11: `Provided bicycle type ID is not valid`,
  StandardError12: `Provided retail price is not valid`,
  StandardError13: `Provided accessories price is not valid`,
  StandardError14: `Provided insurance flag is not valid`,
  StandardError15: `Provided maintenance flag is not valid`,
  StandardError16: `Provided assistance flag is not valid`,
  StandardError17: `Provided extra maintenance is not valid`,
  StandardError18: `Field must be a string`,
  StandardError19: `Field must not be empty`,
  StandardError20: `Provided token is too long and exceeds 512 characters`,
  StandardError21: `Provided email is too long and exceeds 100 characters`,
  StandardError22: `Not a valid login combination`,
  StandardError23: `Not a valid issued token for SSO`,
  StandardError24: `Provided language is not valid`,
  StandardError25: `One or more of the provided product data types is not valid`,
  StandardError26: `Field must be a number`,
  StandardError27: `Cyclist not found`,
  StandardError28: `Field must be a boolean`,
  StandardError29: `Provided color ID is not valid`,
  StandardError30: `Provided frame type ID is not valid`,
  StandardError31: `Provided frame Size ID is not valid`,
  StandardError32: `Provided customer id is not valid`,
  StandardError33: `No document found for provided identifier`,
  StandardError34: `Document checksum did not match. Document might be corrupted after upload`,
  StandardError35: `Exactly one file should be provided when creating a quotation`,
  StandardError36: `Provided dealer ID is not valid`,
  StandardError37: `Cyclist information should be provided`,
  StandardError38: `Cyclist with given email already exists`,
  StandardError39: `Provided quotation ID is not valid`,
  StandardError40: `Provided document ID is not valid`,
  StandardError41: `Filtering requires at least 3 characters`,
  StandardError42: `Provided portaltype is not valid`,
  StandardError43: `Invalid permissions`,
  StandardError44: `Provided bicycle does not exist`,
  StandardError45: `Quotation has the wrong status`,
  StandardError46: `Given status does not exists`,
  StandardError47: `Provided bicycle ID is not valid`,
  StandardError48: `Bicycle has the wrong status`,
  StandardError49: `Provided bicycle is not a speed pedelec`,
  StandardError50: `Provided phone number is too long and exceeds the 20 characters`,
  StandardError51: `Provided language is too long and exceeds the 10 characters`,
  StandardError52: `Provided model is too long and exceeds the 250 characters`,
  StandardError53: `Provided reference is too long and exceeds the 50 characters`,
  StandardError54: `Provided field can not be null`,
  StandardError55: `Provided accessories description is too long`,
  StandardError56: `Invalid document Type`,
  StandardError57: `At least one image is required to create an insurance case`,
  StandardError58: `One or more of the provided filter statuses is not valid`,
  StandardError72: `Missing link to partner on bicycle for authenticated user`,
  StandardError73: `The cyclist for the provided email address is not managed by the current user`,
  StandardError77: `Field must be a positive number`,
  StandardError78: `Field may not be a negative number`,
  StandardError82: `Missing link to partner on quotation for authenticated user`,
  StandardError88: `The bicycle's provided delivery date is earlier than the earliest possible delivery date`,
  StandardError92: `Invalid date`,
  StandardError100: `A speed e-bike must have insurance`,
  StandardError101: `The type of file you uploaded is not supported on the Cyclis platform.`,
  FileInputInvalidFileType: `Invalid file type`,
  FileInputFileTooLarge: `This file is larger than {{maxFileSize}}`,
  FormValidationErrorMessageRequired: `This field is required`,
  FormValidationErrorMessageEmail: `This is not a valid email address`,
  FormValidationErrorMessagePhone: `This is not a valid phone number`,
  FormValidationErrorMessageDateTypeError: `This value is not a valid date`,
  FormValidationErrorMessageFileTypeUnsupported: `This file type is not supported`,
  FormValidationErrorMessageStringMin: `This value is too short`,
  FormValidationErrorMessageStringMax: `This value is too long`,
  FormValidationErrorMessageNumberTypeError: `This value must be a number`,
  FormValidationErrorMessageNumberNoNegative: `No negative value allowed`,
  FormValidationErrorMessageNumberMin: `This value is too small`,
  FormValidationErrorMessageNumberMax: `This value is too large`,
  FormValidationErrorMessageArrayMin: `The number of provided items is too small`,
  FormValidationErrorMessageBooleanCheck: `You must check the checkbox`,
  FormValidationErrorMessagePatternMatch: `This value does not follow to the required pattern`,
  FormValidationErrorMessageCurrentPasswordEqual: `Your new password cannot be the same as your current password`,
  FormValidationErrorMessageConfirmPasswordNotEqual: `Your new password does not match this value`,
  ContactTitle: `We're glad to help you.`,
  ContactSubtitle: `What's your question about?`,
  ContactTabTitleSales: `Sales related`,
  ContactTabTitleAfterCare: `After care`,
  ContactTabTitleTechnical: `Technical issue`,
  ContactTabTitleBilling: `Billing`,
  ContactTabTitleOther: `Other`,
  ContactTabPanelAvailability: `Availability`,
  ContactTabPanelFAQ: `FAQ`,
  ContactTabPanelNameSales: `Cyclis Team`,
  ContactTabPanelDivisionSalesBE: `Cyclis België`,
  ContactTabPanelDivisionSalesNL: `Cyclis Nederland`,
  ContactTabPanelEmailSalesBE: `dealer@cyclis.be`,
  ContactTabPanelEmailSalesNL: `info@cyclis.nl`,
  ContactTabPanelPhoneSalesBE: `+32 (0)11 49 56 08`,
  ContactTabPanelPhoneSalesNL: `+31 (0)85 130 6930`,
  ContactTabPanelAvailabilitySales: `You can reach our teams during the week from 08:00 ‘till 17:00. We’re closed in the weekends and on holidays.`,
  ContactTabPanelFAQSales: `Don’t feel like waiting? You can always search for your answer on our`,
  ContactTilesFindUs: `You can also find us...`,
  ContactTileOfficeTitle: `in Hasselt`,
  ContactTileOfficeBody: `Come by and see where we work.`,
  ContactTileFacebookTitle: `on Facebook`,
  ContactTileFacebookBody: `Like us and follow our stories!`,
  ContactTileInstagramTitle: `on Instagram`,
  ContactTileInstagramBody: `Follow us and get to know the team!`,
  PageNotFoundTitle: `Oops, something went wrong.`,
  PageNotFoundSubtitle: `Please check and try again or go back to our homepage.`,
  PageNotFoundGoBackButton: `Go back`,
  BadRequestTitle: `Oops, something went wrong.`,
  BadRequestGoBackButton: `Back to Home Page`,
  MaintenancePageTitle: `Under Maintenance`,
  MaintenancePageDescription: `We are currently maintaining our portals which means they are temporarily unavailable. You will be able to access them again tomorrow. Our apologies for the inconvenience.`,
  PlannedMaintenanceBannerTitle: `Important Notice`,
  PlannedMaintenanceBannerText: `Due to our teambuilding on Friday 28th of June, phone calls will be transferred to our contact center that day. We will answer your e-mails as soon as possible. Thank you for your understanding.`,
  PlannedMaintenanceBannerSubscript: `The Cyclis Team`,
}
