import {consumeJsonWithAuth} from '@api/api-client'
import {Dealer} from '@utils/types'

const DEALERS_ENDPOINT = 'dealers'

/**
 * Get all dealers.
 *
 * @return {Promise<Dealer[]>} dealers - All the dealers in the system
 */
export const getDealersAPI = async (
	companyName?: string
): Promise<Dealer[]> => {
	const response = await consumeJsonWithAuth<{items: Dealer[]}>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${DEALERS_ENDPOINT}${
			companyName ? `?companyName=${encodeURIComponent(companyName)}` : ''
		}`,
		{
			method: 'GET',
		}
	)

	const {items} = response

	return items
}

/**
 * Get all dealers, managed by the current user.
 *
 * @return {Promise<Dealer[]>} dealers - All dealers, managed by the current user
 */
export const getManagedDealersAPI = async (
	companyName?: string
): Promise<Dealer[]> => {
	const response = await consumeJsonWithAuth<{items: Dealer[]}>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${DEALERS_ENDPOINT}/own/managed${
			companyName ? `?companyName=${encodeURIComponent(companyName)}` : ''
		}`,
		{method: 'GET'}
	)

	const {items} = response

	return items
}

/**
 * Get homepage statistics for dealer.
 *
 * @return {Promise<{
	orderToBeConfirmed: number
	readyToDeliver: number
}>} dealerStatisticsResponse - All the date to fill the statistics on the homepage
 */
interface DealerStatisticsAPIResponse {
	orderToBeConfirmed: number
	readyToDeliver: number
}

export const getDealerStatisticsAPI =
	async (): Promise<DealerStatisticsAPIResponse> => {
		const response = consumeJsonWithAuth<DealerStatisticsAPIResponse>(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${DEALERS_ENDPOINT}/homepage`,
			{
				method: 'GET',
			}
		)
		return response
	}
