import {CyclisDocumentType} from '@utils/types/index'

export default interface QuotationInterface {
	id?: string
	salesOrderNumber?: string
	quotationId?: string
	customerId: number
	customer?: string
	cyclistId?: number
	cyclist?: string
	cyclistFirstName?: string
	cyclistLastName?: string
	cyclistEmail?: string
	email?: string
	phone?: string
	preferredLanguage?: string
	dealerId: number
	dealer?: string
	brandId: string
	brand?: string
	specification?: string
	model?: string
	frameTypeId: string
	frameType?: string
	frameSizeId: string
	frameSize?: number
	colorId: string
	color?: string
	typeId: string
	type?: string
	reference: string
	retailPrice: number | string
	status?: string
	insurance: boolean
	maintenance: boolean
	assistance: boolean
	extraMaintenance: number
	leaseCost?: number
	totalAccessoryPrice?: number
	administrationCost?: number
	createDate?: string
	accessories: {description: string; quantity: number; pricePerPiece: number}[]
	administrationCosts: {description: string; cost: number}[]
	quotationDocument?: {identifier: string; checksum: string}
	attachments?: {documentId: string; documentType?: CyclisDocumentType}[]
}
export const defaultQuotationValue: QuotationInterface = {
	quotationId: '',
	cyclistId: 0,
	customerId: 0,
	customer: '',
	dealerId: 0,
	dealer: '',
	brandId: '',
	brand: '',
	model: '',
	frameTypeId: '',
	frameType: '',
	frameSizeId: '',
	frameSize: 0,
	colorId: '',
	color: '',
	typeId: '',
	type: '',
	reference: '',
	retailPrice: 0,
	leaseCost: 0,
	status: '',
	insurance: true,
	maintenance: true,
	assistance: true,
	extraMaintenance: 0,
	totalAccessoryPrice: 0,
	administrationCost: 0,
	accessories: [],
	administrationCosts: [],
}
