export enum AccessRightsEnum {
	CREATE_SIMULATION = 'CREATE_SIMULATION',
	CREATE_QUOTATION = 'CREATE_QUOTATION',
	CONFIRM_QUOTATION = 'CONFIRM_QUOTATION',
}

export default interface AccessRightsInterface {
	CREATE_SIMULATION: boolean
	CREATE_QUOTATION: boolean
	CONFIRM_QUOTATION: boolean
}
