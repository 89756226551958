import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import decodeJwt from 'jwt-decode'

import {getIdentityTokenAPI, refreshAuthAPI} from '@api/auth-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {
	IDENTITY_TOKEN_STORAGE_KEY,
	REFRESH_TOKEN_STORAGE_KEY,
	USER_UID,
} from '@utils/constants/auth-constants'
import {AccessRights, IdentityToken, JsonWebToken} from '@utils/types'

interface AuthState {
	bearerToken: string | null
	isAuthenticated: boolean
	validationFinished: boolean
	loading: boolean
	success: boolean
	firstName: string
	lastName: string
	language: string
	uid: number
	roles: string[]
	dealerId: number | null
	accessRights: AccessRights
	error: string | null
}

interface ValidateSuccess {
	bearerToken: string
}

interface UserInfoPayload {
	roles: string[]
	dealerId: number | null
	uid: number
	accessRights: AccessRights
}

type IdentityTokenPayload = {
	firstName: string
	lastName: string
	language: string
}

const initialState: AuthState = {
	bearerToken: null,
	isAuthenticated: false,
	validationFinished: false,
	loading: false,
	success: false,
	firstName: '',
	lastName: '',
	language: '',
	roles: [],
	dealerId: null,
	uid: 0,
	accessRights: {
		CREATE_SIMULATION: false,
		CREATE_QUOTATION: false,
		CONFIRM_QUOTATION: false,
	},
	error: null,
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		validateAuthStart(state): void {
			state.loading = true
			state.validationFinished = false
			state.error = null
		},
		validateAuthSuccess(state, action: PayloadAction<ValidateSuccess>): void {
			state.bearerToken = action.payload.bearerToken
			state.isAuthenticated = true
			state.validationFinished = true
			state.loading = false
			state.success = true
			state.error = null
		},
		validateAuthFailure(state, action: PayloadAction<string>): void {
			state.validationFinished = true
			state.isAuthenticated = false
			state.loading = false
			state.error = action.payload
		},
		getUserInfoSuccess(state, action: PayloadAction<UserInfoPayload>): void {
			state.roles = action.payload.roles
			state.dealerId = action.payload.dealerId
			state.uid = action.payload.uid
			state.accessRights = action.payload.accessRights
		},
		getIdentityTokenSuccess(
			state,
			action: PayloadAction<IdentityTokenPayload>
		): void {
			state.firstName = action.payload.firstName
			state.lastName = action.payload.lastName
			state.language = action.payload.language
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): AuthState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	validateAuthStart,
	validateAuthSuccess,
	validateAuthFailure,
	getUserInfoSuccess,
	getIdentityTokenSuccess,
} = authSlice.actions
export default authSlice.reducer

const refreshFlow = async (): Promise<{
	refreshToken: string
	bearerToken: string
}> => {
	const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)

	if (refreshToken === null) {
		throw new Error('')
	}

	const {refreshToken: newRefreshToken, bearerToken} = await refreshAuthAPI(
		refreshToken
	)

	return {refreshToken: newRefreshToken, bearerToken}
}

const isJwtExpired = (jwt: JsonWebToken): boolean => {
	return Date.now() >= jwt.exp * 1000
}

export const validateAuth =
	(): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		dispatch(validateAuthStart())
		// Read bearerToken from memory and decode to JsonWebToken
		let {bearerToken} = getState().auth
		let decodedToken: JsonWebToken | null

		try {
			decodedToken = decodeJwt(bearerToken!) as JsonWebToken
		} catch {
			decodedToken = null
		}

		// Refresh token if decodedToken is invalid
		if (decodedToken === null || isJwtExpired(decodedToken)) {
			try {
				// Try to refresh tokens
				const {refreshToken, bearerToken: newBearerToken} = await refreshFlow()

				// Store new refreshToken in local storage
				localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken)

				// Update and decode bearerToken
				bearerToken = newBearerToken
				decodedToken = decodeJwt(newBearerToken) as JsonWebToken
			} catch (refreshError) {
				// Refreshing rokens failed
				const jwtError =
					decodedToken === null
						? 'Invalid JWT'
						: isJwtExpired(decodedToken)
						? 'Expired JWT'
						: null
				dispatch(validateAuthFailure([jwtError, refreshError].join('-')))
			}
		}

		if (decodedToken === null) {
			dispatch(validateAuthFailure('Invalid JWT'))
		} else if (isJwtExpired(decodedToken)) {
			dispatch(validateAuthFailure('Expired JWT'))
		} else {
			const {roles, dealerId, uid, accessRights} = decodedToken
			localStorage.setItem(USER_UID, uid.toString())

			dispatch(
				validateAuthSuccess({
					bearerToken: bearerToken!,
				})
			)
			dispatch(
				getUserInfoSuccess({
					uid,
					roles,
					dealerId,
					accessRights,
				})
			)

			let identityToken = localStorage.getItem(IDENTITY_TOKEN_STORAGE_KEY)
			if (!identityToken) {
				const identityTokenResponse = await getIdentityTokenAPI()
				identityToken = identityTokenResponse.identityToken
				localStorage.setItem(IDENTITY_TOKEN_STORAGE_KEY, identityToken)
			}

			const decodedIdentityToken = decodeJwt(identityToken) as IdentityToken
			const {firstName, lastName, language} = decodedIdentityToken

			dispatch(getIdentityTokenSuccess({firstName, lastName, language}))
		}
	}
