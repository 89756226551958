import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import decodeJwt from 'jwt-decode'

import {loginWithUsernameAPI} from '@api/auth-api'
import {
	getIdentityTokenSuccess,
	validateAuthSuccess,
} from '@redux/slices/auth-slice'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {
	IDENTITY_TOKEN_STORAGE_KEY,
	REFRESH_TOKEN_STORAGE_KEY,
} from '@utils/constants/auth-constants'
import {getTranslationFromError} from '@utils/errors'
import {IdentityToken, JsonWebToken} from '@utils/types'

const SESSION_EXPIRED_COPY_KEY = 'StandardError3'

interface LoginState {
	username: string
	consentGiven: boolean
	loading: boolean
	success: boolean
	error: string | null
}

interface LoginSuccess {
	username: string
	token: string
	consentGiven: boolean
}

const initialState: LoginState = {
	username: null as any,
	consentGiven: false,
	loading: false,
	success: false,
	error: null,
}

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		loginStart(state): void {
			state.loading = true
			state.error = null
		},
		loginSuccess(state, action: PayloadAction<LoginSuccess>): void {
			const {username, consentGiven} = action.payload

			state.username = username
			state.consentGiven = consentGiven
			state.loading = false
			state.success = true
			state.error = null
		},
		loginFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},

		loginExpired(state): void {
			state.error = SESSION_EXPIRED_COPY_KEY
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (state): LoginState => {
			return {
				...initialState,

				error:
					state.error === SESSION_EXPIRED_COPY_KEY
						? state.error
						: initialState.error,
			}
		},
	},
})

export const {loginStart, loginSuccess, loginFailure, loginExpired} =
	loginSlice.actions
export default loginSlice.reducer

const hasInvalidRole = (token: JsonWebToken): boolean => {
	return (
		!token.roles ||
		(token.roles.includes('CYCLIST') && token.roles.length === 1)
	)
}

export const login =
	(username: string, password: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(loginStart())
			const {token, refreshToken, identityToken, consentGiven} =
				await loginWithUsernameAPI(username, password)
			const decodedToken: JsonWebToken = decodeJwt(token) as JsonWebToken

			if (decodedToken === undefined) {
				dispatch(loginFailure('Undefined JWT'))
			} else if (hasInvalidRole(decodedToken)) {
				dispatch(loginFailure('LoginInvalidRole'))
			} else {
				// Store new refreshToken in local storage
				localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken)
				// Store new identityToken in local storage
				localStorage.setItem(IDENTITY_TOKEN_STORAGE_KEY, identityToken)

				const {firstName, lastName, language} = decodeJwt(
					identityToken
				) as IdentityToken

				// Store new bearerToken and identity token values in memory
				dispatch(
					validateAuthSuccess({
						bearerToken: token,
					})
				)
				dispatch(getIdentityTokenSuccess({firstName, lastName, language}))
				dispatch(loginSuccess({username, token, consentGiven}))
			}
		} catch (err) {
			dispatch(loginFailure(getTranslationFromError(err as Error)))
		}
	}

export const onLoginExpired =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(loginExpired())
	}
