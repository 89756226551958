import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {resetPasswordAPI} from '@api/auth-api'
import {AppThunk} from '@redux/store'
import {getTranslationFromError} from '@utils/errors'

interface SetPasswordState {
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: SetPasswordState = {
	loading: false,
	success: false,
	error: null,
}

const resetPasswordSlice = createSlice({
	name: 'resetPassword',
	initialState,
	reducers: {
		resetPasswordStart(state): void {
			state.loading = true
			state.error = null
		},
		resetPasswordSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		resetPasswordFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
	},
})

export const {resetPasswordStart, resetPasswordSuccess, resetPasswordFailure} =
	resetPasswordSlice.actions
export default resetPasswordSlice.reducer

export const resetPassword =
	(newPassword: string, token: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(resetPasswordStart())

			const success = await resetPasswordAPI(newPassword, token)

			if (success) {
				dispatch(resetPasswordSuccess())
			} else {
				dispatch(resetPasswordFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(resetPasswordFailure(getTranslationFromError(err as Error)))
		}
	}
