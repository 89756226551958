import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import decodeJwt from 'jwt-decode'

import {setPasswordAPI} from '@api/auth-api'
import {AppThunk} from '@redux/store'
import {getTranslationFromError} from '@utils/errors'
import {JsonWebToken} from '@utils/types'

interface SetPasswordState {
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: SetPasswordState = {
	loading: false,
	success: false,
	error: null,
}

const setPasswordSlice = createSlice({
	name: 'setPassword',
	initialState,
	reducers: {
		setPasswordStart(state): void {
			state.loading = true
			state.error = null
		},
		setPasswordSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		setPasswordFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		setPasswordInital(state): void {
			state.loading = initialState.loading
			state.success = initialState.success
			state.error = initialState.error
		},
	},
})

export const {
	setPasswordStart,
	setPasswordSuccess,
	setPasswordFailure,
	setPasswordInital,
} = setPasswordSlice.actions
export default setPasswordSlice.reducer

export const setPassword =
	(oldPassword: string, newPassword: string): AppThunk =>
	async (dispatch, getState): Promise<void> => {
		try {
			dispatch(setPasswordStart())

			const {bearerToken} = getState().auth
			const decodedToken: JsonWebToken = decodeJwt(bearerToken!) as JsonWebToken

			const success = await setPasswordAPI(
				oldPassword,
				newPassword,
				decodedToken.uid
			)

			if (success) {
				dispatch(setPasswordSuccess())
			} else {
				dispatch(setPasswordFailure('UnknownError'))
			}
		} catch (err) {
			dispatch(setPasswordFailure(getTranslationFromError(err as Error)))
		}
	}
