import {consume} from '@api/api-client'

/**
 * Upload file
 *
 * @param uploadUrl
 * @param file
 * @param contentType
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const uploadFileAPI = async (
	uploadUrl: string,
	file: File,
	contentType: string
): Promise<boolean> => {
	try {
		const response = await consume(uploadUrl, {
			method: 'PUT',
			headers: {
				'x-ms-blob-type': 'BlockBlob',
				'Accept-Charset': 'UTF-8',
				'x-ms-version': '2017-11-09',
				'x-ms-blob-content-type': contentType,
			},
			body: file,
		})
		return response.status === 201
	} catch (error) {
		throw error
	}
}
