import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'

interface ErrorState {
	error: string | null
	loading: boolean
	success: boolean
	errorMessage: string | null
}

const initialState: ErrorState = {
	error: null,
	loading: false,
	success: false,
	errorMessage: null,
}

const errorSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {
		setErrorStart(state): void {
			state.loading = true
			state.errorMessage = null
		},
		setErrorSuccess(state, action: PayloadAction<string>): void {
			state.error = action.payload
			state.loading = false
			state.success = true
			state.errorMessage = null
		},
		resetErrorSuccess(state): void {
			state.error = null
			state.loading = false
			state.success = true
			state.errorMessage = null
		},
		setErrorFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.errorMessage = action.payload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): ErrorState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	setErrorStart,
	setErrorSuccess,
	resetErrorSuccess,
	setErrorFailure,
} = errorSlice.actions

export default errorSlice.reducer

export const setError =
	(error: Error): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(setErrorStart())
			dispatch(setErrorSuccess(error.message))
		} catch (err) {
			dispatch(setErrorFailure((err as Error).message))
		}
	}

export const resetError =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(setErrorStart())
			dispatch(resetErrorSuccess())
		} catch (err) {
			dispatch(setErrorFailure((err as Error).message))
		}
	}
