import {consumeJsonWithAuth, consumeWithAuth} from '@api/api-client'
import {Damage} from '@templates/DamageCreation/create-damage-slice'

const BICYCLES_ENDPOINT = 'bicycles'
const DAMAGE_ENDPOINT = 'damage-cases'

export const getUploadUrlAPI = async <T>(
	bicycleId: string,
	endpoint: string,
	originalFileName?: string
): Promise<T> => {
	return consumeJsonWithAuth(
		`${
			process.env.GATSBY_MIDDLEWARE_BASE_URL
		}/${BICYCLES_ENDPOINT}/${bicycleId}/${endpoint}${
			originalFileName ? `?originalFileName=${originalFileName}` : ''
		}`,
		{
			method: 'GET',
		}
	)
}

export const createDamageAPI = async (
	bicycleId: string,
	damage: Damage
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${BICYCLES_ENDPOINT}/${bicycleId}/${DAMAGE_ENDPOINT}`,
			{
				method: 'POST',
				body: JSON.stringify(damage),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}
