import {consumeJson, consumeJsonWithAuth} from '@api/api-client'

const TERMS_ENDPOINT = 'terms'
const CONSENT_ENDPOINT = 'consent-documents'
const QUOTATIONS_ENDPOINT = 'quotations'
const ATTACHMENTS_ENDPOINT = 'attachments'
const BICYCLES_ENDPOINT = 'bicycles'
const ORDER_DOCUMENTS_ENDPOINT = 'order-documents'

interface DocumentAPIResponse {
	downloadUrl: string
}

/**
 * Get terms downloadUrl.
 *
 * @return {Promise<string>} downloadUrl - The url to download the terms
 */
export const getTermsAPI = async (): Promise<string> => {
	const response = await consumeJson(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${TERMS_ENDPOINT}`,
		{
			method: 'GET',
		}
	)

	const {downloadUrl} = response

	return downloadUrl
}

/**
 * Get consent downloadUrl.
 *
 * @return {Promise<string>} downloadUrl - The url to download the concent
 */
export const getConsentAPI = async (): Promise<string> => {
	const response = await consumeJsonWithAuth<DocumentAPIResponse>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${CONSENT_ENDPOINT}`,
		{
			method: 'GET',
		}
	)

	const {downloadUrl} = response

	return downloadUrl
}

/**
 * Get quotation document downloadUrl.
 *
 * @return {Promise<string>} downloadUrl - The url to download the quotation
 */
export const getQuotationDocumentAPI = async (
	quotationId: string,
	documentId: string
): Promise<string> => {
	const response = await consumeJsonWithAuth<DocumentAPIResponse>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${QUOTATIONS_ENDPOINT}/${quotationId}/${ATTACHMENTS_ENDPOINT}/${documentId}`,
		{
			method: 'GET',
		}
	)

	const {downloadUrl} = response

	return downloadUrl
}

/**
 * Get delivery document downloadUrl.
 *
 * @return {Promise<string>} downloadUrl - The url to download the delivery document
 */
export const getDeliveryDocumentAPI = async (
	bicycleId: number,
	documentId: string
): Promise<string> => {
	try {
		const response = await consumeJsonWithAuth<DocumentAPIResponse>(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${BICYCLES_ENDPOINT}/${bicycleId}/${ATTACHMENTS_ENDPOINT}/${documentId}`,
			{
				method: 'GET',
			}
		)

		const {downloadUrl} = response

		return downloadUrl
	} catch (error) {
		throw error
	}
}

/**
 * Get the upload urls for the delivery document and invoice document.
 *
 * @return {Promise<{
	deliveryDocumentIdentifier: string
	deliveryDocumentUploadUrl: string
	invoiceDocumentIdentifier: string
	invoiceDocumentUploadUrl: string
}>} uploadUrlsResponse - All the data needed handle the upload of the delivery/invoice documents
 */
export const getDeliveryDocumentUploadUrlsAPI = async (
	bicycleId: number
): Promise<{
	deliveryDocumentIdentifier: string
	deliveryDocumentUploadUrl: string
	invoiceDocumentIdentifier: string
	invoiceDocumentUploadUrl: string
}> => {
	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${BICYCLES_ENDPOINT}/${bicycleId}/${ORDER_DOCUMENTS_ENDPOINT}`,
		{
			method: 'GET',
		}
	)
}
