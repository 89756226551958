import {consumeJsonWithAuth} from '@api/api-client'
import {Customer} from '@utils/types'

const CUSTOMERS_ENDPOINT = 'customers'

/**
 * Get all customers.
 *
 * @return {Promise<Customer[]>} customers - All the customers in the system
 */
export const getCustomersAPI = async (
	companyName?: string
): Promise<Customer[]> => {
	const response = await consumeJsonWithAuth<{items: Customer[]}>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${CUSTOMERS_ENDPOINT}${
			companyName ? `?companyName=${encodeURIComponent(companyName)}` : ''
		}`,
		{
			method: 'GET',
		}
	)

	const {items} = response

	return items
}

/**
 * Get the detail info of a specific customer by id.
 *
 * @return {Promise<Customer>} customer - The specified customer
 */
export const getCustomerDetailAPI = async (id: number): Promise<Customer> => {
	return consumeJsonWithAuth(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${CUSTOMERS_ENDPOINT}/${id}`,
		{
			method: 'GET',
		}
	)
}
