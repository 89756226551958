import {PreviousLocation} from '@redux/slices/navigation-slice'

export const toMax15Characters = (value: string): string =>
	value && value.length > 15 ? `${value.slice(0, 14)}...` : value

export const toId = (text: string): string => {
	if (typeof text !== 'string' && typeof text !== 'number') {
		return ''
	}

	return String(text)
		.toLowerCase()
		.replace(/[^a-z0-9]+/g, '')
}

export const toPrettyPrice = (price: number): string => {
	return price === null || price === undefined
		? 'N/A'
		: `€${price.toFixed(2).toString().replace('.', ',')}`
}

export const toPrettyPriceStriped = (price: number): string => {
	if (price === null || price === undefined) {
		return 'N/A'
	}

	let tempPrice = `€${price.toFixed(2).toString().replace('.', ',')}`

	if (tempPrice.slice(tempPrice.length - 2, tempPrice.length) === '00') {
		tempPrice = `${tempPrice.substr(0, tempPrice.length - 2)}-`
	}

	return tempPrice
}

export const isEmptyString = (value: any): boolean => {
	return value === undefined || value === null || value === '' || !value
}

export const previousLocationToPath = (
	previousLocation: PreviousLocation,
	currentLanguage?: string
): string => {
	if (previousLocation.language) {
		const pathWithoutLanguage = previousLocation
			.path!.split('/')
			.splice(2)
			.join('/')

		return currentLanguage
			? `${currentLanguage}/${pathWithoutLanguage}`
			: `${previousLocation.language}/${pathWithoutLanguage}`
	}

	return previousLocation.path!
}
