import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {getCustomerDetailAPI, getCustomersAPI} from '@api/customers-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {getTranslationFromError} from '@utils/errors'
import {Customer} from '@utils/types'

interface CustomersState {
	customers: Customer[]
	customer: Customer
	loading: boolean
	success: boolean
	error: string | null
}

interface CustomersSuccess {
	customers: Customer[]
}

interface CustomerSuccess {
	customer: Customer
}

const initialState: CustomersState = {
	customers: [],
	customer: undefined as any,
	loading: false,
	success: false,
	error: null,
}

const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		getCustomersStart(state): void {
			state.loading = true
			state.error = null
		},
		getCustomersSuccess(state, action: PayloadAction<CustomersSuccess>): void {
			const {customers} = action.payload

			state.customers = customers
			state.loading = false
			state.success = true
			state.error = null
		},
		getCustomersFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
		getCustomerSuccess(state, action: PayloadAction<CustomerSuccess>): void {
			const {customer} = action.payload

			state.customer = customer
			state.loading = false
			state.success = true
			state.error = null
		},
		reset(): CustomersState {
			return {...initialState}
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): CustomersState => {
			return {
				...initialState,
			}
		},
	},
})

export const {
	getCustomersStart,
	getCustomersSuccess,
	getCustomersFailure,
	getCustomerSuccess,
	reset,
} = customersSlice.actions
export default customersSlice.reducer

export const getCustomers =
	(companyName?: string): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getCustomersStart())
			const customers = await getCustomersAPI(companyName)

			dispatch(getCustomersSuccess({customers}))
		} catch (err) {
			dispatch(getCustomersFailure(getTranslationFromError(err as Error)))
		}
	}

export const getCustomer =
	(customerId: number): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(getCustomersStart())

			const customer = await getCustomerDetailAPI(customerId)

			dispatch(getCustomerSuccess({customer}))
		} catch (err) {
			dispatch(getCustomersFailure(getTranslationFromError(err as Error)))
		}
	}

export const resetCustomers =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(reset())
	}
