/* eslint-disable camelcase */
import EN from './en_US'
import FR from './fr_BE'
import NL from './nl_BE'

export default {
	en_US: {
		translations: {
			...EN,
		},
	},
	nl_BE: {
		translations: {
			...NL,
		},
	},
	fr_BE: {
		translations: {
			...FR,
		},
	},
}
