import {setError} from '@redux/slices/error-slice'
import {handleErrorResponse} from '@utils/errors'
import {isSSR} from '@utils/gatsby-utils'

import defaultHeaders from './default-headers'

const successCodes = [200, 201, 204, 304]

const handleError = (error: Error): void => {
	try {
		const handledErrors = [401]
		const parsedErrorResponse = JSON.parse(error.message)

		if (handledErrors.includes(parsedErrorResponse.statusCode) && !isSSR()) {
			import('@redux/store').then(({default: store}) => {
				store.dispatch(setError(error))
			})
		} else {
			handleErrorResponse(parsedErrorResponse)
		}
	} catch {
		throw error
	}
}

export const consumeJson = async <T>(
	input: RequestInfo,
	init?: RequestInit
): Promise<T | any> => {
	try {
		const result = await fetch(input, {
			...init,
			headers: {...defaultHeaders, ...init!.headers},
		})

		const jsonResult: unknown = await result.json()
		handleErrorResponse(jsonResult as any)

		return jsonResult as T
	} catch (error) {
		handleError(error as any)
	}
}

export const consume = async (
	input: RequestInfo,
	init?: RequestInit
): Promise<Response | any> => {
	try {
		const result = await fetch(input, {
			...init,
			headers: {...defaultHeaders, ...init!.headers},
		})

		if (!successCodes.includes(result.status)) {
			const jsonResult: unknown = await result.json()
			handleErrorResponse(jsonResult as any)

			throw new Error((jsonResult as any).toString())
		}

		return result
	} catch (error) {
		handleError(error as any)
	}
}

export const consumeText = async (
	input: RequestInfo,
	init?: RequestInit
): Promise<any> => {
	try {
		const result = await fetch(input, {
			...init,
			headers: {...defaultHeaders, ...init!.headers},
		})

		if (successCodes.includes(result.status)) {
			return result.text()
		}

		const jsonResult = await result.json()
		handleErrorResponse(jsonResult)

		return jsonResult
	} catch (error) {
		handleError(error as any)
	}
}

export const consumeWithAuth = async (
	input: RequestInfo,
	init?: RequestInit
): Promise<Response | any> => {
	if (!isSSR()) {
		const {default: store} = await import('@redux/store')
		const {bearerToken} = (store.getState() as any).auth

		return consume(input, {
			...init,
			headers: {
				Authorization: bearerToken,
				...init!.headers,
			},
		})
	}
}

export const consumeJsonWithAuth = async <T>(
	input: RequestInfo,
	init?: RequestInit
): Promise<T | any> => {
	if (!isSSR()) {
		const {default: store} = await import('@redux/store')
		const {bearerToken} = (store.getState() as any).auth

		return consumeJson(input, {
			...init,
			headers: {
				Authorization: bearerToken,
				...init!.headers,
			},
		})
	}
}

export const consumeTextWithAuth = async (
	input: RequestInfo,
	init?: RequestInit
): Promise<any> => {
	if (!isSSR()) {
		const {default: store} = await import('@redux/store')
		const {bearerToken} = store.getState().auth

		return consumeText(input, {
			...init,
			headers: {
				Authorization: bearerToken!,
				...init!.headers,
			},
		})
	}
}
