import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useFeature} from 'flagged'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FeatureFlag} from '@utils/types'

/**
 * UnderMaintenanceWrapper
 */
interface UnderMaintenanceWrapperProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
}

const useMaintenanceStyles = makeStyles((theme) => ({
	maintenanceContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		padding: theme.spacing(8 / 8),
	},
	title: {
		fontSize: 36,
		fontWeight: 'bold',
		marginBottom: 0,
	},
	description: {
		color: '#7E7E80',
		textAlign: 'center',
		marginTop: 0,
		maxWidth: 320,
		[theme.breakpoints.up('md')]: {
			maxWidth: 400,
		},
	},
}))

const UnderMaintenanceComponent: React.FC = () => {
	const classes = useMaintenanceStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})

	return (
		<Box>
			<div id="maintenace-container" className={classes.maintenanceContainer}>
				<img
					src="/images/illustration-maintenance.svg"
					alt="under maintenance"
				/>
				<p className={classes.title}>{t('MaintenancePageTitle')}</p>
				<p className={classes.description}>{t('MaintenancePageDescription')}</p>
			</div>
		</Box>
	)
}

export const UnderMaintenanceWrapper: React.FC<
	UnderMaintenanceWrapperProps
> = ({children}) => {
	const isUnderMaintenance = useFeature(FeatureFlag.UNDER_MAINTENANCE)

	return isUnderMaintenance ? <UnderMaintenanceComponent /> : children
}

UnderMaintenanceWrapper.propTypes = {
	children: PropTypes.node,
}

export default UnderMaintenanceWrapper
