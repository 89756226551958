export const handleErrorResponse = (response: {statusCode?: number}): void => {
	if (response.statusCode && response.statusCode !== 200) {
		throw new Error(JSON.stringify(response))
	}
}

export const getTranslationFromError = (error: Error): string => {
	try {
		const errorResponse = JSON.parse(error.message)

		if (errorResponse.statusCode === 404) {
			return 'StandardErrorNotFound'
		}

		if (errorResponse.message && error.message.length) {
			return `StandardError${errorResponse.message[0].code}`
		}

		return 'UnknownError'
	} catch {
		return 'UnknownError'
	}
}
