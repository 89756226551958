import {
	consume,
	consumeJson,
	consumeJsonWithAuth,
	consumeWithAuth,
} from '@api/api-client'
import {PortalType} from '@utils/types'

const LOGIN_USERNAME_ENDPOINT = 'users/token'
const TOKEN_REFRESH_ENDPOINT = 'users/token/refresh'
const SSO_ENDPOINT = 'users/sso'
const FORGOT_PASSWORD_ENDPOINT = 'users/password/reset-email'
const RESET_PASSWORD_ENDPOINT = 'users/password'
const CONSENT_ENDPOINT = 'users/{userId}/consent'
const IDENTITY_TOKEN_ENDPOINT = 'users/own/identity-token'

interface LoginAPIResponse {
	token: string
	refreshToken: string
	consentGiven: boolean
	identityToken: string
}

interface RefreshAuthAPIResponse {
	refreshToken: string
	bearerToken: string
}

type GetIdentityTokenResponse = {
	identityToken: string
}

/**
 * Login with a username and password.
 * @param {string} username - Username of the user (email).
 * @param {string} password - Password of the user.
 *
 * @return {Promise<string>} jwt - The JSON Web Token issued for the user
 */
export const loginWithUsernameAPI = async (
	username: string,
	password: string
): Promise<LoginAPIResponse> => {
	const response = await consumeJson(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${LOGIN_USERNAME_ENDPOINT}`,
		{
			method: 'POST',
			body: JSON.stringify({
				username,
				password,
				portalType: PortalType.DEALER,
			}),
		}
	)

	const {token, refreshToken, identityToken, consentGiven} = response

	return {token, refreshToken, identityToken, consentGiven}
}

/**
 * Get a JWT through exchanging a one-time SSO token for a JWT.
 * @param {string} token - one-time token received through SSO
 *
 * @return {Promise<string>} jwt - The JSON Web Token issued for the user
 */
export const loginWithSsoAPI = async (
	oneTimeToken: string
): Promise<LoginAPIResponse> => {
	const response = await consumeJson(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${SSO_ENDPOINT}`,
		{
			method: 'POST',
			body: JSON.stringify({
				token: oneTimeToken,
				portalType: PortalType.DEALER,
			}),
		}
	)

	const {token, refreshToken, identityToken, consentGiven} = response

	return {token, refreshToken, identityToken, consentGiven}
}

/**
 * Refresh current bearerToken using refreshToken.
 * @param {string} refreshToken - current refresh token
 *
 * @return {Promise<{refreshToken: string, bearerToken: number}>} refreshToken - New refresh token, bearerToken - The JSON Web Token issued for the user
 */
export const refreshAuthAPI = async (
	refreshToken: string
): Promise<RefreshAuthAPIResponse> => {
	const response = await consumeJson(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${TOKEN_REFRESH_ENDPOINT}`,
		{
			method: 'POST',
			body: JSON.stringify({
				refreshToken,
			}),
		}
	)

	const {refreshToken: newRefreshToken, bearerToken} = response

	return {refreshToken: newRefreshToken, bearerToken}
}

/**
 * Get identity token of own user.
 *
 * @return {Promise<{identityToken: string}>} identityToken - own user's idetntity token
 */

export const getIdentityTokenAPI =
	async (): Promise<GetIdentityTokenResponse> => {
		try {
			const response = await consumeJsonWithAuth<GetIdentityTokenResponse>(
				`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${IDENTITY_TOKEN_ENDPOINT}`,
				{
					method: 'GET',
				}
			)

			return response
		} catch (error) {
			throw error
		}
	}

/**
 * Sends a reset password e-mail to the user
 * @param {string} username - The username of the user
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const forgotPasswordAPI = async (username: string): Promise<boolean> => {
	try {
		await consume(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${FORGOT_PASSWORD_ENDPOINT}`,
			{
				method: 'POST',
				body: JSON.stringify({
					email: username,
				}),
			}
		)
		return true
	} catch {
		return true
	}
}

/**
 * Sends a confirm email to set a new password for the user
 * @param {string} currentPassword - The current password of the user
 * @param {string} newPassword - The new password for the user
 * @param {number} userId - The user id of the user
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const setPasswordAPI = async (
	currentPassword: string,
	newPassword: string,
	userId: number
): Promise<boolean> => {
	try {
		const response = await consume(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/users/${userId}/password`,
			{
				method: 'PUT',
				body: JSON.stringify({
					currentPassword,
					newPassword,
				}),
			}
		)

		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Sets a new password for the user
 * @param {string} newPassword - The new password for the user
 * @param {string} token - Token received by the user via e-mail
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const resetPasswordAPI = async (
	newPassword: string,
	token: string
): Promise<boolean> => {
	try {
		const response = await consume(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${RESET_PASSWORD_ENDPOINT}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					activationToken: token,
					newPassword,
				}),
			}
		)

		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Update consent value for a user
 * @param {string} userId - The id of the user
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const updateConsentAPI = async (userId: string): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${CONSENT_ENDPOINT.replace(
				'{userId}',
				userId
			)}`,
			{
				method: 'PUT',
			}
		)

		return response.status === 204
	} catch (error) {
		throw error
	}
}
