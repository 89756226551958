import {consumeJsonWithAuth} from '@api/api-client'
import {Cyclist} from '@utils/types'

const CYCLIST_ACCOUNTS_ENDPOINT = 'cyclist-accounts'
const CYCLISTS_ENDPOINT = 'cyclists'

/**
 * Get the cyclist id by email.
 *
 * @return {Promise<number | null>} cyclistId - Returns the cyclist id or null if there's no cyclist account
 * registered with this email
 */
export const getCyclistIdAPI = async (
	email: string
): Promise<number | null> => {
	const response = await consumeJsonWithAuth<{id: number | null}>(
		`${
			process.env.GATSBY_MIDDLEWARE_BASE_URL
		}/${CYCLIST_ACCOUNTS_ENDPOINT}?email=${encodeURIComponent(email)}`,
		{
			method: 'GET',
		}
	)

	const {id} = response

	return id
}

/**
 * Search cyclists with a filter
 *
 * @return {Promise<Cyclist[]>} cyclists - Returns the cyclists which
 */
export const searchCyclistsAPI = async (filter: string): Promise<Cyclist[]> => {
	const response = await consumeJsonWithAuth<{items: Cyclist[]}>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${CYCLISTS_ENDPOINT}?filter=${filter}`,
		{
			method: 'GET',
		}
	)

	const {items} = response

	return items
}

/**
 * Get cyclist information from a cyclist id and bicycle id
 *
 * @return {Promise<Cyclist>} cyclist - Returns the cyclist detail info
 */
export const getCyclistAPI = async (
	cyclistId: number,
	bicycleId: number
): Promise<Cyclist> => {
	const response = await consumeJsonWithAuth<Cyclist>(
		`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/${CYCLISTS_ENDPOINT}/${cyclistId}?bicycleId=${bicycleId}`,
		{
			method: 'GET',
		}
	)

	return response
}
