import dayjs from 'dayjs'

import {consumeWithAuth} from '@api/api-client'
import {BicycleStatus} from '@utils/types/status'

const DEALER_ORDERED_ENDPOINT = `states/dealer-ordered`
const READY_FOR_PICKUP_ENDPOINT = `states/ready-for-pickup`
const DELIVERY_COMPLETED_ENDPOINT = `delivery-completed`

/**
 * Set a an order in the dealer ordered status.
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const orderToDealerOrderedAPI = async (
	bicycleId: number
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/bicycles/${bicycleId}/${DEALER_ORDERED_ENDPOINT}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					status: BicycleStatus.DEALER_ORDERED,
				}),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Set a an order in the ETA available status.
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const orderToDeliveryDateGivenAPI = async (
	bicycleId: number,
	deliveryDate: Date
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/bicycles/${bicycleId}/${DEALER_ORDERED_ENDPOINT}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					status: BicycleStatus.DELIVERY_DATE_GIVEN,
					expectedDeliveryDate: dayjs(deliveryDate).format('YYYY-MM-DD'),
				}),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Set an order in the ready for pickup status.
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const orderToReadyForPickupAPI = async (
	bicycleId: number,
	status: BicycleStatus,
	actualDeliveryDate: Date,
	frameNumber?: string,
	keyNumber?: string,
	numberPlate?: string
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/bicycles/${bicycleId}/${READY_FOR_PICKUP_ENDPOINT}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					status,
					actualDeliveryDate: dayjs(actualDeliveryDate).format('YYYY-MM-DD'),
					keyNumber,
					frameNumber,
					numberPlate,
				}),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Set an order in the pickup info available status.
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const orderPickupInfoAvailableAPI = async (
	bicycleId: number,
	actualDeliveryDate: string,
	frameNumber?: string,
	keyNumber?: string
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/bicycles/${bicycleId}/${READY_FOR_PICKUP_ENDPOINT}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					status: BicycleStatus.PICKUP_INFO_AVAILABLE,
					keyNumber,
					frameNumber,
					actualDeliveryDate: dayjs(actualDeliveryDate).format('YYYY-MM-DD'),
				}),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}

/**
 * Set an order in the delivery completed status.
 *
 * @return {Promise<boolean>} success - True if request was successful
 */
export const orderToDeliveryCompletedAPI = async (
	bicycleId: number,
	deliveryDocumentId: string,
	deliveryDocumentChecksum: string,
	invoiceDocumentId: string,
	invoiceDocumentChecksum: string,
	pickupDate: Date,
	velopassId: string
): Promise<boolean> => {
	try {
		const response = await consumeWithAuth(
			`${process.env.GATSBY_MIDDLEWARE_BASE_URL}/bicycles/${bicycleId}/states/${DELIVERY_COMPLETED_ENDPOINT}`,
			{
				method: 'PUT',
				body: JSON.stringify({
					deliveryDocumentId,
					deliveryDocumentChecksum,
					invoiceDocumentId,
					invoiceDocumentChecksum,
					pickupDate: dayjs(pickupDate).format('YYYY-MM-DD'),
					velopassId,
				}),
			}
		)
		return response.status === 204
	} catch (error) {
		throw error
	}
}
