import decodeJwt from 'jwt-decode'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSSR, useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {DEFAULT_LANGUAGE} from '@i18n/i18n-config'
import allLocales from '@i18n/locales/index'
import {RootState} from '@redux/root-reducer'
import {LanguageContext} from '@utils/context'
import {changeLanguage, getLang} from '@utils/i18n'
import {JsonWebToken} from '@utils/types'

/**
 * I18nWrapper
 * This wrapper manages i18n and what language to use in the following order:
 * 		- on successfull login -> Language in JWT
 * 		- language from path (languageFromPath)
 * 		- language from context (activeLanguage)
 * 		- language from identity token, if available, otherwise browser (getLang)
 * 		- default language (DEFAULT_LANGUAGE)
 */
interface I18nWrapperProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any
	languageFromPath?: string
}

export const I18nWrapper: React.FC<I18nWrapperProps> = ({
	children,
	languageFromPath,
}) => {
	const {i18n} = useTranslation(undefined, {useSuspense: false})
	const isBrowser = typeof window !== 'undefined'
	const [activeLanguage, setActiveLanguage] = useState<string>(getLang())

	const {success: loginSuccess} = useSelector((state: RootState) => state.login)
	const language = useSelector((state: RootState) => state.auth.language)

	useSSR(allLocales, languageFromPath || DEFAULT_LANGUAGE)

	// Set active language based on JWT after successful login
	useEffect(() => {
		if (loginSuccess && language) {
			setActiveLanguage(language)
		}
	}, [loginSuccess, language])

	// The useEffect hook isn't triggered on SSR, so we do it like this..
	// Looking for a better solution
	if (languageFromPath && !isBrowser) {
		changeLanguage(i18n, languageFromPath)
	}

	// Update the active language when the languageFromPath updates
	useEffect(() => {
		if (languageFromPath) {
			setActiveLanguage(languageFromPath)
		}
	}, [languageFromPath])

	// Change the language used for i18n when the active language updates
	useEffect(() => {
		if (activeLanguage) {
			changeLanguage(i18n, activeLanguage)
		}
	}, [activeLanguage])

	return (
		<LanguageContext.Provider value={{activeLanguage, setActiveLanguage}}>
			{children}
		</LanguageContext.Provider>
	)
}

I18nWrapper.propTypes = {
	children: PropTypes.node,
	languageFromPath: PropTypes.string,
}

export default I18nWrapper
