export default {
  LoginTitle1: `Welkom terug`,
  LoginTitle2: `Log in om verder te gaan`,
  LoginButton: `Inloggen`,
  LoginEmailLabel: `E-mailadres`,
  LoginPasswordLabel: `Wachtwoord`,
  LoginForgotPasswordLabel: `Wachtwoord vergeten?`,
  LoginPasswordDefaultValue: `Wachtwoord invoeren`,
  LoginEmailDefaultValue: `E-mailadres`,
  LoginPurpleTitle1: `The lease`,
  LoginPurpleTitle2: `we can do!`,
  LoginPurpleTextBlock: `Beheer eenvoudig alle Cyclis leasefietsen in de tool voor dealers.`,
  LogoutButton: `Uitloggen`,
  UnknownError: `Er is een onverwachte fout opgetreden.`,
  ForgotPasswordTitle: `Wachtwoord vergeten?`,
  ForgotPasswordSubTitle: `Voer het e-mailadres in dat is gekoppeld aan je account en wij sturen je instructies om je wachtwoord opnieuw in te stellen.`,
  ForgotPasswordBackButton: `Terug`,
  ForgotPasswordSendEmailButton: `Stuur een reset e-mail`,
  ForgotPasswordSuccessTitle: `Check je e-mail!`,
  ForgotPasswordSuccessSubtitlePart1: `Klik op de knop in de mail die we hebben gestuurd naar`,
  ForgotPasswordSuccessSubtitlePart2: `om je wachtwoord opnieuw in te stellen.`,
  CustomSuccessGoBack: `Ga terug`,
  SetPasswordTitle: `Wijzig je huidig wachtwoord`,
  SetPasswordSubTitle: `Zorg ervoor dat je een veilig wachtwoord kiest dat voldoet aan onderstaande criteria.`,
  SetPasswordSaveButton: `Wachtwoord opslaan`,
  SetPasswordTwoSpecialCharacters: `2 speciale karakters`,
  SetPasswordTwoUppercaseCharacters: `2 hoofdletters`,
  SetPasswordTwoDigits: `2 cijfers`,
  SetPasswordTenCharacters: `10 karakters in totaal`,
  SetPasswordCurrentPasswordLabel: `Huidig wachtwoord`,
  SetPasswordConfirmPasswordLabel: `Wachtwoord bevestigen`,
  SetPasswordError: `Het huidig wachtwoord dat je hebt opgegeven, komt niet overeen met je wachtwoord.`,
  ResetPasswordTitle: `Voer een nieuw wachtwoord in voor je account.`,
  ResetPasswordSubTitle: `Zorg ervoor dat je een veilig wachtwoord kiest dat voldoet aan onderstaande criteria.`,
  ResetPasswordSaveButton: `Wachtwoord opslaan`,
  ResetPasswordError: `De reset beveiligingscode is ofwel ongeldig of verlopen.`,
  EmailSuccessTitle: `Check je e-mail!`,
  EmailSuccessGoBack: `Ga terug`,
  BicycleOverviewHeaderTitle: `Mijn fietsen`,
  BicycleOverviewHeaderSubTitle: `Onderstaande lijst bevat alle actieve fietsen die via jouw winkel zijn geleased.`,
  BicycleOverviewExportButton: `Export`,
  BicycleOverviewTotalNumberOfRecords: `Totaal aantal orders`,
  BicycleOverviewTotalLeaseAmountFee: `Totaal leasebedrag`,
  BicycleOverviewTableHeaderSoNum: `SO-nummer`,
  BicycleOverviewTableHeaderName: `Naam`,
  BicycleOverviewTableHeaderBicycle: `Fiets`,
  BicycleOverviewTableHeaderExpectedDeliveryDate: `Geschatte Leverdatum`,
  BicycleOverviewTableHeaderPickupDate: `Afhaaldatum`,
  BicycleOverviewTableHeaderStartOfContract: `Startdatum Contract`,
  BicycleOverviewTableHeaderEndOfContract: `Einddatum Contract`,
  BicycleOverviewTableHeaderCurrentMaintenanceBudget: `Huidig Onderhoudsbudget`,
  BicycleOverviewTableHeaderBicycleStatus: `Status`,
  BicycleOverviewSearchInputPlaceholder: `Zoeken...`,
  NavigationQuotations: `Offertes`,
  NavigationMyBicycles: `Mijn fietsen`,
  NavigationDropdownChangePassword: `Wijzig wachtwoord`,
  NavigationDropdownLogout: `Uitloggen`,
  NavigationHome: `Home`,
  NavigationOpenTasks: `Open Taken`,
  NavigationContact: `Contact`,
  QuotationCreationPageStepInvolvedParties: `Betrokken partijen`,
  QuotationCreationPageStepBicycleInfo: `Info over de Fiets`,
  QuotationCreationPageStepAccessories: `Accessoires`,
  QuotationCreationPageStepAdditionalInfo: `Bijkomende info`,
  QuotationCreationPageStepUpload: `Upload`,
  QuotationCreationPageNextButton: `Volgende`,
  QuotationCreationPageBackButton: `Terug`,
  QuotationCustomerId: `Werkgever`,
  QuotationCustomerPlaceholder: `Geef de naam van de klant in`,
  QuotationFormIdPlaceholder: `Voer een unieke ID in`,
  QuotationDealerId: `Dealer`,
  QuotationDealerPlaceholder: `Geef de naam van de dealer in`,
  QuotationReference: `Referentie`,
  QuotationReferencePlaceholder: `Referentie die op elke factuur wordt vermeld`,
  QuotationCyclistEmail: `E-mail fietser`,
  QuotationCyclistEmailPlaceholder: `Voer E-mail in`,
  QuotationFirstName: `Voornaam`,
  QuotationFirstNamePlaceholder: `Bv. Richard`,
  QuotationLastName: `Achternaam`,
  QuotationLastNamePlaceholder: `Bv. Fietsmans`,
  QuotationEmailAddress: `E-mailadres`,
  QuotationEmailAddressPlaceholder: `Voer E-mailadres in`,
  QuotationPhone: `Telefoonnummer`,
  QuotationPhonePlaceholder: `Voer een telefoonnummer in`,
  QuotationLanguage: `Taal`,
  QuotationBrand: `Merk`,
  QuotationModel: `Model`,
  QuotationTypeHere: `Type hier...`,
  QuotationType: `Type`,
  QuotationFrameType: `Frametype`,
  QuotationFrameSize: `Framemaat`,
  QuotationColor: `Kleur`,
  QuotationRetailPrice: `Catalogusprijs`,
  QuotationAccessoryDescription: `Beschrijving`,
  QuotationAccessoryDescriptionPlaceholder: `Beschrijving van dit accessoire`,
  QuotationAccessoryAmount: `Aantal`,
  QuotationAccessoryPricePerPiece: `Prijs per stuk`,
  QuotationAddNewAccessory: `Voeg een nieuw accessoire toe`,
  QuotationAdministration: `Administratie`,
  QuotationAdministrationCost: `Administratieve kost`,
  QuotationAdministrationDescription: `Administratie beschrijving`,
  QuotationAdministrationPlaceholder: `Beschrijving van deze administratieve kost`,
  QuotationAddNewAdministrationCost: `Voeg een nieuwe administratiekost toe`,
  QuotationExtrasToInclude: `Extra's om op te nemen`,
  QuotationInsurance: `Verzekering`,
  QuotationAssistance: `Pechbijstand`,
  QuotationMaintenance: `Onderhoud`,
  QuotationAdditionalMainBudget: `Extra Onderhoudsbudget`,
  QuotationAdditionalMainBudgetMin: `min. (inclusief standaard)`,
  QuotationAdditionalMainBudgetMax: `max.`,
  QuotationUploadTitle: `Upload document`,
  QuotationUploadDescription: `Upload de offerte (1) die je hebt ontvangen van één van onze fietshandelaren.`,
  QuotationCreateButton: `Maak offerte`,
  QuotationsOverViewTitle: `Offertes`,
  QuotationsOverViewSubTitle: `Onderstaande lijst bevat alle offertes binnen je bedrijf.`,
  QuotationsOverViewCreateButton: `+ maak offerte`,
  QuotationsOverViewTableHeaderSoNum: `SO-nummer`,
  QuotationsOverViewTableHeaderCustomer: `Klant`,
  QuotationsOverViewTableHeaderCyclist: `Fietser`,
  QuotationsOverViewTableHeaderBicycle: `Fiets`,
  QuotationCreationSuccessTitle: `We maken je offerte`,
  QuotationCreationSuccessSubTitle: `We sturen je een E-mail van zodra je offerte klaarstaat`,
  QuotationCreationSuccessButtonText: `Terug naar overzicht`,
  QuotationCreationCouldNotFindCyclist: `Kan geen geregistreerde fietser vinden met dit e-mailadres {{email}}.`,
  QuotationCreationSelectLanguagePlaceholder: `-- Selecteer een taal --`,
  QuotationCreationSelectPlaceholder: `-- Maak een selectie --`,
  QuotationDetailBackToOverview: `Terug naar het overzicht`,
  QuotationDetailQuotationIdTitle: `Offerte ID`,
  QuotationDetailQuotationSoNumberTitle: `SO-nummer`,
  QuotationDetailQuotationId: `Offerte ID:`,
  QuotationDetailSONumber: `SO-nummer:`,
  QuotationDetailInvolvedPartiesTitle: `Betrokken partijen`,
  QuotationDetailCustomer: `Klant:`,
  QuotationDetailCyclist: `Fietser:`,
  QuotationDetailCyclistEmail: `E-mail:`,
  QuotationDetailDealer: `Dealer:`,
  QuotationDetailReference: `Referentie:`,
  QuotationDetailBicycleInformation: `Fiets informatie`,
  QuotationDetailBrand: `Merk:`,
  QuotationDetailModel: `Model:`,
  QuotationDetailType: `Type:`,
  QuotationDetailFrameType: `Frametype:`,
  QuotationDetailFrameSize: `Framemaat:`,
  QuotationDetailColor: `Kleur:`,
  QuotationDetailRetailPrice: `Catalogusprijs`,
  QuotationDetailTotalAccessoriesPrice: `Totale prijs accessoires`,
  QuotationAccessoriesListTitle: `Lijst Accessoires`,
  QuotationDetailAccessoryPerPiece: `per stuk`,
  QuotationDetailExtrasTitle: `Extra's`,
  QuotationDetailExtrasAdditionalServices: `Bijkomende diensten in je leaseplan:`,
  QuotationDetailInsurance: `Verzekering`,
  QuotationDetailAssistance: `Pechverhelping`,
  QuotationDetailMaintenance: `Onderhoud`,
  QuotationDetailExtraBudget: `Budget voor extra onderhoud:`,
  QuotationDetailBicycleLeaseTitle: `Fietsleasing`,
  QuotationDetailLeaseCost: `Leasekost:`,
  QuotationDetailAdministrationTitle: `Administratie`,
  QuotationDetailAdministrationCost: `Administratieve kost:`,
  UploadHelperTextDragAndDrop: `Sleep`,
  UploadHelperTextQuotation: `je offerte hier of`,
  UploadHelperTextClick: `klik`,
  UploadedText: `Geüpload`,
  UploadDeleteButton: `Verwijder`,
  QuotationDetailAttachmentsTitle: `Bijlagen`,
  QuotationDetailDownloadButton: `Download`,
  QuotationOverviewDownload: `Download`,
  ConsentTitle: `Privacy Policy`,
  ConsentDate: `Geüpdatet op 01 januari 2020`,
  ConsentBodyPart1: `Wanneer je gebruik maakt van onze diensten, vertrouw je ons met jullie data. Cyclis Bike Lease heeft een privacy policy die je helpt te begrijpen welke data we verzamelen, waarom we dit doen en wat we met deze data doen.`,
  ConsentBodyPart2: `Je kan contact met ons opnemen voor privacy-gerelateerde zaken via`,
  ConsentBodyTitle: `Wat voor informatie verzamelen we over jou?`,
  ConsentSubmitButton: `Ga verder`,
  ConsentAcceptPart1: `Ik heb de`,
  ConsentAcceptPart2: `Privacy Policy`,
  QuotationOverviewDocumentLoading: `Laden...`,
  QuotationOverviewHeaderDate: `Datum`,
  LoginInvalidRole: `Om toegang te krijgen tot het portaal, heb je meer rechten nodig dan die van een fietser.`,
  HomePageTitlePartOne: `Welkom bij Cyclis`,
  HomePageTitlePartTwo: `Dashboard`,
  HomePageSubTitle: `Kom te weten welke taken je hebt openstaan, of start met het helpen van je klant.`,
  HomePageStatsOrdersToBeConfirmed: `Orders goed te keuren`,
  HomePageStatsStatsViewOrders: `Bekijk orders`,
  HomePageReadyToDeliverBicycles: `Fietsen klaar om af te leveren`,
  HomePageStatsDeliverBicycle: `Lever de fiets af`,
  HomePageSearchTitle: `Help je klant`,
  HomepageSearchSubTitle: `je kan hun dossier vinden door te zoeken op naam en/of SO-nummer`,
  HomePageSearchInputPlaceholder: `Naam of SO-nummer`,
  HomePageSearchButton: `Zoek`,
  HomePageCreateNewQuotationPartOne: `Creëer een`,
  HomePageCreateNewQuotationPartTwo: `Nieuwe offerte`,
  SearchResultPageBackToOverview: `Terug naar het overzicht`,
  SearchResultPageTitle: `Zoekresultaten voor`,
  SearchResultHeaderSoNum: `SO-nummer`,
  SearchResultHeaderCyclist: `Fietser`,
  SearchResultHeaderCompany: `Bedrijf`,
  SearchResultHeaderBicycle: `Fiets`,
  CyclistProfileBackToOverview: `Terug naar het overzicht`,
  CyclistProfileCurrentMaintenanceBudget: `Huidig onderhoudsbudget`,
  CyclistProfileSeeMore: `Bekijk meer`,
  CyclistProfileNewInsuranceCase: `Nieuw verzekeringsgeval`,
  CyclistProfileNewQuotation: `Nieuwe offerte`,
  CyclistProfileAddMaintenance: `Voeg onderhoud toe`,
  BicycleDetailBackToOverview: `Terug naar het overzicht`,
  BicycleDetailHeaderSoNumber: `SO-nummer`,
  BicycleDetailMaintenanceTitle: `Onderhoud`,
  BicycleDetailMaintenanceAvailable: `Beschikbaar onderhoudsbudget`,
  BicycleDetailMaintenanceTotal: `Totaal onderhoudsbudget`,
  BicycleDetailMaintenanceSpend: `Gespendeerd onderhoudsbudget`,
  BicycleDetailMaintenanceHistory: `Geschiedenis`,
  BicycleDetailMaintenanceUploadHelperText: `de factuur van de herstelling of`,
  BicycleDetailInsurancesTitle: `Verzekeringen`,
  BicycleDetailInsurancesButtonText: `Meld een nieuw verzekeringsgeval`,
  BicycleDetailLeaseDetailsTitle: `Lease details`,
  BicycleDetailLeaseDetailsSoNum: `SO-nummer`,
  BicycleDetailLeaseDetailsStatus: `Status`,
  BicycleDetailLeaseDetailsStartOfContract: `Start Contract`,
  BicycleDetailLeaseDetailsEndOfContract: `Einde Contract`,
  BicycleDetailLeaseDetailsExpectedDeliveryDate: `Verwachte levertermijn`,
  BicycleDetailLeaseDetailsPickupDate: `Afhaaldatum`,
  BicycleDetailLeaseDetailsLeaseCost: `Leasekost`,
  BicycleDetailBicycleInformationTitle: `Fiets informatie`,
  BicycleDetailBicycleInformationBrand: `Merk`,
  BicycleDetailBicycleInformationModel: `Model`,
  BicycleDetailBicycleInformationFrameType: `Frametype`,
  BicycleDetailBicycleInformationFrameNumber: `Framenummer`,
  BicycleDetailBicycleInformationType: `Type`,
  BicycleDetailBicycleInformationColor: `Kleur`,
  BicycleDetailBicycleInformationNumberPlate: `Nummerplaat`,
  BicycleDetailBicycleInformationKeyNumber: `Sleutelnummer`,
  BicycleDetailInvolvedPartiesTitle: `Betrokken partijen`,
  BicycleDetailInvolvedPartiesCustomer: `Klant`,
  BicycleDetailInvolvedPartiesCyclist: `Fietser`,
  BicycleDetailInvolvedPartiesCyclistEmail: `E-mail:`,
  BicycleDetailInvolvedPartiesDealer: `Dealer`,
  BicycleDetailAccessoriesListTitle: `Lijst accessoires`,
  BicycleDetailAccessoriesListPerPiece: `per stuk`,
  BicycleDetailExtrasTitle: `Extra's`,
  BicycleDetailExtrasExtraBudget: `Budget voor extra onderhoud`,
  BicycleDetailExtrasAdditionalServices: `Bijhorende diensten die inbegrepen zijn in je leaseplan`,
  BicycleDetailExtrasAdditionalServicesInsurance: `Verzekering`,
  BicycleDetailExtrasAdditionalServicesAssistance: `Pechverhelping`,
  BicycleDetailExtrasAdditionalServicesMaintenance: `Onderhoud`,
  BicycleDetailAttachmentsTitle: `Bijlagen`,
  BicycleDetailCtaQuotationApproved: `Je offerte is goedgekeurd!`,
  BicycleDetailCtaQuotationApprovedSubTitle: `Bevestig de bestelling en geef indien mogelijk een verwachte leverdatum door.`,
  BicycleDetailEstimatedDeliveryDateLabel: `Verwachte leverdatum`,
  BicycleDetailConfirmOrderButton: `Bevestig bestelling`,
  BicycleDetailCtaKeepYourCustomerUpToDate: `Hou je klant op de hoogte`,
  BicycleDetailKeepYourCustomerUpToDate: `In geval van vertraging kan je de verwachte levertermijn aanpassen, of je kan de klant laten weten dat de fiets klaar staat voor afhaling.`,
  BicycleDetailCtaReadyForPickupLabel: `Klaar om af te halen`,
  BicycleDetailCtaUpdateStatusButton: `Update status`,
  BicycleDetailCtaFrameNumberLabel: `Framenummer:`,
  BicycleDetailCtaKeyNumberLabel: `Sleutelnummer:`,
  BicycleDetailCtaLicensePlateLabel: `Nummerplaat:`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleTitle: `Bijna klaar om de fiets af te leveren.`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart1: `Vul alle onderstaande velden in om de fiets af te leveren.`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart2: `Je kan het`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart3: `Afleverdocument`,
  BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart4: `hier downloaden`,
  BicycleDetailCtaFrameNumberLabel2: `Framenummer:`,
  BicycleDetailCtaKeyNumberLabel2: `Sleutelnummer:`,
  BicycleDetailCtaLicensePlateLabel2: `Nummerplaat:`,
  BicycleDetailCtaDeliverBicycleButton: `Lever de fiets af`,
  BicycleDetailCtaDeliverBicycleTitle: `Je kan de fiets nu afleveren!`,
  BicycleDetailCtaDeliverBicycleSubTitlePart1: `Vergeet niet het getekende leverdocument en de factuur op te laden`,
  BicycleDetailCtaDeliverBicycleSubTitlePart2: `Je kan het`,
  BicycleDetailCtaDeliverBicycleSubTitlePart3: `Afleverdocument`,
  BicycleDetailCtaDeliverBicycleSubTitlePart4: `hier downloaden`,
  BicycleDetailCtaAddDocument: `Voeg document toe`,
  BicycleDetailCtaAddInvoice: `Voeg factuur toe`,
  BicycleDetailCtaSignedDeliveryDocument: `Getekend leverdocument`,
  BicycleDetailCtaInvoiceDocument: `Factuur`,
  BicycleDetailCtaFinishButton: `Beëindig`,
  BicycleDetailCtaDeliverySuccessTitle: `Je hebt het order afgewerkt!`,
  BicycleDetailCtaDeliverySuccessSubTitle1: `bedankt om alle info in te geven, alle stappen zijn nu compleet.`,
  BicycleDetailCtaDeliverySuccessSubTitle2: `We gaan het orderproces voor je afsluiten`,
  ErrorDownloadingUnsignedDeliveryDocument: `Het leverdocument kon niet worden gedownload.`,
  BicycleDetailStatusIsSyncingPlaceholderTitle: `Fiets update in uitvoering`,
  BicycleDetailStatusIsSyncingPlaceholderSubtitle1: `We onderzoeken uw update momenteel intern bij Cyclis en zullen deze zo snel mogelijk aanpassen in uw fietsdossier.`,
  BicycleDetailStatusIsSyncingPlaceholderSubtitle2: `Kom op een later tijdstip terug om uw updates te bekijken.`,
  BicycleDetailStatusIsSyncingPlaceholderBackToOverviewButton: `Terug naar overzicht`,
  BicycleDetailAddMaintenanceModalTitle: `Klaar om de onderhoudskost op te laden`,
  BicycleDetailAddMaintenanceModalText: `De onderhoudskost is klaar voor verzending. Als je zeker bent, kan je het document verzenden met de 'Upload' knop onderaan.`,
  BicycleDetailAddMaintenanceModalCancelButton: `Annuleer`,
  BicycleDetailAddMaintenanceModalUploadButton: `Upload`,
  BicycleDetailUserActionLogTitle: `Mijn Laatste Activiteiten`,
  UserActionLogItemTitleMaintenanceInvoiceUploaded: `Onderhoudsfactuur opgeladen`,
  UserActionLogItemTitleDamageCaseCreated: `Verzekeringsgeval aangemeld`,
  SearchAtleast3Characters: `Type ten minste 3 karakters`,
  BicycleStatusAll: `Alle`,
  BicycleStatusCyclisToOrder: `Te bestellen (Cyclis)`,
  BicycleStatusCyclisOrdered: `Te bestellen (Dealer)`,
  BicycleStatusDealerOrdered: `Besteld`,
  BicycleStatusDeliveryDateGiven: `Besteld (Levertermijn gekend)`,
  BicycleStatusReadyForPickup: `Klaar om af te halen`,
  BicycleStatusPickupInfoAvailable: `Klaar om af te halen`,
  BicycleStatusDeliveryCompleted: `Geleverd`,
  BicycleStatusEndLease: `Einde lease`,
  BicycleStatusCanceled: `Geannuleerd`,
  BicycleOverviewCustomPlaceholderHeader: `Geen fietsen beschikbaar`,
  BicycleOverviewCustomPlaceholderSubTitle: `Het kan zijn dat er geen fietsen in deze status zitten. Je kan best filteren op 'Alle'.`,
  QuotationOverviewCustomPlaceholderHeader: `Geen offertes beschikbaar`,
  QuotationOverviewCustomPlaceholderSubTitle: `Het kan zijn dat er geen fietsen voldoen aan deze zoekterm.`,
  AutocompleteNoOptionsWithFilterText: `Geen opties`,
  AutocompleteNoOptionsWithoutFilterText3Chars: `Type 3 of meer karakters om te zoeken`,
  AutocompleteNoOptionsWithoutFilterTextNoChars: `Type om te zoeken`,
  DamageCreationPageStepBicycleInfo: `Info over de fiets`,
  DamageCreationPageStepDescription: `Beschrijving`,
  DamageCreationPageStepCost: `Kostprijs`,
  DamageCreationBicycleInfoFormCyclistName: `Naam fietser`,
  DamageCreationBicycleInfoFormSalesOrderNumber: `SO-nummer`,
  DamageCreationBicycleInfoFormBicycleInfo: `Fiets`,
  DamageCreationDescriptionFormPictures: `Upload Afbeelding(en)`,
  DamageCreationDescriptionFormPreview: `Voorbeeld:`,
  DamageCreationDescriptionFormDescription: `Beschrijving`,
  DamageCreationDescriptionFormDescriptionPlaceholder: `Beschrijf het verzekeringsgeval`,
  DamageCreationCostFormTitle: `Upload document`,
  DamageCreationCostFormSubTitle: `Upload het offertedocument (1) dat de reparatie voor dit verzekeringsgeval dekt.`,
  DamageCreationPlaceholderTitle: `We hebben je aanvraag goed ontvangen.`,
  DamageCreationPlaceholderSubTitle: `Relax en ontspan, we sturen je een e-mail zodra je offerte is goedgekeurd.`,
  DamageCreationPlaceholderButton: `Terug naar de fiets`,
  DamageCreationBackButton: `Terug`,
  DamageCreationSubmitButton: `Beëindigen`,
  DamageCreationNextButton: `Volgende`,
  DealerOrderFlowSyncInProgress: `Bezig met updaten`,
  DealerOrderFlowPickupInfoTogetherError: `De informatievelden moeten samen ingevuld worden.`,
  NoDeliveryDateGiven: `Geen leverdatum geselecteerd`,
  NoActualDeliveryDateGiven: `Geen leverdatum geselecteerd`,
  DealerOrderedFlowBicycleMustHaveADeliveryDate: `De fiets moet eerst in status 'Levertijd Beschikbaar' komen.`,
  OpenTaskOverviewHeaderTitle: `Bestellingen`,
  OpenTaskOverviewHeaderSubTitle: `Onderstaande lijst bestaat uit alle fietsen met open taken.`,
  OpenTaskOverviewSearchInputPlaceholder: `Zoeken...`,
  OpenTaskOverviewTableHeaderSoNum: `SO-nummer`,
  OpenTaskOverviewTableHeaderName: `Naam`,
  OpenTaskOverviewTableHeaderBicycle: `Fiets`,
  OpenTaskOverviewTableHeaderCreateDate: `Aanmaakdatum`,
  OpenTaskOverviewTableHeaderExpectedDeliveryDate: `Verwachte levertijd`,
  OpenTaskOverviewCustomPlaceholderHeader: `Geen open taken beschikbaar.`,
  OpenTaskOverviewCustomPlaceholderSubTitle: `Er zijn geen openstaande taken. Kom later nog eens kijken.`,
  HomeCyclistSearchSeeAllResults: `Bekijk alle resultaten`,
  BicycleDetailMaintenanceUploadSuccessTitle: `Bedankt voor het uploaden van de onderhoudsfactuur.`,
  BicycleDetailMaintenanceUploadSuccessSubTitle: `We nemen je aanvraag in behandeling en passen het onderhoudsbudget aan.`,
  BicycleDetailPickupDateLabel: `De fiets was opgehaald op:`,
  BicycleDetailVelopassIdLabel: `Velopass ID:`,
  NoPickupDateGiven: `Geen afhaaldatum geselecteerd`,
  InsurancaCaseHelperText: `je foto's of`,
  StandardError1: `Het opgegeven e-mailadres is geen geldig e-mailadres`,
  StandardError2: `Het opgegeven wachtwoord komt overeen met de wachtwoordregels`,
  StandardError3: `Je sessie is verlopen`,
  StandardError4: `Je bent niet bevoegd om deze handeling uit te voeren`,
  StandardError5: `De opgegeven sessie token is niet geldig`,
  StandardError6: `De opgegeven voornaam is te lang`,
  StandardError7: `De opgegeven achternaam is te lang`,
  StandardError8: `De opgegeven aanmeldingscode is niet geldig`,
  StandardError9: `De opgegeven eenmalige token is niet geldig`,
  StandardError10: `De opgegeven merk ID is niet geldig`,
  StandardError11: `Het opgegeven fietstype ID is niet geldig`,
  StandardError12: `De opgegeven verkoopprijs is niet geldig`,
  StandardError13: `De prijs van de opgegeven accessoires is niet geldig`,
  StandardError14: `De opgegeven insurance flag is niet geldig`,
  StandardError15: `De opgegeven maintenance flag is niet geldig`,
  StandardError16: `De opgegeven assistance flag is niet geldig`,
  StandardError17: `De opgegeven extra maintenance is niet geldig`,
  StandardError18: `Veld moet een tekenreeks zijn`,
  StandardError19: `Veld mag niet leeg zijn`,
  StandardError20: `Het opgegeven token is te lang en langer dan 512 tekens`,
  StandardError21: `Het opgegeven e-mailadres is te lang en bevat meer dan 100 tekens`,
  StandardError22: `Geen geldige login combinatie`,
  StandardError23: `Geen geldige beveiligingscode voor SSO`,
  StandardError24: `Opgegeven taal is niet geldig`,
  StandardError25: `Een of meer van de opgegeven productgegevenstypen is niet geldig`,
  StandardError26: `Veld moet een nummer zijn`,
  StandardError27: `Fietser niet gevonden`,
  StandardError28: `Veld moet een boolean waarde zijn`,
  StandardError29: `Opgegeven kleur ID is niet geldig`,
  StandardError30: `Opgegeven frametype ID is niet geldig`,
  StandardError31: `Opgegeven framemaat ID is niet geldig`,
  StandardError32: `Opgegeven klant ID is niet geldig`,
  StandardError33: `Geen document gevonden voor de opgegeven ID`,
  StandardError34: `Document checksum kwam niet overeen. Het document is mogelijk beschadigd na het uploaden.`,
  StandardError35: `Bij het maken van een offerte moet precies één bestand worden aangeleverd.`,
  StandardError36: `Opgegeven dealer ID is niet geldig`,
  StandardError37: `Informatie over fietser moet worden verstrekt`,
  StandardError38: `Fietser met opgegeven e-mailadres bestaat al`,
  StandardError39: `Opgegeven offerte ID is niet geldig`,
  StandardError40: `Opgegeven document ID is niet geldig`,
  StandardError41: `Voor filteren zijn minimaal 3 tekens nodig`,
  StandardError42: `Het opgegeven portaaltype is niet geldig`,
  StandardError43: `Ongeldige machtigingen`,
  StandardError44: `Bijgeleverde fiets bestaat niet`,
  StandardError45: `Offerte heeft de verkeerde status`,
  StandardError46: `Opgegeven status bestaat niet`,
  StandardError47: `Opgegeven fiets ID is niet geldig`,
  StandardError48: `Fiets heeft de verkeerde status`,
  StandardError49: `De opgegeven fiets is geen speed pedelec`,
  StandardError50: `Het opgegeven telefoonnummer is te lang en langer dan 20 tekens`,
  StandardError51: `De opgegeven taal is te lang en langer dan 10 tekens`,
  StandardError52: `Het opgegeven model is te lang en overschrijdt de 250 tekens`,
  StandardError53: `De opgegeven referentie is te lang en langer dan 50 tekens`,
  StandardError54: `Het opgegeven veld mag niet null zijn`,
  StandardError55: `Beschrijving van meegeleverde accessoires is te lang`,
  StandardError56: `Ongeldig documenttype`,
  StandardError57: `Er is minimaal één afbeelding vereist om een verzekeringscase te maken`,
  StandardError58: `Een of meer van de opgegeven filtersstatussen zijn niet geldig`,
  StandardError72: `Ontbrekende link naar partner op fiets voor geauthentificeerde gebruiker`,
  StandardError73: `De fietser voor het opgegeven e-mailadres wordt niet beheerd door de huidige gebruiker`,
  StandardError77: `Het veld moet een positief getal zijn`,
  StandardError78: `Het veld mag geen negatief getal zijn`,
  StandardError82: `Ontbrekende link naar partner op offerte voor geauthenticeerde gebruiker`,
  StandardError92: `Ongeldige datum`,
  StandardError101: `Het bestandstype is niet toegelaten op het Cyclis platform.`,
  FileInputInvalidFileType: `Ongeldig bestandstype`,
  FormValidationErrorMessageRequired: `Dit veld is verplicht`,
  FormValidationErrorMessageEmail: `Dit is geen geldig e-mail adres`,
  FormValidationErrorMessagePhone: `Dit is geen geldig telefoonnummer`,
  FormValidationErrorMessageDateTypeError: `Deze waarde is geen geldige datum`,
  FormValidationErrorMessageFileTypeUnsupported: `Dit bestandstype wordt niet ondersteund`,
  FormValidationErrorMessageStringMin: `Deze waarde is te kort`,
  FormValidationErrorMessageStringMax: `Deze waarde is te lang`,
  FormValidationErrorMessageNumberTypeError: `Deze waarde moet een getal zijn`,
  FormValidationErrorMessageNumberNoNegative: `Geen negatieve waarde toegestaan`,
  FormValidationErrorMessageNumberMin: `Deze waarde is te klein`,
  FormValidationErrorMessageNumberMax: `Deze waarde is te groot`,
  FormValidationErrorMessageArrayMin: `Het aantal verstrekte items is te klein`,
  FormValidationErrorMessageBooleanCheck: `Je moet het vakje aanvinken`,
  FormValidationErrorMessagePatternMatch: `Deze waarde volgt niet het vereiste patroon`,
  FormValidationErrorMessageCurrentPasswordEqual: `Jouw nieuwe wachtwoord mag niet hetzelfde zijn als jouw huidige wachtwoord`,
  FormValidationErrorMessageConfirmPasswordNotEqual: `Jouw nieuwe wachtwoord komt niet overeen met deze waarde`,
  ContactTitle: `We helpen je graag.`,
  ContactSubtitle: `Waar gaat je vraag over?`,
  ContactTabTitleSales: `Verkoop gerelateerd`,
  ContactTabTitleAfterCare: `Nazorg`,
  ContactTabTitleTechnical: `Technisch probleem`,
  ContactTabTitleBilling: `Facturatie`,
  ContactTabTitleOther: `Andere`,
  ContactTabPanelAvailability: `Beschikbaarheid`,
  ContactTabPanelFAQ: `FAQ`,
  ContactTabPanelNameSales: `Cyclis Team`,
  ContactTabPanelEmailSalesBE: `dealer@cyclis.be`,
  ContactTabPanelEmailSalesNL: `info@cyclis.nl`,
  ContactTabPanelPhoneSalesBE: `+32 (0)11 49 56 08`,
  ContactTabPanelPhoneSalesNL: `+31 (0)85 130 6930`,
  ContactTabPanelAvailabilitySales: `Je kan onze teams bereiken tijdens de week van 08:00 tot 17:00. Wij zijn gesloten in het weekend en op feestdagen.`,
  ContactTabPanelFAQSales: `Geen zin om te wachten? Je kan altijd naar het antwoord zoeken op onze`,
  ContactTilesFindUs: `Je kan ons ook vinden...`,
  ContactTileOfficeTitle: `in Hasselt`,
  ContactTileOfficeBody: `Kom langs en zie waar we werken.`,
  ContactTileFacebookTitle: `op Facebook`,
  ContactTileFacebookBody: `Vind ons leuk en volg onze verhalen!`,
  ContactTileInstagramTitle: `op Instagram`,
  ContactTileInstagramBody: `Volg ons en leer het team kennen!`,
  PageNotFoundTitle: `Oeps, er ging iets mis.`,
  PageNotFoundSubtitle: `Controleer en probeer het opnieuw of ga terug naar onze homepage.`,
  PageNotFoundGoBackButton: `Ga terug`,
  BadRequestTitle: `Oeps, er ging iets mis.`,
  BadRequestGoBackButton: `Terug naar de homepage`,
  MaintenancePageTitle: `Onderhoud`,
  MaintenancePageDescription: `We onderhouden momenteel onze portalen waardoor deze tijdelijk niet beschikbaar zijn. Morgen kan je deze weer raadplegen. Onze excuses voor het ongemak.`,
  PlannedMaintenanceBannerTitle: `Belangrijke melding`,
  PlannedMaintenanceBannerText: `Vanwege onze teambuilding op vrijdag 28 juni, worden onze telefoongesprekken op die dag behandeld door ons contact center. We beantwoorden jouw e-mails zo snel mogelijk. Bedankt voor jouw begrip.`,
  PlannedMaintenanceBannerSubscript: `Het Cyclis Team`,
}
