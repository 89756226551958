import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {updateConsentAPI} from '@api/auth-api'
import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'
import {CONSENT_GIVEN, USER_UID} from '@utils/constants/auth-constants'
import {getTranslationFromError} from '@utils/errors'

interface ConsentState {
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: ConsentState = {
	loading: false,
	success: false,
	error: null,
}

const consentSlice = createSlice({
	name: 'consent',
	initialState,
	reducers: {
		updateConsentStart(state): void {
			state.loading = true
			state.error = null
		},
		updateConsentSuccess(state): void {
			state.loading = false
			state.success = true
			state.error = null
		},
		updateConsentFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.success = false
			state.error = action.payload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): ConsentState => {
			return {
				...initialState,
			}
		},
	},
})

export const {updateConsentStart, updateConsentSuccess, updateConsentFailure} =
	consentSlice.actions
export default consentSlice.reducer

export const updateConsent =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(updateConsentStart())

			const uid = localStorage.getItem(USER_UID)
			const success = await updateConsentAPI(uid!)

			if (success) {
				localStorage.setItem(CONSENT_GIVEN, success.toString())
				dispatch(updateConsentSuccess())
			} else {
				dispatch(updateConsentFailure('UpdateConsentFailure'))
			}
		} catch (err) {
			dispatch(updateConsentFailure(getTranslationFromError(err as Error)))
		}
	}
