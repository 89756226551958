import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/work-sans/400.css'
import '@fontsource/work-sans/500.css'
import '@fontsource/work-sans/600.css'
import '@fontsource/work-sans/700.css'

import {FlagsProvider} from 'flagged'
import type {GatsbyBrowser} from 'gatsby'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {Provider} from 'react-redux'

import i18next from './src/i18n/i18n-config'
import store from './src/redux/store'
import {parseFeatureFlags} from './src/utils/parse-feature-flags'
import {ErrorWrapper} from './src/wrappers'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
	element,
}) => {
	return (
		<Provider store={store}>
			<FlagsProvider
				features={parseFeatureFlags(process.env.GATSBY_FEATURE_FLAGS)}
			>
				<I18nextProvider i18n={i18next}>
					<ErrorWrapper>{element}</ErrorWrapper>
				</I18nextProvider>
			</FlagsProvider>
		</Provider>
	)
}
