import {NavigateOptions as GatsbyNavigateOptions} from '@reach/router'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {navigate as gastbyNavigate} from 'gatsby-link'

import {AppThunk} from '@redux/store'
import {logoutSuccess} from '@templates/Logout/logout-slice'

export interface PreviousLocation {
	path?: string
	language?: string
}

export interface NavigateOptions extends GatsbyNavigateOptions<{}> {
	previousLocation?: PreviousLocation
}

interface NavigationState {
	previousLocation: PreviousLocation
	loading: boolean
	success: boolean
	error: string | null
}

const initialState: NavigationState = {
	previousLocation: null as any,
	loading: false,
	success: false,
	error: null,
}

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		navigateStart(state): void {
			state.loading = true
			state.error = null
		},
		navigateSuccess(state, action: PayloadAction<PreviousLocation>): void {
			state.previousLocation = action.payload
			state.loading = false
			state.success = true
			state.error = null
		},
		navigateFailure(state, action: PayloadAction<string>): void {
			state.loading = false
			state.error = action.payload
		},
	},
	extraReducers: {
		[logoutSuccess.toString()]: (): NavigationState => {
			return {
				...initialState,
			}
		},
	},
})

export const {navigateStart, navigateSuccess, navigateFailure} =
	navigationSlice.actions
export default navigationSlice.reducer

export const navigate =
	(to: string, options: NavigateOptions = {}): AppThunk =>
	async (dispatch): Promise<void> => {
		try {
			dispatch(navigateStart())

			const previousPath =
				(options.previousLocation && options.previousLocation.path) ||
				location.pathname

			if (options) {
				await gastbyNavigate(to, options)
			} else {
				await gastbyNavigate(to)
			}

			dispatch(
				navigateSuccess({...options.previousLocation, path: previousPath})
			)
		} catch (err) {
			dispatch(navigateFailure((err as Error).message))
		}
	}
